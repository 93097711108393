import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import React from "react";
import { IconContext } from "react-icons";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import configureStore from "../stores/configureStore";
import GlobalStyle from "../styles/global";
import ViewContainer from "./ViewContainer";

const store = configureStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const csrfToken = document.querySelector("meta[name=csrf-token]").getAttribute("content");

const requestUrl =
  process.env.NODE_ENV === "production" ? "https://community.view-inc.net/graphql" : "http://localhost:3000/graphql";

const client = new ApolloClient({
  uri: requestUrl,
  link: new HttpLink({
    credentials: "same-origin",
    headers: {
      "X-CSRF-Token": csrfToken,
    },
  }),
  cache: new InMemoryCache({
    typePolicies: {
      Profile: {
        merge: true,
      },
    },
  }),
});

const App: React.FC = () => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <Router>
        <GlobalStyle />
        <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
          <ViewContainer />
        </IconContext.Provider>
      </Router>
    </ApolloProvider>
  </Provider>
);

export default App;
