import React, { useEffect, useMemo } from "react";
import { SMARTPHONE_WIDTH } from "../styles/constants";
import styled from "styled-components";
import { ArchivesContents } from "./ArchivesContents";
import { ArchivesHeader } from "./ArchivesHeader";
import { useDispatch, useSelector } from "react-redux";
import { fetchContentsGroups } from "../actions/ContentsGroups";
import { fetchContents } from "../actions/Contents";
import { RootStore } from "../reducers";
import { useArchivedVideos } from "../hooks/useArchivedVideos";
import MediaQuery from "react-responsive";
import { SmartPhoneArchivesContents } from "./SmartPhoneArchivesContents";
import { ContainerCommunityFragment } from "../@types/types";

const selector = (state: RootStore) => ({
  contentsGroups: state.contentsGroups,
  contents: state.contents,
});

export const ArchivesContainer: React.FC<{ community: ContainerCommunityFragment }> = ({ community }) => {
  const archivedVideosProps = useArchivedVideos();
  const { contentsGroups, contents } = useSelector(selector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!contentsGroups || contentsGroups.length === 0) {
      dispatch(fetchContentsGroups());
    }
    if (!contents || contents.length === 0) {
      dispatch(fetchContents());
    }
  }, [dispatch]);

  const archivedContents = useMemo(() => {
    return contents.filter((c) => c.is_archived);
  }, [contents]);
  return (
    <>
      <ArchivesHeader community={community} />
      <ContentsWrapper>
        <MediaQuery query="(min-width: 1040px)">
          <ArchivesContents
            archivedContents={archivedContents}
            contentsGroups={contentsGroups}
            {...archivedVideosProps}
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 1039px)">
          <SmartPhoneArchivesContents
            archivedContents={archivedContents}
            contentsGroups={contentsGroups}
            {...archivedVideosProps}
          />
        </MediaQuery>
      </ContentsWrapper>
    </>
  );
};

const ContentsWrapper = styled.div`
  margin: 100px 0 163px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    margin: 40px 0 120px;
  }
`;
