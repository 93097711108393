import React from "react";
import MediaQuery from "react-responsive";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { ViewIndexViewerFragment } from "../@types/types";
import { MyPageLeftMenu } from "./MyPageLeftMenu";
import { NotificationSetting } from "./NotificationSetting";
import { ProfileContentContainer } from "./ProfileContentContainer";

interface Props {
  viewer: ViewIndexViewerFragment;
}

export const MyPageContainer: React.FC<Props> = ({ viewer }) => {
  return (
    <Wrapper>
      <MediaQuery query="(min-width: 1040px)">
        <MyPageLeftMenu />
      </MediaQuery>
      <MainContentWrapper>
        <Switch>
          {/* <Route exact path="/view/my_page/schedule">
            <MainContent>schedule</MainContent>
          </Route>
          <Route exact path="/view/my_page/contents">
            <MainContent>contents</MainContent>
          </Route>
          <Route exact path="/view/my_page/favorite">
            <MainContent>favorite</MainContent>
          </Route> */}
          <Route exact path="/view/my_page/notification">
            <NotificationSetting />
          </Route>
          <Route path="/view/my_page">
            <ProfileContentContainer viewer={viewer} />
          </Route>
        </Switch>
      </MainContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const MainContentWrapper = styled.div`
  flex-grow: 1;
  background-color: #f5f7f7;
`;
