import React, { useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
import { Red } from "../styles/colors";
import { GroupedEvents } from "../hooks/useGroupingEventsByDay";

moment.locale("ja");

interface Props {
  groupedEventsByDay: GroupedEvents;
  openEventShow: (id: number) => void;
}

export const EventListComponent: React.FC<Props> = ({ groupedEventsByDay, openEventShow }) => {
  const eventListDom = useMemo(() => {
    if (groupedEventsByDay) {
      return Object.keys(groupedEventsByDay).map((date) => (
        <div key={date}>
          <DateLabel>{date}</DateLabel>
          {groupedEventsByDay[date].map((event) => (
            <Event key={event.id} onClick={() => openEventShow(event.id)}>
              <EventTitle className="title">{event.title}</EventTitle>
              {event.start_time === "00:00" && event.end_time === "23:59" ? (
                <div className="start-time" style={{ lineHeight: "36px" }}>
                  終日
                </div>
              ) : (
                <div>
                  <StartTime className="start-time">{event.start_time}</StartTime>
                  <EndTime className="end-time">{event.end_time}</EndTime>
                </div>
              )}
            </Event>
          ))}
        </div>
      ));
    }
  }, [groupedEventsByDay, openEventShow]);

  return (
    <Base>
      <Title>予定の一覧</Title>
      <EventList>{eventListDom}</EventList>
    </Base>
  );
};

const Base = styled.div`
  background-color: #fff;
  margin-left: 12px;
  margin-top: 36px;
`;

const EventList = styled.div`
  overflow: scroll;
  height: 536px;
  width: 231px;
`;
const Title = styled.h3`
  margin: 18px 0;
  font-size: 14px;
  text-align: center;
`;

const DateLabel = styled.div`
  font-size: 12px;
  background-color: #e4e4e4;
  padding: 9px 10px;
`;

const Event = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  padding: 12px 16px;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
  transition: all 0.2s;
  &:hover .title,
  &:hover .start-time {
    color: ${Red};
  }
  &:hover .end-time {
    color: #f7717b;
  }
`;

const EventTitle = styled.div`
  font-size: 13px;
  line-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 140px;
`;

const StartTime = styled.div`
  font-size: 12px;
  margin: 4px 0;
`;

const EndTime = styled.div`
  font-size: 12px;
  margin: 4px 0;
  color: #9e9e9e;
`;
