import { Actions } from "../actions/Events";
import { Event } from "../types/event";

function compare(a: Event, b: Event) {
  // Use toUpperCase() to ignore character casing
  const datetimeA = a.started_at;
  const datetimeB = b.started_at;

  let comparison = 0;
  if (datetimeA > datetimeB) {
    comparison = 1;
  } else if (datetimeA < datetimeB) {
    comparison = -1;
  }
  return comparison;
}

type Action =
  | {
      type: "EVENTS::FETCH";
      data: Event[];
    }
  | {
      type: "EVENTS::ADD";
      data: Event;
    }
  | {
      type: "EVENTS::REMOVE";
      id: number;
    };

const EMPTY_EVENT: Event[] = [];

const eventsReducer = (state: Event[] = EMPTY_EVENT, action: Action): Event[] => {
  switch (action.type) {
    case Actions.FETCH_EVENTS:
      return action.data;
    case Actions.ADD_EVENT: {
      const withoutUpdateEvent = state.filter((element) => element.id !== action.data.id);
      return [...withoutUpdateEvent, action.data].sort(compare);
    }
    case Actions.REMOVE_EVENT: {
      const newState = state.filter((element) => element.id !== action.id);
      return newState;
    }
    default:
      return state;
  }
};

export default eventsReducer;
