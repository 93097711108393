import React, { useState, useCallback } from "react";
import { Content } from "../types/contents";
import { ContentsGroup } from "../types/contentsGroup";
import { ContentForm } from "./ContentForm";
import { ModalBase } from "./ModalBase";
import { ContainerCommunityFragment } from "../@types/types";

interface Props {
  community: ContainerCommunityFragment;
  open: boolean;
  modalContent: Content | null;
  contentsGroups: ContentsGroup[];
  onCloseModal: () => void;
  openContentDelete?: (content: Content) => void;
}

export const useContentFormModal = (
  community: ContainerCommunityFragment,
  contentsGroups: ContentsGroup[]
): Props & { onOpenModal: (e: Content) => void } => {
  const [open, setOpen] = useState<boolean>(false);
  const [content, setContent] = useState<Content | null>(null);

  const onCloseModal = useCallback(() => {
    setOpen(false);
    setContent(null);
  }, []);

  const onOpenModal = useCallback((content: Content) => {
    setOpen(true);
    setContent(content);
  }, []);

  return {
    open,
    modalContent: content,
    community,
    contentsGroups,
    onCloseModal,
    onOpenModal,
  };
};

export const ContentFormModal: React.FC<Props> = ({
  community,
  open,
  modalContent,
  contentsGroups,
  onCloseModal,
  openContentDelete,
}) => {
  return (
    <ModalBase isOpen={open} onRequestClose={onCloseModal} contentLabel="Day Content Modal" width={890} spWidth={290}>
      <ContentForm
        community={community}
        contentsGroups={contentsGroups}
        modalContent={modalContent}
        closeModal={onCloseModal}
        openContentDelete={openContentDelete}
      />
    </ModalBase>
  );
};
