import axios from "axios";

export const Actions = {
  FETCH_APPDATA: "APPDATA::FETCH",
};

export const fetchAppData = () => {
  return async (dispatch) => {
    const response = await axios.get("/api/app_data");

    dispatch({
      type: Actions.FETCH_APPDATA,
      data: response.data,
    });
  };
};
