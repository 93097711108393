import axios from "axios";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { Red } from "../styles/colors";
import { AdminTextInput } from "./AdminTextInput";
import { User } from "./ManageMembers";

interface Props {
  addMember: (arg: User) => void;
}

export const AddMember: React.FC<Props> = ({ addMember }) => {
  const [doneUpload, setDoneUpload] = useState(false);
  const { handleSubmit, control } = useForm<User>({
    defaultValues: {
      id: null,
      real_name: "",
      user_name: "",
      email: "",
      confirmed: false,
      editable: true,
      owner: false,
      locked: true,
    },
  });
  const [isOpenNewUserEditor, setOpenNewUserEditor] = useState<boolean>(false);

  const onSubmit = (data) => {
    const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
    axios
      .post("/admin/new_member", data, {
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      })
      .then((res) => {
        setDoneUpload(true);
        addMember(res.data);
      });
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TitleWrapper>
          <H2>新しいユーザーを手動で追加</H2>
          <OpenButton type="button" onClick={() => setOpenNewUserEditor(!isOpenNewUserEditor)}>
            追加する
          </OpenButton>
        </TitleWrapper>
        <p style={{ color: Red, fontSize: "13px", margin: "8px 0" }}>
          ※Campfire コミュニティで支援していない人を個別で追加したい場合こちらから追加してください
        </p>
        {isOpenNewUserEditor && (
          <>
            <Controller
              control={control}
              name="real_name"
              render={({ onChange, value }) => <AdminTextInput onChange={onChange} value={value} label="本名" />}
            />
            <Controller
              control={control}
              name="user_name"
              render={({ onChange, value }) => <AdminTextInput onChange={onChange} value={value} label="ユーザー名" />}
            />
            <Controller
              control={control}
              name="email"
              render={({ onChange, value }) => (
                <AdminTextInput onChange={onChange} value={value} label="メールアドレス" />
              )}
            />
            <Controller
              control={control}
              name="owner"
              render={({ onChange, value }) => (
                <CheckBoxWrapper>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      onChange(e.currentTarget.checked);
                    }}
                    checked={value}
                  />
                  <label>管理者</label>
                </CheckBoxWrapper>
              )}
            />
            <Controller
              control={control}
              name="editable"
              render={({ onChange, value }) => (
                <CheckBoxWrapper>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      onChange(e.currentTarget.checked);
                    }}
                    checked={value}
                  />
                  <label>編集可能</label>
                </CheckBoxWrapper>
              )}
            />
            <SubmitFormButton type="submit" value={doneUpload ? "追加しました" : "送信"} />
          </>
        )}
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px 0;
  margin-bottom: 24px;
  border-bottom: 1px solid #aaa;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const OpenButton = styled.button`
  cursor: pointer;
  margin-left: 8px;
  background-color: #fff;
  color: ${Red};
  border-radius: 16px;
  border: 1px solid ${Red};
  &:hover {
    background-color: ${Red};
    color: #fff;
  }
`;

const H2 = styled.h2`
  font-size: 24px;
  margin-bottom: 2px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  margin: 16px 0;
`;

const SubmitFormButton = styled.input`
  cursor: pointer;
  margin-left: 21px;
  border-radius: 22px;
  background-color: unset;
  width: 200px;
  border: solid 2px ${Red};
  color: ${Red};
  transition: all 0.2s;
  height: 40px;
  &:hover {
    color: #fff;
    background-color: ${Red};
  }
  @media screen and (max-width: 1024px) {
    width: 250px;
    height: 54px;
    line-height: 50px;
    border-radius: 26px;
    margin-left: 0;
    margin-bottom: 20px;
  }
`;
