// todo: アイコンを変更
import ArchivesHeaderIcon from "images/archives_icon.png";
import { PageHeader } from "./PageHeader";
import React from "react";

const title = "メンバーリスト";
const subtitle = "MEMBER LIST";

export const MemberListHeader: React.FC = () => {
  const description = [
    {
      text: `メンバーが大集合！！あのプロジェクトに参加している人、地域が近い人や、同じ趣味の人...
      いろんなメンバーをみてみよう！`,
      em: false,
    },
  ];

  // todo:アイコンを変更
  return <PageHeader title={title} subtitle={subtitle} icon={ArchivesHeaderIcon} description={description} />;
};
