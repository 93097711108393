import { useReducer } from "react";

const init = {
  title: "",
  category: "",
  date: "",
  url: "",
  comment: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "update":
      return { ...state, [action.name]: action.value };
    case "init":
      return action.data;
    default:
      return state;
  }
};

export const useForm = () => {
  const [formData, formDispatch] = useReducer(reducer, init);
  return [formData, formDispatch];
};
