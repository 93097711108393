import React from "react";
import styled from "styled-components";
import { SMARTPHONE_WIDTH } from "../styles/constants";
import { Red, White } from "../styles/colors";
import GuideHeaderIcon from "images/guide_icon.png";
import { PageHeader } from "./PageHeader";

const title = '前田デザイン室の歩き方';
const subtitle = 'GUIDE';
const icon = GuideHeaderIcon;
const lead = 'はじめましての方へ。';
const description = [
  {
    text: `はじめまして、前田デザイン室へようこそ。前田デザイン室・運営チームです。
  前田デザイン室(以下「前デ」)は、元・任天堂デザイナー前田高志が率いるクリエイティブ集団です。
  `,
    em: false
  },
  {
    text: '「おもろ！たのし！いいな！」',
    em: true
  },
  {
    text: 'をモットーに、世の中に新しいクリエイティブを大量投下していきます。',
    em: false
  }
];

export const GuideHeader = () => {
  return (
    <>
      <PageHeader
        title={title}
        subtitle={subtitle}
        icon={icon}
        lead={lead}
        description={description}
      />
      <GuideWalkLink
        href="https://drive.google.com/file/d/1tZkcgVBy1phEgBWBXW1uovMG1fHyaRzV/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GuideWalkButton>
          <BeginnerIcon className="view-beginner" />
          <Text>前田さんからのメッセージ(note)</Text>
          <Arrow>></Arrow>
        </GuideWalkButton>
      </GuideWalkLink>
    </>
  );
};

const GuideWalkLink = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const GuideWalkButton = styled.div`
  position: relative;
  display: flex;
  width: 490px;
  height: 84px;
  margin: 60px auto;
  border-radius: 45px;
  border: solid 2px ${Red};
  box-shadow: 0.5px 0.9px 3.8px 0.2px rgba(30, 30, 30, 0.2);
  background-color: ${White};
  color: ${Red};
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  transition: all 0.2s;
  &:hover {
    background-color: ${Red};
    color: ${White};
  }
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    width: 320px;
    height: 50px;
    margin: 26px auto;
  }
`;

const BeginnerIcon = styled.i`
  font-size: 42px;
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    font-size: 23px;
  }
`;

const Text = styled.div`
  font-size: 20px;
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    font-size: 14px;
    letter-spacing: 0.98px;
  }
`;

const Arrow = styled.div`
  position: absolute;
  right: 30px;
  font-size: 32px;
  transform: ;
  transform: translate(-50%) scale(0.6,1.4);
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    right: 15px;
    font-size: 24px;
  }
`;
