import React from "react";
import { FiHome, FiList, FiUsers, FiVideo, FiFacebook } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

export const AdminMenu = ({ community }) => {
  const location = useLocation();
  return (
    <Wrapper>
      <AdminItemLink to="/admin">
        <AdminItem selected={location.pathname === "/admin"}>
          <FiHome size={24} />
          <Title>コミュニティ管理</Title>
        </AdminItem>
      </AdminItemLink>
      <AdminItemLink to="/admin/members">
        <AdminItem selected={location.pathname === "/admin/members"}>
          <FiUsers size={24} />
          <Title>ユーザー管理</Title>
        </AdminItem>
      </AdminItemLink>
      <AdminItemLink to="/admin/contents_categories">
        <AdminItem selected={location.pathname === "/admin/contents_categories"}>
          <FiList size={24} />
          <Title>コンテンツカテゴリ</Title>
        </AdminItem>
      </AdminItemLink>
      <AdminItemLink to="/admin/videos_categories">
        <AdminItem selected={location.pathname === "/admin/videos_categories"}>
          <FiVideo size={24} />
          <Title>ビデオカテゴリ</Title>
        </AdminItem>
      </AdminItemLink>
      <AdminItemLink to="/admin/facebook">
        {community?.id === 1 && (
          <AdminItem selected={location.pathname === "/admin/facebook"}>
            <FiFacebook size={24} />
            <Title>Facebook 連携</Title>
          </AdminItem>
        )}
      </AdminItemLink>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 240px;
  flex-shrink: 0;
`;

const AdminItemLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const AdminItem = styled.div`
  display: flex;
  font-size: 13px;
  padding: 15px 14px;
  align-items: center;
  transition: all 0.2s;
  ${(p) =>
    p.selected
      ? css`
          color: #fff;
          background-color: #e50213;
        `
      : css`
          color: #000;
          &:hover {
            color: #e50213;
            background-color: rgba(229, 2, 19, 0.1);
          }
        `};
`;

const Icon = styled.i`
  font-size: 38px;
  &.view-favorite-b {
    font-size: 36px;
  }
`;

const Title = styled.div`
  margin-left: 6px;
`;
