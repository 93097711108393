import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";

import reducer from "../reducers/index";

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];

  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(reducer, undefined, composeEnhancers(...enhancers));

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("../reducers/index", () => store.replaceReducer(reducer));
  }

  return store;
}
