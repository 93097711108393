import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { AddMember } from "./AddMember";
import { UserList } from "./UserList";
import { CsvUploadSection } from "./CsvUploadSection";
import { CheckCsvUploadModal } from "./CheckCsvUploadModal";

const fetchDefaultEditable = async () => {
  const res = await axios.get("/admin/default_editable_setting");
  return res.data;
};

const fetchUsers = async () => {
  const res = await axios.get("/admin/users");
  return res.data;
};

export type User = {
  id: number;
  owner: boolean;
  real_name: string;
  user_name: string;
  email: string;
  confirmed: boolean;
  editable: boolean;
  locked: boolean;
};

type TmpUser = {
  email: string;
  user_name: string;
  real_name: string;
};

export type WillUpdateUsers = {
  will_be_deleted_members: TmpUser[];
  will_be_added_members: TmpUser[];
};

export const ManageMembers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [files, setFiles] = useState<File[] | null>(null);
  const [withEditable, setWithEditable] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [willUpdateUsers, setWillUpdateUsers] = useState<WillUpdateUsers | null>(null);

  useEffect(() => {
    fetchUsers().then((data) => {
      setUsers(data);
    });
  }, []);

  useEffect(() => {
    fetchDefaultEditable().then((data) => {
      setWithEditable(data.default_editable);
    });
  }, []);

  const toggleEditable = useCallback(() => {
    setWithEditable(!withEditable);
    const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
    axios.post(
      "/admin/update_default_editable",
      { default_editable: !withEditable },
      {
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      }
    );
  }, [withEditable]);

  const handleChangeFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFiles(Array.from(e.target.files));
    },
    [setFiles]
  );

  const addMember = useCallback(
    (user: User) => {
      setUsers([user, ...users]);
    },
    [users]
  );

  const uploadFile = useCallback(async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files[]", file);
    });
    formData.append("editable", withEditable.toString());
    const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
    const res = await axios.post("/admin/import_csv", formData, {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    });
    setUploaded(true);
    setUsers(res.data);
  }, [files, withEditable]);

  const checkCsv = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files[]", file);
    });
    const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
    const res = await axios.post("/admin/check_import_csv", formData, {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    });
    setWillUpdateUsers(res.data);
  };

  const closeModal = () => {
    setWillUpdateUsers(null);
  };

  return (
    <>
      <CsvUploadSection
        files={files}
        checkCsv={checkCsv}
        uploaded={uploaded}
        withEditable={withEditable}
        handleChangeFile={handleChangeFile}
        toggleEditable={toggleEditable}
      />
      <AddMember addMember={addMember} />
      <UserList users={users} setUsers={setUsers} />
      {willUpdateUsers && users && (
        <CheckCsvUploadModal
          willUpdateUsers={willUpdateUsers}
          uploadFile={uploadFile}
          closeModal={closeModal}
          currentUsersCount={users.length}
        />
      )}
    </>
  );
};
