import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import styled, { css } from "styled-components";
import { ArchivedVideosProps } from "../hooks/useArchivedVideos";
import { Red } from "../styles/colors";
import { Content } from "../types/contents";
import { ContentsGroup } from "../types/contentsGroup";
import { ArchivedContentsList } from "./ArchivedContentsList";
import { ArchivedVideos } from "./ArchivedVideos";
import { IconComponent } from "./IconComponent";

const ROOT_PATH = "/view/archives";
const VIDEO_PATH = "/view/archives/video";

type Props = {
  contentsGroups: ContentsGroup[];
  archivedContents: Content[];
} & ArchivedVideosProps;

export const ArchivesContents: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <Tabs>
        <Tab opened={[VIDEO_PATH, ROOT_PATH].includes(location.pathname) ? 1 : 0} to={VIDEO_PATH}>
          <Icon className="view-past-video" />
          <TabText>過去の配信</TabText>
        </Tab>
        {props.contentsGroups
          .filter((cg) => cg.date_limitable === true)
          .map((cg) => (
            <Tab
              key={cg.id}
              opened={location.pathname === `/view/archives/${cg.id}` ? 1 : 0}
              to={`/view/archives/${cg.id}`}
            >
              <IconComponent iconName={cg.icon_name} />
              <TabText>{`過去の${cg.name}`}</TabText>
            </Tab>
          ))}
      </Tabs>
      <Switch>
        {props.contentsGroups.map((cg) => (
          <Route key={cg.id} exact path={`/view/archives/${cg.id}`}>
            <ArchivedContentsList
              contentsGroupId={cg.id}
              contents={props.archivedContents.filter((c) => c.contents_group_id === cg.id)}
            />
          </Route>
        ))}
        <Route path={["/view/archives", "/view/archives/video"]}>
          <ArchivedVideos
            addVideo={props.addVideo}
            categories={props.categories}
            videos={props.videos}
            backToVideoIndex={props.backToVideoIndex}
            categoriesFilter={props.categoriesFilter}
            handleDelete={props.handleDelete}
            handleCategoriesFilter={props.handleCategoriesFilter}
          />
        </Route>
      </Switch>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 1200px;
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled(Link)`
  display: flex;
  height: 64px;
  border: 1px solid #d2d2d2;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  &:visited {
    color: #000;
  }
  &:hover {
    text-decoration: none;
    background-color: rgba(229, 2, 19, 0.1);
    color: ${Red};
  }
  ${(p) =>
    p.opened &&
    css`
      background-color: ${Red};
      color: #fff;
      &:visited {
        color: #fff;
      }
      &:hover {
        background-color: ${Red};
        color: #fff;
      }
    `}
`;

const Icon = styled.i`
  font-size: 34px;
`;

const TabText = styled.p`
  margin-left: 8px;
  font-size: 14px;
  letter-spacing: 1.12;
`;
