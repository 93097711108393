import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { SnsIconsFragment } from "../@types/types";

type snsDataType = {
  linkPath?: string;
  snsName: string;
  icon: string;
};

export const SNS_ICONS_FRAGMENT = gql`
  fragment SnsIcons on Profile {
    facebookUrl
    instagramUrl
    twitterUrl
    noteUrl
    homepageUrl
  }
`;

export const SNSList = styled.ul`
  display: flex;
  gap: 0 4px;
`;

interface Props {
  snsUrls: SnsIconsFragment;
}

export const SnsIcons: React.FC<Props> = ({ snsUrls }) => {
  const snsData: snsDataType[] = [
    {
      linkPath: snsUrls?.twitterUrl || undefined,
      snsName: "twitter",
      icon: require("images/icon-twitter.svg"),
    },
    {
      linkPath: snsUrls?.noteUrl || undefined,
      snsName: "note",
      icon: require("images/icon-note.svg"),
    },
    {
      linkPath: snsUrls?.facebookUrl || undefined,
      snsName: "facebook",
      icon: require("images/icon-facebook.svg"),
    },
    {
      linkPath: snsUrls?.instagramUrl || undefined,
      snsName: "instagram",
      icon: require("images/icon-instagram.svg"),
    },
    {
      linkPath: snsUrls?.homepageUrl || undefined,
      snsName: "homepage",
      icon: require("images/icon-homepage.svg"),
    },
  ];
  return (
    <>
      {snsData.map(
        (data, index) =>
          data.linkPath && (
            <SNSListItem key={index}>
              <SNSLink href={data.linkPath} target="_blank" rel="noopener noreferrer">
                <SNSIcon>
                  <SNSIconImage src={data.icon} alt={data.snsName} />
                </SNSIcon>
              </SNSLink>
            </SNSListItem>
          )
      )}
    </>
  );
};

const SNSListItem = styled.li``;

const SNSLink = styled.a`
  display: block;
  cursor: pointer;
  border-radius: 50%;
  background: #fff;
  transition: transform ease-in-out 0.175s;
  &:hover {
    background: #fff;
    transform: translateY(-2px);
  }
`;

const SNSIcon = styled.div``;

const SNSIconImage = styled.img``;
