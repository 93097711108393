import React from "react";
import styled from "styled-components";
import { ContainerCommunityFragment } from "../@types/types";
import { Red } from "../styles/colors";
import { Event } from "../types/event";
import { CloseModalIcon } from "./CloseModalIcon";
import { EventFormModal } from "./EventFormModal";
import { EventList } from "./EventList";
import { EventShow } from "./EventShow";
import { ModalBase } from "./ModalBase";

export type EventModalState = null | "showEventList" | "editableEventList" | "eventEdit" | "eventShow" | "eventDelete";

interface Props {
  community: ContainerCommunityFragment;
  closeEventModal: () => void;
  openEventShow: (arg: number) => void;
  openEventEdit: (arg: number) => void;
  openEventDelete: (event: Event) => void;
  isOpenEventModal: boolean;
  eventModalState: EventModalState;
  // TODO: イベント一覧を分離して nullable ではなくする
  showEvents: Event[] | null;
  handleDeleteEvent: (arg: number) => void;
  modalEvent: Event | null;
}

export const EventModals: React.FC<Props> = ({
  community,
  closeEventModal,
  openEventShow,
  openEventEdit,
  openEventDelete,
  isOpenEventModal,
  eventModalState,
  showEvents,
  handleDeleteEvent,
  modalEvent,
}) => {
  return (
    <>
      {eventModalState === "showEventList" && showEvents && (
        <ModalBase
          isOpen={isOpenEventModal}
          onRequestClose={closeEventModal}
          contentLabel="Day Event Modal"
          width={500}
          spWidth={290}
        >
          <EventList showEvents={showEvents} handleClick={openEventShow} closeModal={closeEventModal} />
        </ModalBase>
      )}
      {eventModalState === "editableEventList" && showEvents && (
        <ModalBase
          isOpen={isOpenEventModal}
          onRequestClose={closeEventModal}
          contentLabel="Day Event Modal"
          width={500}
          spWidth={290}
        >
          <EventList showEvents={showEvents} handleClick={openEventEdit} closeModal={closeEventModal} />
        </ModalBase>
      )}

      {eventModalState === "eventEdit" && (
        <EventFormModal
          open={isOpenEventModal}
          modalEvent={modalEvent}
          community={community}
          onCloseModal={closeEventModal}
          openEventDelete={openEventDelete}
        />
      )}
      {eventModalState === "eventShow" && modalEvent && (
        <ModalBase
          isOpen={isOpenEventModal}
          onRequestClose={closeEventModal}
          contentLabel="Day Event Modal"
          width={420}
          spWidth={290}
        >
          <EventShow modalEvent={modalEvent} closeModal={closeEventModal} />
        </ModalBase>
      )}
      {eventModalState === "eventDelete" && modalEvent && (
        <ModalBase
          isOpen={isOpenEventModal}
          onRequestClose={closeEventModal}
          contentLabel="Day Event Modal"
          width={420}
          spWidth={290}
        >
          <Base>
            <CloseModalIcon onClick={closeEventModal} className="view-close" />
            <Title>このスケジュールを削除しますか？</Title>
            <SubText>一度削除したスケジュールは復元できません。</SubText>

            <Buttons>
              <CancelButton onClick={closeEventModal}>キャンセル</CancelButton>
              <DeleteButton
                onClick={() => {
                  handleDeleteEvent(modalEvent.id);
                }}
              >
                削除する
              </DeleteButton>
            </Buttons>
          </Base>
        </ModalBase>
      )}
    </>
  );
};

const Base = styled.div`
  padding: 69px 38px 51px;
  position: relative;
  @media screen and (max-width: 1024px) {
    padding: 50px 34px 51px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
`;

const SubText = styled.div`
  font-size: 14px;
  text-align: center;
  margin: 24px 0 46px;
  line-height: 24px;
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    margin: 24px auto 46px;
    width: 165px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const Button = styled.div`
  cursor: pointer;
  height: 36px;
  border-radius: 22px;
  border: solid 2px #6c6c6c;
  box-shadow: 0.5px 0.9px 2px 0px rgba(30, 30, 30, 0.2);
  line-height: 37px;
  text-align: center;
`;

const DeleteButton = styled(Button)`
  margin-left: 21px;
  width: 136px;
  border: solid 2px ${Red};
  color: ${Red};
  transition: all 0.2s;
  &:hover {
    color: #fff;
    background-color: ${Red};
  }
  @media screen and (max-width: 1024px) {
    margin-left: 0;
    margin-bottom: 20px;
  }
`;

const CancelButton = styled(Button)`
  width: 140px;
  color: #6c6c6c;
  transition: all 0.2s;
  &:hover {
    color: #fff;
    background-color: #6c6c6c;
  }
`;
