import moment from "moment";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { ContainerCommunityFragment } from "../@types/types";
import { sendEvent } from "../actions/Events";
import { Red } from "../styles/colors";
import { Event as ViewEvent } from "../types/event";
import { CloseModalIcon } from "./CloseModalIcon";
import {
  CancelButton,
  DeleteButton,
  ErrorMessage,
  FormWrapper,
  Hint,
  InformationsWrapper,
  InputWrapper,
  Label,
  SubmitButton,
} from "./FormParts";
import { ModalTitle, TextInput } from "./ModalBase";
import { TimeInput } from "./TimeInput";

export type EventFormData = {
  id: string;
  title: string;
  description: string;
  link_url: string;
  start_date: string;
  start_time: string;
  end_date: string;
  end_time: string;
  with_notification: string;
  facebook_id?: string;
};

const defaultFormData = (defaultEventUrl: string): EventFormData => {
  return {
    id: "",
    title: "",
    description: "",
    link_url: defaultEventUrl || "",
    start_date: moment().add(1, "days").format("YYYY-MM-DD"),
    start_time: "00:00",
    end_date: moment().add(1, "days").format("YYYY-MM-DD"),
    end_time: "00:00",
    with_notification: "",
  };
};

type ErrorMessages = {
  title: string | null;
  description: string | null;
  link_url: string | null;
  date_limit: string | null;
};

const initialErrorMessages: ErrorMessages = {
  title: null,
  description: null,
  link_url: null,
  date_limit: null,
};

const useEventFormData = (modalEvent: ViewEvent | null, closeModal: () => void, defaultEventUrl: string) => {
  const dispatch = useDispatch();
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>(initialErrorMessages);

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [formData, setFormData] = useState<EventFormData>(
    modalEvent
      ? {
          id: modalEvent?.id?.toString(),
          title: modalEvent.title,
          description: modalEvent.description,
          link_url: modalEvent.link_url,
          start_date: moment(modalEvent.started_at).format("YYYY-MM-DD"),
          start_time: moment(modalEvent.started_at).format("HH:mm"),
          end_date: moment(modalEvent.ended_at).format("YYYY-MM-DD"),
          end_time: moment(modalEvent.ended_at).format("HH:mm"),
          with_notification: "",
          facebook_id: modalEvent.facebook_id,
        }
      : defaultFormData(defaultEventUrl)
  );

  const validate = useCallback(() => {
    let sendable = true;
    const newErrorMessages = JSON.parse(JSON.stringify(initialErrorMessages));
    if (formData) {
      if (formData.title.length === 0) {
        newErrorMessages.title = "タイトルを入力してください";
        sendable = false;
      }
      if (
        formData.start_date.length === 0 ||
        formData.start_time.length === 0 ||
        formData.end_date.length === 0 ||
        formData.end_time.length === 0
      ) {
        newErrorMessages.date_limit = "開始日時と終了予定日時を入力してください";
        sendable = false;
      }
      if (formData.link_url.length === 0) {
        newErrorMessages.link_url = "URLを入力してください";
        sendable = false;
      }
      if (formData.description.length === 0) {
        newErrorMessages.description = "コメントを入力してください";
        sendable = false;
      }
      setErrorMessages(newErrorMessages);
      return sendable;
    }
    return false;
  }, [formData]);

  const handleSubmit = useCallback(
    (e) => {
      if (submitDisabled) {
        return;
      }
      setSubmitDisabled(true);
      e.preventDefault();
      const sendable = validate();
      if (sendable) {
        const csrf_token = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]")?.content || "";
        dispatch(sendEvent(formData, csrf_token, closeModal, setSubmitDisabled));
      } else {
        setSubmitDisabled(false);
      }
    },
    [closeModal, dispatch, formData, submitDisabled, validate]
  );

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData, setFormData]
  );

  const updateCheck = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData({
        ...formData,
        with_notification: e.target.checked.toString(),
      });
    },
    [formData, setFormData]
  );

  return { formData, setFormData, handleSubmit, errorMessages, changeHandler, updateCheck };
};

interface Props {
  modalEvent: ViewEvent | null;
  closeModal: () => void;
  openEventDelete?: (event: ViewEvent) => void;
  community: ContainerCommunityFragment;
}

export const EventForm: React.FC<Props> = ({ modalEvent, closeModal, openEventDelete, community }) => {
  const { formData, setFormData, handleSubmit, errorMessages, changeHandler, updateCheck } = useEventFormData(
    modalEvent,
    closeModal,
    community.defaultEventUrl
  );

  return (
    <>
      <ModalTitle>
        <CloseModalIcon onClick={closeModal} className="view-close" />
        {modalEvent ? "スケジュールの編集" : "新規スケジュールの作成"}
      </ModalTitle>
      <FormWrapper>
        <form onSubmit={handleSubmit}>
          <InformationsWrapper>
            <input type="hidden" onChange={changeHandler} name="id" value="" />
            <InputWrapper>
              <Label must>タイトル</Label>
              <div>
                <TextInput
                  onChange={changeHandler}
                  placeholder="簡潔なイベント名を入力してください"
                  name="title"
                  value={formData.title}
                  error={errorMessages.title}
                />
                <ErrorMessage>{errorMessages.title}</ErrorMessage>
              </div>
            </InputWrapper>
            <InputWrapper>
              <Label must>日次</Label>
              <div>
                <TimeForms>
                  <StartDateTime>
                    <DateInput
                      onChange={changeHandler}
                      type="date"
                      name="start_date"
                      value={formData.start_date}
                      error={errorMessages.date_limit}
                    />
                    <div style={{ marginLeft: 9 }}>
                      <TimeInput
                        formData={formData}
                        setFormData={setFormData}
                        changeHandler={changeHandler}
                        name={"start_time"}
                        error={errorMessages.date_limit}
                      />
                    </div>
                  </StartDateTime>
                  <EndDateTime>
                    <DateInput
                      onChange={changeHandler}
                      type="date"
                      name="end_date"
                      value={formData.end_date}
                      error={errorMessages.date_limit}
                    />
                    <div style={{ marginLeft: 9 }}>
                      <TimeInput
                        formData={formData}
                        setFormData={setFormData}
                        changeHandler={changeHandler}
                        name={"end_time"}
                        error={errorMessages.date_limit}
                      />
                    </div>
                  </EndDateTime>
                </TimeForms>
                <ErrorMessage>{errorMessages.date_limit}</ErrorMessage>
              </div>
            </InputWrapper>
            <InputWrapper>
              <Label must>URL</Label>
              <div>
                <TextInput
                  onChange={changeHandler}
                  placeholder="urlを入力"
                  name="link_url"
                  value={formData.link_url}
                  error={errorMessages.link_url}
                />
                <ErrorMessage>{errorMessages.link_url}</ErrorMessage>
                <Hint>
                  ※ Facebookや外部ページにリンクを設定する場合はURLをご入力ください。 （デフォルトでは前田デザイン室
                  アトリエ（zoom）のURLが入力されています。）
                </Hint>
              </div>
            </InputWrapper>
            <InputWrapper>
              <Label must>コメント</Label>
              <div>
                <Textarea
                  onChange={changeHandler}
                  placeholder="イベントの説明"
                  name="description"
                  value={formData.description}
                  error={errorMessages.description}
                />
                <ErrorMessage>{errorMessages.description}</ErrorMessage>
                <Hint>※ 必要に応じて140字以内でご入力ください。</Hint>
              </div>
            </InputWrapper>

            <CheckBoxWrapper>
              <CheckBox
                type="checkbox"
                onChange={updateCheck}
                name="with_notification"
                value="true"
                checked={formData.with_notification === "true"}
              />
              <LabelTitle>{community.name}メンバーに通知を送る</LabelTitle>
            </CheckBoxWrapper>
          </InformationsWrapper>
          <Buttons>
            {modalEvent && openEventDelete && (
              <DeleteButton onClick={() => openEventDelete(modalEvent)}>削除する</DeleteButton>
            )}
            <RightButtons>
              <CancelButton onClick={closeModal}>キャンセル</CancelButton>
              <SubmitButton onClick={handleSubmit}>
                {modalEvent ? "スケジュールに反映する" : "スケジュールを追加する"}
              </SubmitButton>
            </RightButtons>
          </Buttons>
        </form>
      </FormWrapper>
    </>
  );
};

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

const DateInput = styled.input<{ error: string | null }>`
  font-size: 14px;
  width: 155px;
  height: 48px;
  border: solid 1px #d2d2d2;
  line-height: 48px;
  padding: 0;
  padding-left: 18px;
  ${({ error }) =>
    error !== null &&
    css`
      border: solid 1px ${Red};
      background-color: rgba(229, 2, 19, 0.1);
    `};
  @media screen and (max-width: 1024px) {
    width: 138px;
    padding-left: 10px;
    height: 38px;
    line-height: 38px;
    font-size: 13px;
  }
`;

const TimeForms = styled.div`
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

const DateTime = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StartDateTime = styled(DateTime)`
  @media screen and (max-width: 1024px) {
    margin-bottom: 14px;
  }
`;
const EndDateTime = styled(DateTime)`
  margin-left: 32px;
  @media screen and (max-width: 1024px) {
    margin-left: 0;
  }
`;

const Textarea = styled.textarea<{ error: string | null }>`
  resize: none;
  border: solid 1px #d2d2d2;
  font-size: 14px;
  width: 632px;
  height: 100px;
  padding: 18px;
  ${(p) =>
    p.error !== null &&
    css`
      border: solid 1px ${Red};
      background-color: rgba(229, 2, 19, 0.1);
    `};
  @media screen and (max-width: 1024px) {
    width: 234px;
    height: 60px;
    padding: 14px 12px;
    font-size: 13px;
  }
`;

const Buttons = styled.div`
  display: flex;
  padding-top: 40px;
  justify-content: space-between;
  border-top: solid 1px #d2d2d2;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const CheckBox = styled.input`
  margin: 18px 8px;
`;

const LabelTitle = styled.div`
  margin-left: 8px;
  line-height: 48px;
  font-size: 13px;
  @media screen and (max-width: 1024px) {
    line-height: 38px;
  }
`;
