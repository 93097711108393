import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppData } from "../actions/AppData";
import { FacebookGroupSelectorModal } from "./FacebookGropuSelectorModal";
import styled from "styled-components";
import { EventFormModal, useEventFormModal } from "./EventFormModal";
import moment from "moment";
import { ContentFormModal, useContentFormModal } from "./ContentFormModal";
import { ContentsGroup } from "../types/contentsGroup";
import { fetchContentsGroups } from "../actions/ContentsGroups";
import { Red } from "../styles/colors";
import { SubmitButton } from "./FormParts";

const FACEBOOK_EVENT_BASE_URL = "https://www.facebook.com/events";

const fetchFacebookContents = async () => {
  const feeds_response = await axios.get("/api/admin/facebook_feeds");
  const events_response = await axios.get("/api/admin/facebook_events");
  return {
    feeds: feeds_response.data?.contents,
    events: events_response.data?.contents,
    updatedAt: feeds_response.data?.updated_at,
  };
};

type FacebookFeed = {
  updated_at: string;
  message: string;
  url: string;
  cover: string | undefined;
  id: string;
  name?: string;
  registered: boolean;
};

type FacebookEvent = {
  cover?: string;
  description: string;
  place_name?: string;
  latitude?: number;
  longitude?: number;
  started_at: string;
  ended_at?: string;
  name: string;
  updated_at: string;
  id: string;
  registered: boolean;
};

const selector = (state: { appData: any; contentsGroups: ContentsGroup[] }) => ({
  appData: state.appData,
  contentsGroups: state.contentsGroups,
});

export const AdminFacebook: React.FC = () => {
  const dispatch = useDispatch();
  const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
  const [feeds, setFeeds] = useState<FacebookFeed[]>([]);
  const [events, setEvents] = useState<FacebookEvent[]>([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState<Date>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { appData, contentsGroups } = useSelector(selector);
  const { path } = useRouteMatch();
  const { onOpenModal: onOpenEventModal, ...eventModalProps } = useEventFormModal(appData.community);
  const { onOpenModal: onOpenContentModal, ...contentModalProps } = useContentFormModal(
    appData.community,
    contentsGroups
  );

  const setFacebookContents = useCallback(() => {
    fetchFacebookContents().then((contents) => {
      setFeeds(contents.feeds);
      setEvents(contents.events);
      setLastUpdatedAt(contents.updatedAt ? moment(contents.updatedAt).toDate() : null);
    });
  }, [setFeeds, setEvents]);

  useEffect(() => {
    dispatch(fetchAppData());
    dispatch(fetchContentsGroups());
    setFacebookContents();
  }, [dispatch, setFacebookContents]);

  const refetchFacebookContents = useCallback(() => {
    setLoading(true);
    const header = {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    };
    axios
      .post("/api/admin/refetch_facebook_contents", {}, header)
      .then(() => {
        setFacebookContents();
        setLoading(false);
      })
      .catch((response) => {
        console.error(response);
      });
  }, [csrfToken, setFacebookContents]);

  if (appData?.community?.id !== 1) return null;

  return (
    <>
      {appData?.community?.facebook_linked ? (
        <>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <SubmitButton onClick={refetchFacebookContents} style={{ marginLeft: 0 }}>
              Facebook の情報を取得する
            </SubmitButton>
            {lastUpdatedAt && <p>{`最終取得日時: ${moment(lastUpdatedAt).format("YYYY年MM月DD日 HH:mm")}`}</p>}
          </div>
          {loading ? (
            <div>Facebook の情報を取得しています。しばらくお待ち下さい</div>
          ) : (
            <>
              <div>
                <h2 style={{ fontSize: 24, margin: "24px 0 8px" }}>直近の投稿一覧</h2>
                {lastUpdatedAt ? (
                  <List>
                    {feeds.map((f) => (
                      <ListItem key={f.id}>
                        <a href={f.url} target="_blank" rel="noopener noreferrer">
                          <Name>{f.message || f.name}</Name>
                        </a>
                        {f.registered ? (
                          <div>登録済み</div>
                        ) : (
                          <AddButton
                            onClick={() =>
                              onOpenContentModal({
                                id: null,
                                comment: f.message || f.name,
                                facebook_url: f.url,
                                image_url: f.cover,
                                name: (f.message || f.name).slice(0, 18),
                                contents_group_id: null,
                                is_archived: false,
                                facebook_id: f.id,
                              })
                            }
                          >
                            追加
                          </AddButton>
                        )}
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <div>
                    まだ一度も Facebook からデータを取得していません。上の取得するボタンを押して取得してください。
                  </div>
                )}
              </div>
              <div>
                <h2 style={{ fontSize: 24, margin: "24px 0 8px" }}>直近のイベント一覧</h2>
                {lastUpdatedAt ? (
                  <List>
                    {events.map((e) => (
                      <ListItem key={e.id}>
                        <a href={`${FACEBOOK_EVENT_BASE_URL}/${e.id}`} target="_blank" rel="noopener noreferrer">
                          <Name>{e.name}</Name>
                        </a>
                        {e.registered ? (
                          <div>登録済み</div>
                        ) : (
                          <AddButton
                            onClick={() =>
                              onOpenEventModal({
                                id: null,
                                description: e.description,
                                ended_at: e.ended_at ? moment(e.ended_at).toDate() : undefined,
                                started_at: moment(e.started_at).toDate(),
                                link_url: `${FACEBOOK_EVENT_BASE_URL}/${e.id}`,
                                title: e.name,
                                user_id: appData.user.id,
                                user_name: appData.user.name,
                                facebook_id: e.id,
                              })
                            }
                          >
                            追加
                          </AddButton>
                        )}
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <div>
                    まだ一度も Facebook からデータを取得していません。左上の取得するボタンを押して取得してください。
                  </div>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <form action="/auth/facebook" method="post">
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <SubmitButton style={{ marginLeft: 0, marginTop: 16 }}>
            {appData?.community?.facebook_linked ? "Facebook再連携" : "Facebook連携"}
          </SubmitButton>
        </form>
      )}
      <Route path={`${path}/group_selector`}>
        <FacebookGroupSelectorModal setFacebookContents={setFacebookContents} />
      </Route>
      <EventFormModal {...eventModalProps} />
      <ContentFormModal {...contentModalProps} />
    </>
  );
};

const List = styled.ul``;
const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  & + & {
    border-top: 1px solid #aaa;
  }
`;

const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 650px;
`;

const AddButton = styled.button`
  cursor: pointer;
  width: 80px;
  background-color: #fff;
  color: ${Red};
  border-radius: 16px;
  border: 1px solid ${Red};
  &:hover {
    background-color: ${Red};
    color: #fff;
  }
`;
