import { useCallback } from "react";
import moment from "moment";
import { useState } from "react";
import { Event } from "../types/event";

moment.locale("ja");

type FormattedEvent = {
  id: number;
  title: string;
  user_id: number;
  start_time: string;
  end_time: string;
};

export type GroupedEvents = {
  [key: string]: FormattedEvent[];
};

export const useGroupingEventsByDay = (): [GroupedEvents, (events: Event[]) => void] => {
  const [groupedEventsByDay, setGroupedEvents] = useState<GroupedEvents>({});

  const setGroupedEventsByDay = useCallback((events: Event[]) => {
    const groupedEvents: GroupedEvents = {};
    events.forEach((e) => {
      const dates = [];
      const dateStart = moment(e.started_at);
      const dateIncriment = moment(e.started_at);
      const dateEnd = moment(e.ended_at);
      while (dateIncriment <= dateEnd) {
        dates.push(dateIncriment.format("M月D日 (ddd)"));
        dateIncriment.add(1, "day");
      }
      dates.forEach((date) => {
        const formattedEvent: FormattedEvent = {
          id: e.id,
          title: e.title,
          user_id: e.user_id,
          start_time: date === dateStart.format("M月D日 (ddd)") ? moment(e.started_at).format("HH:mm") : "00:00",
          end_time: date === dateEnd.format("M月D日 (ddd)") ? moment(e.ended_at).format("HH:mm") : "23:59",
        };
        if (groupedEvents[date]) {
          groupedEvents[date] = [...groupedEvents[date], formattedEvent];
        } else {
          groupedEvents[date] = [formattedEvent];
        }
      });
    });
    setGroupedEvents(groupedEvents);
  }, []);

  return [groupedEventsByDay, setGroupedEventsByDay];
};
