import React from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { Red } from "../styles/colors";

const MonoLogoPath = require("images/logo_mono.png");

export default function ViewFooter({ communityName }) {
  return (
    <>
      <FooterWrapper>
        <Footer>
          <Logo src={MonoLogoPath} />
          <MediaQuery query="(min-width: 1040px)">
            <FooterLinksWrapper>
              <TopLinks>
                <Title>VIEW</Title>
                <FooterLink
                  href="https://corporate.view-inc.net/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  運営会社
                </FooterLink>
                <FooterLink
                  href="https://corporate.view-inc.net/gp"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  利用規約
                </FooterLink>
                <FooterLink
                  href="https://corporate.view-inc.net/privacy-policy"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  プライバシポリシー
                </FooterLink>
              </TopLinks>
            </FooterLinksWrapper>
          </MediaQuery>
        </Footer>
      </FooterWrapper>
      <Copyright>Copyright (C) VIEW inc. All Rights Reserved.</Copyright>
    </>
  );
}

const FooterWrapper = styled.div`
  background-color: #262626;
  display: flex;
  justify-content: center;
  color: #fff;
`;

const Copyright = styled.div`
  background-color: ${Red};
  text-align: center;
  padding: 26px 26px 22px;
  color: #fff;
  font-size: 10px;
`;

const Footer = styled.div`
  width: 1200px;
`;

const Logo = styled.img`
  display: block;
  margin: 48px 8px 54px;
  font-size: 32px;
`;

const FooterLinksWrapper = styled.div`
  display: flex;
`;

const TopLinks = styled.div`
  width: 260px;
`;

const Title = styled.div`
  font-size: 15px;
  margin-bottom: 34px;
`;

const FooterLink = styled.a`
  cursor: pointer;
  font-size: 13px;
  margin-bottom: 28px;
  text-decoration: none;
  display: block;
  color: #fff;
  &:hover {
    text-decoration: none;
    background-color: unset;
    color: #666;
  }
`;
