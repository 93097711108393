import React from "react";
import styled from "styled-components";
interface Props {
  imagePath: string;
  linkPath: string;
}

export const ProfileContentCard: React.FC<Props> = ({ imagePath, linkPath }) => {
  return (
    <Card>
      <CardItem>
        <CardLink href={linkPath}>
          <ImageWrapper>
            <Image src={imagePath}></Image>
          </ImageWrapper>
        </CardLink>
      </CardItem>
    </Card>
  );
};

const Card = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  margin-bottom: -16px;
`;

const CardItem = styled.li`
  padding: 0 8px;
  width: 33.333%;
  margin-bottom: 16px;
`;

const CardLink = styled.a`
  display: block;
`;

const ImageWrapper = styled.div``;

const Image = styled.img`
  vertical-align: bottom;
  width: 100%;
`;
