import React from "react";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";

export const Label: React.FC<{ must?: boolean; width?: number }> = ({ must, width, children }) => {
  return (
    <LabelWrapper width={width}>
      <LabelTitle>{children}</LabelTitle>
      {must && <Must>必須</Must>}
    </LabelWrapper>
  );
};

const LabelWrapper = styled.div<{ width?: number }>`
  display: flex;
  width: ${({ width }) => width || 120}px;
  flex-shrink: 0;
`;

const LabelTitle = styled.div`
  line-height: 48px;
  font-size: 13px;
  @media screen and (max-width: 1024px) {
    line-height: 38px;
  }
`;

const Must = styled.div`
  flex-shrink: 0;
  background-color: #d2d2d2;
  color: ${Red};
  font-size: 12px;
  border-radius: 3px;
  padding: 2px;
  height: 15px;
  margin-top: 15px;
  margin-left: 8px;
  @media screen and (max-width: 1024px) {
    margin-top: 11px;
    padding: 2px 6px;
    margin-left: 4px;
  }
`;

export const Hint = styled.div`
  max-width: 670px;
  margin-top: 10px;
  font-size: 12px;
  color: #666;
  line-height: 21px;
  letter-spacing: 0.96px;
  @media screen and (max-width: 1024px) {
    font-size: 10px;
    line-height: 16px;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  height: 40px;
  border-radius: 22px;
  border: solid 2px #6c6c6c;
  box-shadow: 0.5px 0.9px 2px 0px rgba(30, 30, 30, 0.2);
  line-height: 35px;
  text-align: center;
  background-color: #fff;
`;

export const CancelButton = styled(Button)`
  width: 134px;
  color: #6c6c6c;
  transition: all 0.2s;
  &:hover {
    color: #fff;
    background-color: #6c6c6c;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 40px;
    width: 210px;
    height: 40px;
    line-height: 40px;
    border-radius: 21px;
  }
`;

export const SubmitButton = styled(Button)`
  margin-left: 21px;
  ${({ width }) =>
    width
      ? css`
          width: ${width}px;
        `
      : css`
          width: 200px;
        `};
  border: solid 2px ${Red};
  color: ${Red};
  transition: all 0.2s;
  &:hover {
    color: #fff;
    background-color: ${Red};
  }
  @media screen and (max-width: 1024px) {
    width: 250px;
    height: 54px;
    line-height: 50px;
    border-radius: 26px;
    margin-left: 0;
    margin-bottom: 20px;
  }
`;

export const DeleteButton = styled.div`
  margin: 5px 0;
  cursor: pointer;
  text-align: center;
  width: 114px;
  height: 34px;
  line-height: 32px;
  background-color: #e4e4e4;
  border-radius: 17px;
  color: #000;
  transition: all 0.2s;
  &:hover {
    background-color: #c0c0c0;
  }
  @media screen and (max-width: 1024px) {
    width: 214px;
    height: 44px;
    line-height: 40px;
    border-radius: 21px;
  }
`;

export const FormWrapper = styled.div`
  padding: 0 50px 40px 50px;
  width: 890px;
  @media screen and (max-width: 1024px) {
    width: 290px;
    padding: 0 15px 40px;
  }
`;

export const InformationsWrapper = styled.div`
  padding-top: 50px;
  @media screen and (max-width: 1024px) {
    padding-top: 20px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  @media screen and (max-width: 1024px) {
    display: block;
    margin-bottom: 15px;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 13px;
  color: ${Red};
  margin-top: 8px;
`;

export const Textarea = styled.textarea`
  resize: none;
  border: solid 1px #d2d2d2;
  font-size: 14px;
  width: 632px;
  height: 100px;
  padding: 18px;
  ${(p) =>
    p.error !== null &&
    css`
      border: solid 1px ${Red};
      background-color: rgba(229, 2, 19, 0.1);
    `};
  @media screen and (max-width: 1024px) {
    width: 234px;
    height: 60px;
    padding: 14px 12px;
    font-size: 13px;
  }
`;
