import axios, { AxiosError, AxiosResponse } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FiClock, FiPlus } from "react-icons/fi";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";
import { ContentsGroup } from "../types/contentsGroup";
import { AdminTextInput } from "./AdminTextInput";
import { CancelButton, DeleteButton } from "./FormParts";
import { IconComponent } from "./IconComponent";
import { IconSelector } from "./IconSelector";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

const fetchContentsGroupsData = async () => {
  const response = await axios.get("/api/contents_groups");
  return response.data as ContentsGroup[];
};

const emptyContentsGroup: ContentsGroup = {
  id: null,
  name: "",
  icon_name: "",
  date_limitable: false,
  with_event: false,
  has_contents: false,
  slag: "",
  description: "",
  rank: 0,
};

export const ManageContentsGroups: React.FC = () => {
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [contentsGroups, setContentsGroups] = useState<ContentsGroup[]>([]);
  const [selectedContentsGroupId, setSelectedContentsGroupId] = useState<
    number | null
  >(null);

  const {
    errors,
    setError,
    setValue,
    watch,
    handleSubmit,
    control,
    reset,
  } = useForm<ContentsGroup>();

  useEffect(() => {
    fetchContentsGroupsData().then((data) => {
      setContentsGroups(data)
    });
  }, []);

  const editContentsGroup = (cg: ContentsGroup) => {
    setSelectedContentsGroupId(cg.id);
    setEditMode(true);
    reset(cg);
  };

  const reorderContentGroups = (contentsGroups: ContentsGroup[], startIndex: number, endIndex: number) => {
    const orderedList = Array.from(contentsGroups);
    const [movedGroup] = orderedList.splice(startIndex, 1);
    orderedList.splice(endIndex, 0, movedGroup);
    orderedList.forEach((contentsGroup, index) => {
      contentsGroup.rank = index + 1;
    });
    return orderedList;
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (result.destination == null) return;
    const reOrderedContentsGroups = reorderContentGroups(contentsGroups, result.source.index, result.destination.index);
    reOrderedContentsGroups.map((cg) => submit(cg).catch((e) => console.error("reorder failed", e)));
    setContentsGroups(reOrderedContentsGroups);
  };

  const deleteSelectedContentsGroup = useCallback(
    (id: number) => {
      const csrfToken = document.head.querySelector<HTMLMetaElement>(
        "meta[name=csrf-token]"
      ).content;
      axios
        .delete(`/api/admin/delete_contents_group`, {
          data: {
            id,
          },
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        })
        .then(() => {
          const index = contentsGroups.findIndex((cg) => cg.id === id);
          setContentsGroups([
            ...contentsGroups.slice(0, index),
            ...contentsGroups.slice(index + 1),
          ]);
        })
        .catch((e) => {
          alert(e.response.body);
        });
    },
    [contentsGroups]
  );

  const submit = useCallback(
    (data: ContentsGroup) => {
      const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
      return axios.post("/api/admin/contents_group", data, {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
      });
    },
    [contentsGroups]
  );

  const setUpdatedContentsGroup = (res: AxiosResponse) => {
          if (res.data?.id) {
            const updatedContentsGroup = res.data;
      const index = contentsGroups.findIndex((cg) => cg.id === updatedContentsGroup.id);
      if (index != -1) {
            setContentsGroups([
              ...contentsGroups.slice(0, index),
              updatedContentsGroup,
              ...contentsGroups.slice(index + 1),
            ]);
          } else {
            setContentsGroups([...contentsGroups, res.data]);
          }
    } else {
      setContentsGroups([...contentsGroups, res.data]);
    }
  };
  const setErrorOfUpdatedContentGroup = (e: AxiosError) => {
          const resErrors = e.response.data;
          const keys = Object.keys(resErrors);
          keys.forEach((k: keyof ContentsGroup) => {
            setError(k, {
              type: "server",
              message: resErrors[k].join(", "),
            });
          });
  };

  const resetForm = useCallback(() => {
    reset(
      selectedContentsGroupId === null
        ? emptyContentsGroup
        : contentsGroups.find((cg) => cg.id === selectedContentsGroupId)
    );
  }, [reset, selectedContentsGroupId, contentsGroups]);

  return (
    <>
      <p>現在アクティブなコンテンツカテゴリ</p>
      <ListWrapper>
        <ContentsGroupItem
          onClick={() => editContentsGroup({ ...emptyContentsGroup, rank: contentsGroups.length + 1 })}
          style={{ color: Red }}
        >
          <ContentsGroupIcon>
            <FiPlus size={32} />
          </ContentsGroupIcon>
          <ContentsGroupName>新規カテゴリ作成</ContentsGroupName>
        </ContentsGroupItem>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="contentGroupDroppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {contentsGroups.map((cg, index) => (
                  <Draggable key={cg.id} draggableId={`${cg.id}`} index={index}>
                    {(provided) => (
                      <ContentsGroupItem
                        key={cg.id}
                        onClick={() => editContentsGroup(cg)}
                        selected={cg.id === selectedContentsGroupId}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {cg.date_limitable && (
                          <>
                            <DateLimitable selected={cg.id === selectedContentsGroupId}>
                              <FiClock size={16} />
                            </DateLimitable>
                            <ClockBackground selected={cg.id === selectedContentsGroupId} />
                          </>
                        )}
                        <ContentsGroupIcon>
                          {cg.icon_name && <IconComponent iconName={cg.icon_name} />}
                        </ContentsGroupIcon>

                        <ContentsGroupName>{cg.name}</ContentsGroupName>
                      </ContentsGroupItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ListWrapper>

      {isEditMode && (
        <EditorWrapper>
          <form
            onSubmit={handleSubmit((data) => {
              submit(data)
                .then((res) => setUpdatedContentsGroup(res))
                .catch((e) => setErrorOfUpdatedContentGroup(e));
            })}
          >
            <Controller
              as={<input type="hidden" />}
              name={`id`}
              defaultValue={selectedContentsGroupId}
              control={control}
            />
            <Label>カテゴリアイコン</Label>
            <Error>{errors.icon_name?.message}</Error>
            <Controller
              render={({ onChange, onBlur, value }) => (
                <IconSelector iconSelectHandler={onChange} selectedIconName={value} />
              )}
              name={`icon_name`}
              control={control}
              rules={{ required: true }}
            />
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ onChange, onBlur, value }) => (
                <AdminTextInput onChange={onChange} value={value} label="カテゴリ名" />
              )}
            />
            <Error>{errors.name?.message}</Error>
            {/* <Controller
              control={control}
              name="slag"
              rules={{ required: true }}
              render={({ onChange, onBlur, value }) => (
                <AdminTextInput
                  onChange={onChange}
                  value={value}
                  label="Slag設定"
                />
              )}
            />
            <Error>{errors.slag?.message}</Error> */}
            <Controller
              control={control}
              name="date_limitable"
              render={({ onChange, onBlur, value }) => (
                <CheckBoxWrapper>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      onChange(e.currentTarget.checked);
                      if (!e.currentTarget.checked) {
                        setValue("with_event", false);
                      }
                    }}
                    checked={value}
                  />
                  <label>期間設定可能なカテゴリ</label>
                </CheckBoxWrapper>
              )}
            />
            <Error>{errors.date_limitable?.message}</Error>
            <Controller
              control={control}
              name="with_event"
              render={({ onChange, onBlur, value }) => (
                <CheckBoxWrapper>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      onChange(e.currentTarget.checked);
                    }}
                    checked={value}
                    disabled={!watch("date_limitable")}
                  />
                  <label>カレンダーに予定として表示するカテゴリ</label>
                </CheckBoxWrapper>
              )}
            />
            <Controller
              as={<input type="hidden" />}
              name={`rank`}
              defaultValue={contentsGroups.length}
              control={control}
            />
            <Error>{errors.with_event?.message}</Error>
            <Buttons>
              {selectedContentsGroupId && (
                <DeleteButton
                  type="button"
                  onClick={() => {
                    deleteSelectedContentsGroup(selectedContentsGroupId);
                    setEditMode(false);
                  }}
                >
                  削除する
                </DeleteButton>
              )}
              <RightButtons>
                <CancelButton type="button" onClick={resetForm}>
                  キャンセル
                </CancelButton>
                <SubmitButton type="submit" value={selectedContentsGroupId ? "更新する" : "追加する"} />
              </RightButtons>
            </Buttons>
          </form>
        </EditorWrapper>
      )}
    </>
  );
};

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const Error = styled.p`
  color: ${Red};
`;

const ContentsGroupItem = styled.div<{ selected: boolean; ref: (HTMLElement) => void }>`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #dedede;
  width: 100%;
  height: 68px;
  padding: 16px;
  margin: 12px 0 0 0;
  flex-shrink: 0;
  background-color: #fff;
  &:hover {
    background-color: rgba(229, 2, 19, 0.1);
  }
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${Red};
      color: #fff;
      &:hover {
        background-color: ${Red};
      }
    `}
`;

const ContentsGroupIcon = styled.div`
  width: 48px;
  justify-content: center;
  text-align: center;
`;

const ContentsGroupName = styled.p``;
const EditorWrapper = styled.div`
  margin-top: 24px;
  border-top: 1px solid #dedede;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  margin: 16px 0;
`;

const Label = styled.label`
  margin: 16px 0 8px;
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const Buttons = styled.div`
  display: flex;
  padding-top: 40px;
  justify-content: space-between;
  border-top: solid 1px #d2d2d2;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const DateLimitable = styled.div<{ selected: boolean }>`
  z-index: 1;
  position: absolute;
  bottom: 4px;
  right: 4px;
  color: #fff;

  ${({ selected }) =>
    selected &&
    css`
      color: ${Red};
    `}
`;

const ClockBackground = styled.div<{ selected: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 40px 40px;
  border-color: transparent transparent ${Red} transparent;

  ${({ selected }) =>
    selected &&
    css`
      border-color: transparent transparent #fff transparent;
    `}
`;

const SubmitButton = styled.input`
  cursor: pointer;
  margin-left: 21px;
  border-radius: 22px;
  background-color: unset;
  width: 200px;
  border: solid 2px ${Red};
  color: ${Red};
  transition: all 0.2s;
  height: 40px;
  &:hover {
    color: #fff;
    background-color: ${Red};
  }
  @media screen and (max-width: 1024px) {
    width: 250px;
    height: 54px;
    line-height: 50px;
    border-radius: 26px;
    margin-left: 0;
    margin-bottom: 20px;
  }
`;
