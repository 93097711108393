import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

export const MyPageLeftMenu = () => {
  const location = useLocation();

  return (
    <Wrapper>
      <MyPageItemLink to="/view/my_page">
        <MyPageItem selected={location.pathname === "/view/my_page"}>
          <Icon className="view-person" />
          <Title>プロフィール</Title>
        </MyPageItem>
      </MyPageItemLink>
      {/*
      <MyPageItemLink to="/view/my_page/schedule">
        <MyPageItem selected={location.pathname === "/view/my_page/schedule"}>
          <Icon className="view-person" />
          <Title>スケジュール</Title>
        </MyPageItem>
      </MyPageItemLink>
      <MyPageItemLink to="/view/my_page/contents">
        <MyPageItem selected={location.pathname === "/view/my_page/contents"}>
          <Icon className="view-person" />
          <Title>コンテンツ</Title>
        </MyPageItem>
      </MyPageItemLink>
      <MyPageItemLink to="/view/my_page/favorite">
        <MyPageItem selected={location.pathname === "/view/my_page/favorite"}>
          <Icon className="view-favorite-b" />
          <Title>お気に入り</Title>
        </MyPageItem>
      </MyPageItemLink> */}
      <MyPageItemLink to="/view/my_page/notification">
        <MyPageItem
          selected={location.pathname === "/view/my_page/notification"}
        >
          <Icon className="view-notification" />
          <Title>通知</Title>
        </MyPageItem>
      </MyPageItemLink>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 240px;
`;

const MyPageItemLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const MyPageItem = styled.div`
  display: flex;
  font-size: 13px;
  padding: 15px 14px;
  align-items: center;
  transition: all 0.2s;
  ${(p) =>
    p.selected
      ? css`
          color: #fff;
          background-color: #e50213;
        `
      : css`
          color: #000;
          &:hover {
            color: #e50213;
            background-color: rgba(229, 2, 19, 0.1);
          }
        `};
`;

const Icon = styled.i`
  font-size: 38px;
  &.view-favorite-b {
    font-size: 36px;
  }
`;

const Title = styled.div`
  margin-left: 6px;
`;
