import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { fetchVideos, deleteVideo, sendVideos } from "../actions/Videos";
import { useDispatch, useSelector } from "react-redux";
import { Video, VideoCategory } from "../types/video";
import { fetchVideoCategories } from "../actions/VideoCategories";

export type CategoryFilter = {
  id: number | null;
  name: string;
  checked: boolean;
  disabled: boolean;
};

export type ArchivedVideosProps = {
  addVideo: any;
  categories: VideoCategory[];
  videos: Video[];
  backToVideoIndex: () => void;
  categoriesFilter: CategoryFilter[];
  handleDelete: (id: number) => void;
  handleCategoriesFilter: (e: Event) => void;
};

export const useArchivedVideos = (): ArchivedVideosProps => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);

  const backToVideoIndex = useCallback(() => {
    history.push("/view/archives/video");
  }, [history]);

  const addVideo = useCallback(
    ({ formData }) => {
      if (uploading) {
        return;
      }
      setUploading(true);
      const video = {
        id: formData.id,
        title: formData.title,
        date: formData.date,
        comment: formData.comment,
        url: formData.url,
        video_category_id: formData.video_category_id,
      };
      const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
      const action = sendVideos({ video, csrfToken, setUploading, backToVideoIndex });
      dispatch(action);
    },
    [uploading, dispatch, setUploading, backToVideoIndex]
  );

  const { videos, videoCategories: categories } = useSelector(
    (state: { videos: Video[]; videoCategories: VideoCategory[] }) => state
  );
  const [categoriesFilter, setCategoriesFilter] = useState<CategoryFilter[]>([]);
  useEffect(() => {
    dispatch(fetchVideos());
    dispatch(fetchVideoCategories());
  }, []);

  useEffect(() => {
    if (categories.length > 0) {
      setCategoriesFilter(
        categories.map((value) => {
          return {
            id: value.id,
            name: value.name,
            checked: true,
            disabled: true,
          };
        })
      );
    }
  }, [categories]);

  const handleCategoriesFilter = useCallback(
    (e) => {
      if (e.target.name === "all") {
        if (categoriesFilter.every((c) => c.disabled)) {
          setCategoriesFilter(categoriesFilter.map((c) => ({ ...c, disabled: false })));
        } else {
          setCategoriesFilter(
            categoriesFilter.map((c) => ({
              ...c,
              disabled: true,
              checked: true,
            }))
          );
        }
      } else {
        const updated = categoriesFilter.map((element) => {
          if (element.name === e.target.name) {
            return {
              ...element,
              checked: !element.checked,
            };
          }
          return element;
        });

        setCategoriesFilter(updated);
      }
    },
    [categoriesFilter]
  );

  const handleDelete = useCallback(
    (id: number) => {
      const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
      dispatch(deleteVideo({ id, csrfToken, backToVideoIndex }));
    },
    [dispatch]
  );

  return {
    addVideo,
    backToVideoIndex,
    videos,
    categories,
    categoriesFilter,
    handleDelete,
    handleCategoriesFilter,
  };
};
