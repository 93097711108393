import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { fetchNotifications } from "../actions/Notifications";
import { Red } from "../styles/colors";
import ContentsGroupsDropDownMenu from "./ContentsGroupsDropDownMenu";
import { ProfileWidget } from "./ProfileWidget";
import { SmartPhoneMenuWidget } from "./SmartPhoneMenuWidget";
import { ZoomWidget } from "./ZoomWidget";

const LogoImagePath = require("images/VIEW_logo.png");

const notificationSelector = (state) => state.notifications;

export default function ViewHeader({
  communityId,
  iconUrl,
  contentsGroups,
  subscribeNotification,
  userName,
  openSPMenu,
  setOpenSPMenu,
  videoConferenceUrl,
}) {
  const [displayDropDown, setDisplayDropDown] = useState(false);

  const notifications = useSelector(notificationSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  return (
    <Base>
      <TopLinkWrapper to="/view">
        {iconUrl && (
          <IconWrapper>
            <Icon src={iconUrl} />
          </IconWrapper>
        )}
        <LogoWrapper>
          <Logo src={LogoImagePath} />
        </LogoWrapper>
      </TopLinkWrapper>
      <MediaQuery query="(min-width: 1040px)">
        <GlobalLinks>
          <ContentsLinks>
            <Contents
              onMouseEnter={() => setDisplayDropDown(true)}
              onMouseLeave={() => setDisplayDropDown(false)}
              hovered={displayDropDown.toString()}
              to="/view"
            >
              コンテンツ
            </Contents>
            {communityId === 1 && <Member to="/view/members">メンバーリスト</Member>}
            <Archives to="/view/archives">アーカイブ</Archives>
            {/* <Sns to="/view">SNS</Sns> */}
          </ContentsLinks>
          <Line />
          <PersonalWidgets>
            {videoConferenceUrl && <ZoomWidget communityId={communityId} videoConferenceUrl={videoConferenceUrl} />}
            {/* <NotificationWidget
              notifications={notifications}
              subscribeNotification={subscribeNotification}
            /> */}
            <ProfileWidget userName={userName} />
          </PersonalWidgets>
        </GlobalLinks>
        {displayDropDown && (
          <ContentsGroupsDropDownMenu contentsGroups={contentsGroups} setDisplayDropDown={setDisplayDropDown} />
        )}
      </MediaQuery>
      <MediaQuery query="(max-width: 1039px)">
        <SmartPhonePersonalWidgets>
          <ProfileWidget userName={userName} />
          {/* <SmartPhoneNotificationWidget notifications={notifications} /> */}
          <SmartPhoneMenuWidget open={openSPMenu} setOpen={setOpenSPMenu} />
        </SmartPhonePersonalWidgets>
      </MediaQuery>
    </Base>
  );
}

const Base = styled.div`
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 3px solid ${Red};
  font-weight: 500;
  font-size: 15px;
  @media screen and (max-width: 1024px) {
    border-bottom: 2px solid ${Red};
  }
`;

const TopLinkWrapper = styled(Link)`
  display: flex;
  align-items: center;
  margin: 21px 0 16px 38px;
  &:hover {
    background-color: white;
  }
  @media screen and (max-width: 1024px) {
    margin: 19px 0 15px 15px;
  }
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #eee;
  @media screen and (max-width: 1024px) {
    width: 32px;
    height: 32px;
  }
`;

const Icon = styled.img`
  width: 100%;
  border-radius: 50%;
`;

const LogoWrapper = styled.div`
  width: 120px;
  margin-left: 8px;
  @media screen and (max-width: 1024px) {
    width: 68px;
  }
`;

const Logo = styled.img`
  width: 100%;
`;

const GlobalLinks = styled.div`
  display: flex;
`;

const ContentsLinks = styled.div`
  display: flex;
`;

const MenuText = styled(Link)`
  padding: 40px 25px 30px;
  flex-shrink: 0;
  height: 87px;
  transition: all 0.2s;
  &:visited {
    color: black;
  }
  &:hover {
    border-bottom: 6px solid ${Red};
    color: ${Red};
    background-color: white;
    text-decoration: none;
  }
`;

const Contents = styled(MenuText)``;

const Member = styled(MenuText)`
  padding-left: 24px;
  padding-right: 24px;
`;
const Archives = styled(MenuText)``;

const Sns = styled(MenuText)`
  padding-right: 42px;
`;

const Line = styled.div`
  width: 1px;
  height: 30px;
  background-color: #d2d2d2;
  margin-top: 30px;
`;

const PersonalWidgets = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
`;

const SmartPhonePersonalWidgets = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
