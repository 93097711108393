import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";
import { AdminTextInput } from "./AdminTextInput";
import { CancelButton, DeleteButton } from "./FormParts";

const fetchVideoCategoriesData = async () => {
  const response = await axios.get("/api/video_categories");
  return response.data;
};

type VideoCategory = {
  id: number | null;
  name: string;
};

const emptyVideoCategory = {
  id: null,
  name: "",
};

export const ManageVideoCategories: React.FC = () => {
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [videoCategories, setVideoCategories] = useState<VideoCategory[]>([]);
  const [selectedVideoCategoryId, setSelectedVideoCategoryId] = useState<
    number | null
  >(null);

  const {
    errors,
    setError,
    setValue,
    watch,
    handleSubmit,
    control,
    reset,
  } = useForm<VideoCategory>();

  useEffect(() => {
    fetchVideoCategoriesData().then((data) => {
      setVideoCategories(data);
    });
  }, []);

  const editVideoCategory = (vc: VideoCategory) => {
    setSelectedVideoCategoryId(vc.id);
    setEditMode(true);
    reset(vc);
  };

  const deleteSelectedVideoCategory = useCallback(
    (id: number) => {
      const csrfToken = document.head.querySelector<HTMLMetaElement>(
        "meta[name=csrf-token]"
      ).content;
      axios
        .delete(`/api/admin/delete_video_category`, {
          data: {
            id,
          },
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        })
        .then(() => {
          const index = videoCategories.findIndex((vc) => vc.id === id);
          setVideoCategories([
            ...videoCategories.slice(0, index),
            ...videoCategories.slice(index + 1),
          ]);
        })
        .catch((e) => {
          alert(e.response.body);
        });
    },
    [videoCategories]
  );

  const submit = useCallback(
    (data) => {
      const csrfToken = document.head.querySelector<HTMLMetaElement>(
        "meta[name=csrf-token]"
      ).content;
      axios
        .post("/api/admin/video_category", data, {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        })
        .then((res) => {
          if (res.data?.id?.length > 0) {
            const updatedVideoCategory = res.data;
            const index = videoCategories.findIndex(
              (vc) => vc.id === updatedVideoCategory.id
            );
            setVideoCategories([
              ...videoCategories.slice(0, index),
              updatedVideoCategory,
              ...videoCategories.slice(index + 1),
            ]);
          } else {
            setVideoCategories([...videoCategories, res.data]);
          }
        })
        .catch((e) => {
          const resErrors = e.response.data;
          const keys = Object.keys(resErrors);
          keys.forEach((k: keyof VideoCategory) => {
            setError(k, {
              type: "server",
              message: resErrors[k].join(", "),
            });
          });
        });
    },
    [videoCategories]
  );

  const resetForm = useCallback(() => {
    reset(
      selectedVideoCategoryId === null
        ? emptyVideoCategory
        : videoCategories.find((vc) => vc.id === selectedVideoCategoryId)
    );
  }, [reset, selectedVideoCategoryId, videoCategories]);

  return (
    <>
      <ListWrapper>
        <VideoCategoryItem
          onClick={() => editVideoCategory(emptyVideoCategory)}
          style={{ paddingTop: 0, paddingBottom: 0, color: Red }}
        >
          <VideoCategoryIcon>
            <FiPlus size={32} />
          </VideoCategoryIcon>
          <VideoCategoryName>新規カテゴリ作成</VideoCategoryName>
        </VideoCategoryItem>
        {videoCategories.map((vc) => (
          <VideoCategoryItem
            key={vc.id}
            onClick={() => editVideoCategory(vc)}
            selected={vc.id === selectedVideoCategoryId}
          >
            <VideoCategoryName>{vc.name}</VideoCategoryName>
          </VideoCategoryItem>
        ))}
      </ListWrapper>

      {isEditMode && (
        <EditorWrapper>
          <form onSubmit={handleSubmit(submit)}>
            <Controller
              as={<input type="hidden" />}
              name={`id`}
              defaultValue={selectedVideoCategoryId}
              control={control}
            />
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ onChange, onBlur, value }) => (
                <AdminTextInput
                  onChange={onChange}
                  value={value}
                  label="カテゴリ名"
                />
              )}
            />
            <Error>{errors.name?.message}</Error>

            <Buttons>
              {selectedVideoCategoryId && (
                <DeleteButton
                  onClick={() => {
                    deleteSelectedVideoCategory(selectedVideoCategoryId);
                    setEditMode(false);
                  }}
                >
                  削除する
                </DeleteButton>
              )}
              <RightButtons>
                <CancelButton type="button" onClick={resetForm}>
                  キャンセル
                </CancelButton>
                <SubmitButton
                  type="submit"
                  value={selectedVideoCategoryId ? "更新する" : "追加する"}
                />
              </RightButtons>
            </Buttons>
          </form>
        </EditorWrapper>
      )}
    </>
  );
};

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Error = styled.p`
  color: ${Red};
`;

const VideoCategoryItem = styled.div<{ selected: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #dedede;
  width: 230px;
  padding: 16px;
  margin: 8px;
  flex-shrink: 0;
  &:hover {
    background-color: rgba(229, 2, 19, 0.1);
  }
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${Red};
      color: #fff;
      &:hover {
        background-color: ${Red};
      }
    `}
`;

const VideoCategoryName = styled.p``;
const EditorWrapper = styled.div`
  border-top: 1px solid #dedede;
`;

const Label = styled.label`
  margin: 16px 0 8px;
`;

const VideoCategoryIcon = styled.div`
  width: 48px;
  justify-content: center;
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  padding-top: 40px;
  justify-content: space-between;
  border-top: solid 1px #d2d2d2;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const SubmitButton = styled.input`
  cursor: pointer;
  margin-left: 21px;
  border-radius: 22px;
  background-color: unset;
  width: 200px;
  border: solid 2px ${Red};
  color: ${Red};
  transition: all 0.2s;
  height: 40px;
  &:hover {
    color: #fff;
    background-color: ${Red};
  }
  @media screen and (max-width: 1024px) {
    width: 250px;
    height: 54px;
    line-height: 50px;
    border-radius: 26px;
    margin-left: 0;
    margin-bottom: 20px;
  }
`;
