import React, { useMemo } from "react";
import styled, { css } from "styled-components";

interface Props {
    headline: string;
  }

  export const ContentHeadline: React.FC<Props> = ({ headline }) => {
      return (
        <Headline>{headline}</Headline>
      )
  }

  const Headline = styled.h2`
  font-size: 16px;
  line-height: 2;
  display: flex;
  align-items: center;
  &:before {
    background: #e50213;
    height: 1em;
    width: 4px;
    display: inline-block;
    margin-right: 4px;
    content: '';
  }
  `;