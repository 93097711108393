import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ViewIndexViewerFragment } from "../@types/types";
import { ProfileContent } from "./ProfileContent";
import { ProfileEditorContainer } from "./ProfileEditorContainer";

interface Props {
  viewer: ViewIndexViewerFragment;
}

export const ProfileContentContainer: React.FC<Props> = ({ viewer }) => {
  const location = useLocation();
  const history = useHistory();
  const closeEditor = useCallback(() => {
    history.push("/view/my_page");
  }, [history]);
  return (
    <>
      <Wrapper>
        <ProfileContent member={viewer} editable={true} />
      </Wrapper>
      {location.pathname === "/view/my_page/edit" && (
        <ProfileEditorContainer viewer={viewer} closeEditor={closeEditor} />
      )}
    </>
  );
};

const Wrapper = styled.div`
  margin: 104px auto;
  max-width: 980px;
`;
