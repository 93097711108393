import axios from "axios";
import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { Red } from "../styles/colors";
import { Community } from "./AdminPage";
import { AdminTextInput } from "./AdminTextInput";
import { CommunityIconUploadInput } from "./CommunityIconUploadInput";
import CoverImageUploadComponent from "./CoverImageUploadComponent";
import { CancelButton } from "./FormParts";
import { MaedeNoteForm } from "./MaedeNoteForm";

interface Props {
  community: Community;
}

export const CommunitySettingsForm: React.FC<Props> = ({ community }) => {
  const { handleSubmit, reset, control } = useForm();

  useEffect(() => {
    reset(community);
  }, [community]);

  const onSubmit = (data) => {
    const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
    axios.post("/api/admin/community_settings", data, {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    });
  };

  const onCancel = useCallback(() => {
    reset(community);
  }, [reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <Controller
          control={control}
          name="icon_url"
          render={({ onChange, onBlur, value }) => (
            <CommunityIconUploadInput imageUrl={value} imageUrlHandler={(url) => onChange(url)} error={null} />
          )}
        />
        <CommunityIconLabel>コミュニティアイコン</CommunityIconLabel>
        <Controller
          control={control}
          name="name"
          render={({ onChange, onBlur, value }) => (
            <AdminTextInput onChange={onChange} value={value} label="コミュニティ名" />
          )}
        />
        <Controller
          control={control}
          name="video_conference_url"
          render={({ onChange, onBlur, value }) => (
            <AdminTextInput onChange={onChange} value={value} label="オンラインスペースURL" />
          )}
        />
        <Controller
          control={control}
          name="default_event_url"
          render={({ onChange, onBlur, value }) => (
            <AdminTextInput onChange={onChange} value={value} label="予定作成時のデフォルトURL" />
          )}
        />
        <Controller
          control={control}
          name="how_to_walk_url"
          render={({ onChange, onBlur, value }) => (
            <AdminTextInput
              onChange={onChange}
              value={value}
              label="コミュニティに入ってはじめに読んでほしい資料のURL"
            />
          )}
        />
        <CoverImageLabel>コンテンツ作成時のデフォルト画像</CoverImageLabel>
        <Controller
          control={control}
          name="default_content_image_url"
          render={({ onChange, onBlur, value }) => (
            <CoverImageUploadComponent imageUrl={value} imageUrlHandler={(url) => onChange(url)} error={null} />
          )}
        />
      </Wrapper>
      <Buttons>
        <CancelButton type="button" onClick={onCancel}>
          キャンセル
        </CancelButton>
        <SubmitButton type="submit" value="更新する" />
      </Buttons>
      {community?.id === 1 && community.note_urls && <MaedeNoteForm noteUrls={community.note_urls} />}
    </form>
  );
};

const Wrapper = styled.div`
  border-bottom: 1px solid #aaa;
  padding-bottom: 24px;
`;

const CommunityIconLabel = styled.label`
  text-align: center;
`;

const CoverImageLabel = styled.label`
  margin: 24px 0 8px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px 0;
`;

const SubmitButton = styled.input`
  cursor: pointer;
  margin-left: 21px;
  border-radius: 22px;
  background-color: unset;
  width: 200px;
  border: solid 2px ${Red};
  color: ${Red};
  transition: all 0.2s;
  height: 40px;
  &:hover {
    color: #fff;
    background-color: ${Red};
  }
  @media screen and (max-width: 1024px) {
    width: 250px;
    height: 54px;
    line-height: 50px;
    border-radius: 26px;
    margin-left: 0;
    margin-bottom: 20px;
  }
`;
