import ArchivesHeaderIcon from "images/archives_icon.png";
import { PageHeader } from "./PageHeader";
import React from "react";

const title = "アーカイブ";
const subtitle = "ARCHIVES";

export const ArchivesHeader = ({ community }) => {
  const description = [
    {
      text: `${community.name}のこれまでの活動内容を見ることができます。
    イベントの動画を見たり、プロジェクトの流れを追ったり、見るだけでも学びがたくさん！
    ぜひ活用してみてください。`,
      em: false,
    },
  ];
  return <PageHeader title={title} subtitle={subtitle} icon={ArchivesHeaderIcon} description={description} />;
};
