import { createGlobalStyle } from "styled-components/macro";

const GlobalStyle = createGlobalStyle`
  /* custom */
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap");
  body {
    font-family: "Noto Sans JP", sans-serif;
  }
`;

export default GlobalStyle;
