import styled from "styled-components";
import { Red } from "../styles/colors";

export const CloseModalIcon = styled.i`
  cursor: pointer;
  border-radius: 17px;
  background-color: #fff;
  color: ${Red};
  font-size: 32px;
  position: absolute;
  top: -16px;
  right: -16px;
  width: 30px;
  height: 30px;
  z-index: 10;
  @media screen and (max-width: 1024px) {
    top: -10px;
    right: -8px;
    width: 28px;
    height: 28px;
    font-size: 30px;
  }
`;
