import { Actions } from "../actions/VideoCategories";
import { VideoCategory } from "../types/video";

const videoCategoriesReducer: (state: VideoCategory[], action: any) => VideoCategory[] = (state = [], action) => {
  switch (action.type) {
    case Actions.FETCH_VIDEO_CATEGORIES:
      return [...action.data, { id: null, name: "その他" }];
    default:
      return state;
  }
};

export default videoCategoriesReducer;
