import moment from "moment";
import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { Red } from "../styles/colors";
import { DeleteVideoModal } from "./DeleteVideoModal";
import { EditableVideosModal } from "./EditableVideosModal";
import { VideoFormModal } from "./VideoFormModal";
import { ArchivedVideosProps } from "../hooks/useArchivedVideos";

export const ArchivedVideos: React.FC<ArchivedVideosProps> = ({
  addVideo,
  categories,
  videos,
  backToVideoIndex,
  categoriesFilter,
  handleDelete,
  handleCategoriesFilter,
}) => {
  return (
    <>
      <Switch>
        <Route path={["/view/archives/video/new", "/view/archives/video/:id/edit"]}>
          <VideoFormModal
            addVideo={addVideo}
            categories={categories}
            videos={videos}
            backToVideoIndex={backToVideoIndex}
          />
        </Route>
        <Route path="/view/archives/video/editable">
          <EditableVideosModal videos={videos} />
        </Route>
        <Route path="/view/archives/video/:id/delete">
          <DeleteVideoModal handleDelete={handleDelete} backToVideoIndex={backToVideoIndex} />
        </Route>
      </Switch>
      <Wrapper>
        <VideoOperatorWrapper>
          <Categories>
            <CategorySelector key={"all"}>
              <Selector
                name={"all"}
                type="checkbox"
                checked={categoriesFilter.every((c) => c.checked) && categoriesFilter.every((c) => c.disabled)}
                onChange={handleCategoriesFilter}
              />
              <CategoryLabel>すべて</CategoryLabel>
            </CategorySelector>
            {categoriesFilter &&
              categoriesFilter.map((c) => (
                <CategorySelector key={c.id}>
                  <Selector
                    onChange={handleCategoriesFilter}
                    name={c.name}
                    type="checkbox"
                    checked={c.checked}
                    disabled={c.disabled}
                  />
                  <CategoryLabel>{c.name}</CategoryLabel>
                </CategorySelector>
              ))}
          </Categories>
          <Buttons>
            <Button to="/view/archives/video/new">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "21px",
                  marginRight: "10px",
                }}
              >
                ＋
              </span>
              動画の追加
            </Button>
            <EditButton to="/view/archives/video/editable">
              <Icon className="view-edit" />
              <div style={{ marginLeft: "-10px" }}>内容を編集</div>
            </EditButton>
          </Buttons>
        </VideoOperatorWrapper>
        <VideoListWrapper>
          {categories.map((category) => {
            const title = category.name;
            const groupedVideos = videos.filter((v) => v.video_category_id === category.id);
            const checked = categoriesFilter?.find((c) => c.id === category.id)?.checked;
            return (
              <React.Fragment key={category.id}>
                {checked && (
                  <Category>
                    <LabelWrapper>
                      <Border />
                      <Label>{title}</Label>
                    </LabelWrapper>
                    {groupedVideos.map((v) => (
                      <VideoItem href={v.url} key={v.id} target="_blank" rel="noreferrer noopener">
                        <VideoContents>
                          <Title>{v.title.length > 20 ? v.title.slice(0, 20) + "..." : v.title}</Title>
                          <Date>{moment(v.date).format("YYYY年MM月DD日")}</Date>
                          <Comment>{v.comment.length > 35 ? v.comment.slice(0, 35) + "..." : v.comment}</Comment>
                        </VideoContents>
                        <Arrow>&gt;</Arrow>
                      </VideoItem>
                    ))}
                  </Category>
                )}
              </React.Fragment>
            );
          })}
        </VideoListWrapper>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div``;

const VideoOperatorWrapper = styled.div`
  display: flex;
  height: 136px;
  padding: 40px;
  border: solid 1px #d2d2d2;
  font-size: 14px;
`;

const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CategoryLabel = styled.label``;

const CategorySelector = styled.div`
  display: flex;
  padding: 8px;
`;

const Selector = styled.input``;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 36px;
  box-shadow: 0.5px 0.9px 3.8px 0.2px rgba(30, 30, 30, 0.2);
  border: solid 2px ${Red};
  border-radius: 60px;
  text-align: center;
  font-size: 15px;
  background-color: #fff;
  color: ${Red};
  letter-spacing: 0.6px;
  transition: all 0.2s;
  &:visited {
    text-decoration: none;
    background-color: #fff;
    color: ${Red};
  }
  &:hover {
    text-decoration: none;
    background-color: #e50213;
    color: #fff;
  }
`;

const EditButton = styled(Button)`
  margin-left: 20px;
`;

const Icon = styled.i`
  margin-left: -23px;
  font-size: 50px;
  line-height: 56px;
`;

const VideoListWrapper = styled.div`
  height: 616px;
  background-color: #f5f7f7;
  overflow: scroll;
  padding: 40px 40px;
`;

const Category = styled.div`
  padding-bottom: 30px;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: solid 2px #f5f7f7;
  padding: 21px 50px;
`;

const Border = styled.div`
  width: 4px;
  height: 18px;
  border-radius: 2px;
  background-color: ${Red};
  margin-right: 8px;
`;

const Label = styled.p`
  font-size: 18px;
  letter-spacing: 1.6px;
`;

const VideoItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 50px;
  background-color: #fff;
  border-bottom: solid 2px #f5f7f7;
  &:hover {
    text-decoration: none;
    background-color: rgba(229, 2, 19, 0.1);
    color: ${Red};
  }
  &:active {
    background-color: ${Red};
    color: #fff;
  }
`;

const VideoContents = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  font-size: 16px;
  width: 404px;
`;
const Date = styled.p`
  font-size: 13px;
  width: 150px;
`;
const Comment = styled.p`
  font-size: 13px;
`;

const Arrow = styled.div`
  transform: scale(0.6, 1.4);
  font-size: 22px;
  color: ${Red};
`;
