import React from "react";
import { ModalBase, ModalTitle } from "./ModalBase";
import { CloseModalIcon } from "./CloseModalIcon";
import styled from "styled-components";
import { WillUpdateUsers } from "./ManageMembers";
import { CancelButton, SubmitButton } from "./FormParts";
import { useLog } from "../hooks/useLog";

interface Props {
  willUpdateUsers: WillUpdateUsers;
  uploadFile: () => void;
  closeModal: () => void;
  currentUsersCount: number;
}

export const CheckCsvUploadModal: React.FC<Props> = ({
  willUpdateUsers,
  uploadFile,
  closeModal,
  currentUsersCount,
}) => {
  const log = useLog();
  const submitHandler = () => {
    log({ event_target: "SubmitCsv", event_action: "click" });
    uploadFile();
    closeModal();
  };
  return (
    <ModalBase width={700} isOpen={!!willUpdateUsers} onRequestClose={closeModal}>
      <Base>
        <CloseModalIcon onClick={closeModal} className="view-close" />
        <ModalTitle>CSVアップロードの確認</ModalTitle>
        <ModalContent>
          <p>
            {willUpdateUsers.will_be_added_members.length}人が追加、
            {willUpdateUsers.will_be_deleted_members.length}人が削除され、 ユーザーは
            {currentUsersCount -
              willUpdateUsers.will_be_deleted_members.length +
              willUpdateUsers.will_be_added_members.length}
            人になります。
          </p>
          <UserContentWrapper>
            <HeadingWrapper>
              <Title>追加されるユーザー</Title>
              <MemberCount>{willUpdateUsers.will_be_added_members.length}人</MemberCount>
            </HeadingWrapper>
            <UserListWrapper>
              <Columns>
                <RealName>本名</RealName>
                <UserName>ユーザー名</UserName>
                <Email>メールアドレス</Email>
              </Columns>
              <UserList>
                {willUpdateUsers?.will_be_added_members.map((m) => (
                  <UserItem key={m.email}>
                    <RealName>{m.real_name}</RealName>
                    <UserName>{m.user_name}</UserName>
                    <Email>{m.email}</Email>
                  </UserItem>
                ))}
              </UserList>
            </UserListWrapper>
          </UserContentWrapper>
          <UserContentWrapper>
            <HeadingWrapper>
              <Title>削除されるユーザー</Title>
              <MemberCount>{willUpdateUsers.will_be_deleted_members.length}人</MemberCount>
            </HeadingWrapper>
            <UserListWrapper>
              <Columns>
                <RealName>本名</RealName>
                <UserName>ユーザー名</UserName>
                <Email>メールアドレス</Email>
              </Columns>
              <UserList>
                {willUpdateUsers?.will_be_deleted_members.map((m) => (
                  <UserItem key={m.email}>
                    <RealName>{m.real_name}</RealName>
                    <UserName>{m.user_name}</UserName>
                    <Email>{m.email}</Email>
                  </UserItem>
                ))}
              </UserList>
            </UserListWrapper>
          </UserContentWrapper>

          <Buttons>
            <RightButtons>
              <CancelButton style={{ width: 160 }} onClick={closeModal}>
                キャンセル
              </CancelButton>
              <SubmitButton style={{ width: 200 }} onClick={submitHandler}>
                メンバーを更新する
              </SubmitButton>
            </RightButtons>
          </Buttons>
        </ModalContent>
      </Base>
    </ModalBase>
  );
};

const Base = styled.div`
  min-height: 300px;
  max-height: 1000px;
  position: relative;
`;
const ModalContent = styled.div`
  margin: 16px 32px;
`;

const UserContentWrapper = styled.div`
  margin: 16px 0;
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 16px 0 8px;
`;

const MemberCount = styled.p`
  margin-left: 8px;
  font-size: 14px;
  color: #aaa;
`;

const Title = styled.p`
  font-size: 20px;
`;

const UserListWrapper = styled.div``;

const UserList = styled.div`
  max-height: 280px;
  overflow: scroll;
`;

const Columns = styled.div`
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #d2d2d2;
`;

const UserItem = styled.div`
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #d2d2d2;
`;

const RealName = styled.div`
  width: 200px;
`;

const UserName = styled.div`
  width: 200px;
`;

const Email = styled.div`
  width: 320px;
`;

const Buttons = styled.div`
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
  border-top: solid 1px #d2d2d2;
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
`;
