import React, { useMemo, useState } from "react";
import DefaultIconPath from "images/default_icon.png";
import styled from "styled-components";
import { Red } from "../styles/colors";
import { FiSearch } from "react-icons/fi";
import { gql } from "@apollo/client";
import { MemberListMemberFragment } from "../@types/types";
import { SnsIcons, SNSList } from "./SnsIcons";
import { useHistory } from "react-router-dom";

export const MEMBER_LIST_MEMBER_FRAGMENT = gql`
  fragment MemberListMember on Member {
    id
    user {
      detail {
        userName
        iconUrl
      }
    }
    profile {
      area
      facebookUrl
      favorite
      future
      playing
      intro
      homepageUrl
      instagramUrl
      noteUrl
      twitterUrl
      contents {
        edges {
          node {
            name
          }
        }
      }
    }
  }
`;

const isMemberMatched = (member: MemberListMemberFragment, searchWord: string): boolean => {
  return (
    member.user.detail.userName.includes(searchWord) ||
    member?.profile?.intro?.includes(searchWord) ||
    member?.profile?.favorite?.includes(searchWord) ||
    member?.profile?.area?.includes(searchWord) ||
    member?.profile?.future?.includes(searchWord) ||
    member?.profile?.playing?.includes(searchWord) ||
    member?.profile?.contents.edges?.some((edge) => edge?.node?.name.includes(searchWord)) ||
    false
  );
};

interface Props {
  members: MemberListMemberFragment[];
}

export const MemberListContents: React.FC<Props> = ({ members }) => {
  const history = useHistory();
  const [searchWord, setSearchWord] = useState("");
  const filteredMemberList = useMemo(() => {
    if (searchWord === "") return members;
    // memberのnameの中にsearchWorldが含まれていたら、trueを返す。含まれていなければ、falseを返す
    // さらに、membersの中からtrueを返したものを表示する
    const result = members.filter((member) => isMemberMatched(member, searchWord));

    return result;
  }, [members, searchWord]);

  return (
    <Wrapper>
      <MemberBox>
        <Search>
          <Form method="get" action="#" className="search_container">
            <FiSearch color={"black"} size={24} />
            <SearchInput
              type="text"
              placeholder="キーワードでメンバーを検索(例：10期、神奈川県、プロジェクト名、グラフィックデザイナー、登山）"
              value={searchWord}
              onChange={(event) => setSearchWord(event.target.value)}
            ></SearchInput>
          </Form>
        </Search>
        <MemberList>
          {filteredMemberList.map((member) => {
            return (
              <Member key={member.id} onClick={() => history.push(`/view/members/${member.id}`)}>
                <MemberIcon src={member.user.detail.iconUrl || DefaultIconPath} />
                <Name>{member.user.detail.userName}</Name>
                <Meta>{member?.profile?.area || "-"}</Meta>
                <Introduction>{member?.profile?.intro}</Introduction>
                <SNSList>{member?.profile && <SnsIcons snsUrls={member?.profile} />}</SNSList>
              </Member>
            );
          })}
        </MemberList>
      </MemberBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 40px;
`;

const Search = styled.div`
  height: 50px;
  max-width: 800px;
  align: center;
  padding: 0 10px;
  outline: 0;
  margin: auto;
  background: white;
  background-color: transparent;
  position: relative;
  top: -25px;
`;

const Form = styled.form`
  background-color: white;
  height: 50px;
  max-width: 800px;
  margin: auto;
  border-radius: 50px;
  border-width: 1px;
  border-color: #cdcdcd;
  border-style: solid;
  text-align: left;
  padding-left: 20px;
`;

const SearchInput = styled.input`
  background-color: white;
  height: 30px;
  width: calc(100% - 70px);
  margin: auto;
  outline: none;
  text-align: left;
  padding-left: 20px;
  border: none;
  padding-top: 10px;
`;

const MemberBox = styled.div`
  max-width: 1903px;
  background-color: #f5f7f7;
`;

const MemberList = styled.div`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  @media screen and (max-width: 1023px) {
    justify-content: center;
  }
`;

const Name = styled.div`
  padding-top: 40px;
  text-align: center;
  font-weight: bold;
  line-height: 25px;
`;

const Member = styled.div`
  background-color: #fff;
  cursor: pointer;
  padding: 24px;
  width: 280px;
  height: 280px;
  position: relative;
  border-radius: 10px;
  margin: 48px 16px;
  &:hover {
    background-color: #fce6e7;
    ${Name} {
      color: ${Red};
    }
  }
`;

const Meta = styled.div`
  color: gray;
  text-align: center;
  font-size: small;
  line-height: 40px;
`;

const MemberIcon = styled.img`
  z-index: 2;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
  top: -40px;
  width: 96px;
  height: 96px;
`;

const Introduction = styled.div`
  font-weight: bold;
  font-size: small;
  width: 232px;
  height: 80px;
  margin: 0 auto 20px;
  line-height: 20px;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;
