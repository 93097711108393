import styled, { css } from "styled-components";
import { IconComponent } from "./IconComponent";
import { Red } from "../styles/colors";
import React from "react";
import { ArchivedVideosProps } from "../hooks/useArchivedVideos";

const ArrowImagePath = require("images/arrow.svg");
const UpArrowImagePath = require("images/u_arrow.svg");

export const SmartPhoneArchivedVideo: React.FC<
  ArchivedVideosProps & { openGroupId: number; toggleContentsGroup: (id: number) => void }
> = ({
  addVideo,
  categories,
  videos,
  backToVideoIndex,
  categoriesFilter,
  handleDelete,
  handleCategoriesFilter,
  openGroupId,
  toggleContentsGroup,
}) => {
  const groupId = -1;
  return (
    <VideosGroupWrapper key={groupId}>
      <VideosGroupItem
        onClick={() => {
          toggleContentsGroup(groupId);
        }}
      >
        <GroupTitleWrapper>
          <IconComponent iconName={"view-past-video"} color={Red} size={31} />
          <Title>過去の動画</Title>
        </GroupTitleWrapper>
        <Arrow>
          <ArrowImage src={groupId === openGroupId ? UpArrowImagePath : ArrowImagePath} />
        </Arrow>
      </VideosGroupItem>
      {openGroupId === groupId && (
        <VideosWrapper>
          {videos.map((video) => {
            return (
              <VideoItem key={video.id} href={video.url} target="_blank" rel="noreferrer noopener">
                <TextWrapper>
                  <VideoTitle>{video.title}</VideoTitle>
                  <Description>
                    {video.comment.length < 36 ? video.comment : `${video.comment.slice(0, 50)}...`}
                  </Description>
                </TextWrapper>
              </VideoItem>
            );
          })}
        </VideosWrapper>
      )}
    </VideosGroupWrapper>
  );
};

const VideosGroupWrapper = styled.div`
  border-bottom: solid 1px #efefef;
`;
const VideosGroupItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  padding: 10px 8px 10px;
  align-items: center;
`;

const GroupTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled.div`
  margin-left: 8px;
`;

const Arrow = styled.div`
  width: 13px;
`;
const ArrowImage = styled.img`
  width: 100%;
`;

const VideosWrapper = styled.div``;

const VideoItem = styled.a`
  border-top: solid 1px #efefef;
  transition: all 0.2s;
  display: flex;
  padding: 8px 0;
  &:hover {
    background-color: unset;
    text-decoration: none;
    color: #000;
  }
`;

const TextWrapper = styled.div`
  margin-left: 8px;
`;

const VideoTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin: 4px 0;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.div`
  font-size: 12px;
  color: #424242;
  line-height: 1.42;
`;
