import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { useForm } from "../hooks/useForm";
import { Red } from "../styles/colors";
import { Video, VideoCategory } from "../types/video";
import { CloseModalIcon } from "./CloseModalIcon";
import {
  CancelButton,
  DeleteButton,
  ErrorMessage,
  FormWrapper,
  Hint,
  InformationsWrapper,
  InputWrapper,
  Label,
  SubmitButton,
  Textarea,
} from "./FormParts";
import { ModalBase, ModalTitle, TextInput } from "./ModalBase";
import { VideoCategoryInput } from "./VideoCategoryInput";

interface Props {
  categories: VideoCategory[];
  addVideo: any;
  videos: Video[];
  backToVideoIndex: () => void;
}

export const VideoFormModal: React.FC<Props> = ({ addVideo, categories, videos, backToVideoIndex }) => {
  const history = useHistory();
  const location = useLocation();

  const matchedPath = matchPath<{ id: string }>(location.pathname, {
    path: "/view/archives/video/:id/edit",
    exact: true,
  });

  useEffect(() => {
    const id = matchedPath ? parseInt(matchedPath.params.id, 10) : null;
    if (!id) {
      return;
    }
    const video = videos.find((v) => v.id === id);
    formDispatch({ type: "init", data: video });
  }, [matchPath]);

  const [formData, formDispatch] = useForm();

  const changeHandler = useCallback(
    (e) => {
      formDispatch({
        type: "update",
        name: e.target.name,
        value: e.target.value,
      });
    },
    [formDispatch]
  );

  return (
    <ModalBase isOpen={true} onRequestClose={backToVideoIndex} width={890}>
      <ModalTitle>
        <CloseModalIcon onClick={backToVideoIndex} className="view-close" />
        動画の追加
      </ModalTitle>
      <FormWrapper>
        <InformationsWrapper>
          <InputWrapper>
            <Label must>タイトル</Label>
            <div>
              <TextInput
                onChange={changeHandler}
                placeholder="タイトル"
                name={"title"}
                value={formData.title}
                error={null}
              />
              <ErrorMessage>{null}</ErrorMessage>
            </div>
          </InputWrapper>
          <CategoryWrapper>
            <Label must>カテゴリ</Label>
            <VideoCategoryInput categories={categories} formData={formData} formDispatch={formDispatch} />
          </CategoryWrapper>
          <InputWrapper style={{ justifyContent: "flex-start" }}>
            <Label must>日時</Label>
            <DateInput type="date" onChange={changeHandler} name="date" value={formData.date} error={null} />
            <ErrorMessage>{null}</ErrorMessage>
          </InputWrapper>
          <InputWrapper>
            <Label must>URL</Label>
            <div>
              <TextInput
                placeholder="動画のURL"
                onChange={changeHandler}
                name="url"
                value={formData.url}
                error={null}
              />
              <ErrorMessage>{null}</ErrorMessage>
            </div>
          </InputWrapper>
          <InputWrapper>
            <Label must>コメント</Label>
            <div>
              <Textarea
                type="textarea"
                placeholder="動画の説明"
                onChange={changeHandler}
                name="comment"
                value={formData.comment}
                error={null}
              />
              <ErrorMessage>{""}</ErrorMessage>
              <Hint>※ 必要に応じて80字以内でご入力ください。</Hint>
            </div>
          </InputWrapper>
        </InformationsWrapper>
        <Buttons>
          {formData.id && (
            <DeleteButton onClick={() => history.push(`/view/archives/video/${formData.id}/delete`)}>
              削除する
            </DeleteButton>
          )}

          <RightButtons>
            <CancelButton onClick={backToVideoIndex}>キャンセル</CancelButton>
            <SubmitButton onClick={() => addVideo({ formData })}>動画を追加する</SubmitButton>
          </RightButtons>
        </Buttons>
      </FormWrapper>
    </ModalBase>
  );
};

const CategoryWrapper = styled(InputWrapper)`
  justify-content: flex-start;
`;

const DateInput = styled.input`
  font-size: 14px;
  width: 155px;
  height: 48px;
  border: solid 1px #d2d2d2;
  line-height: 48px;
  padding: 0;
  padding-left: 18px;
  ${(p) =>
    p.error !== null &&
    css`
      border: solid 1px ${Red};
      background-color: rgba(229, 2, 19, 0.1);
    `};
  @media screen and (max-width: 1024px) {
    width: 138px;
    padding-left: 10px;
    height: 38px;
    line-height: 38px;
    font-size: 13px;
  }
`;

const Buttons = styled.div`
  display: flex;
  padding-top: 40px;
  justify-content: space-between;
  border-top: solid 1px #d2d2d2;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;
