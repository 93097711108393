import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { ContentsGroup } from "../types/contentsGroup";
import { IconComponent } from "./IconComponent";
import { FiSearch } from "react-icons/fi";

interface Props {
  contentsGroups: ContentsGroup[];
}

export const ContentsGroupList: React.FC<Props> = ({ contentsGroups }) => {
  const { id } = useParams<{ id: string }>();

  const selectedId = useMemo(() => {
    if (contentsGroups.length > 0) {
      if (id) {
        return id;
      }
      return contentsGroups[0].id.toString();
    }
  }, [id, contentsGroups]);

  return (
    <ContentsGroupWrapper>
      <ContentsGroupLink to={"/view/search"} key={"search"}>
        <ContentsGroupItem selected={selectedId === "search" ? "selected" : null}>
          <FiSearch size={34} />
          <Title>検索</Title>
        </ContentsGroupItem>
      </ContentsGroupLink>
      <ContentsGroupLink to={"/view/favorite"} key={"favorite"}>
        <ContentsGroupItem selected={selectedId === "favorite" ? "selected" : null}>
          <Icon className="view-favorite-b" />
          <Title>お気に入り</Title>
        </ContentsGroupItem>
      </ContentsGroupLink>
      {contentsGroups &&
        contentsGroups.map((contentsGroup) => (
          <ContentsGroupLink to={"/view/" + contentsGroup.id} key={contentsGroup.id}>
            <ContentsGroupItem selected={parseInt(selectedId, 10) === contentsGroup.id ? "selected" : null}>
              <IconComponent iconName={contentsGroup.icon_name} />
              <Title>{contentsGroup.name}</Title>
            </ContentsGroupItem>
          </ContentsGroupLink>
        ))}

      <ContentsGroupLink to={"/view/archives"} key={"archives"}>
        <ContentsGroupItem selected={selectedId === "archives" ? "selected" : null}>
          <IconComponent iconName="view-archive" size={34} />
          <Title>アーカイブ</Title>
        </ContentsGroupItem>
      </ContentsGroupLink>
    </ContentsGroupWrapper>
  );
};

const ContentsGroupWrapper = styled.div`
  width: 243px;
  height: 670px;
  border: 1px solid #d2d2d2;
  border-right: none;
  overflow: scroll;
`;

const ContentsGroupLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const ContentsGroupItem = styled.div`
  display: flex;
  font-size: 13px;
  padding: 15px 14px;
  align-items: center;
  transition: all 0.2s;
  ${(p) =>
    p.selected === "selected"
      ? css`
          color: #fff;
          background-color: #e50213;
        `
      : css`
          color: #000;
          &:hover {
            color: #e50213;
            background-color: rgba(229, 2, 19, 0.1);
          }
        `};
`;

const Icon = styled.i`
  font-size: 38px;
  &.view-favorite-b {
    font-size: 36px;
  }
`;

const Title = styled.div`
  margin-left: 6px;
`;
