import { Actions } from "../actions/AppData";

const initialState = {
  user: null,
};

const appDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_APPDATA:
      return action.data;
    default:
      return state;
  }
};

export default appDataReducer;
