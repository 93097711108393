import React, { useState, useCallback, useEffect, useMemo } from "react";
import { ProfileContentEditModal } from "./ProfileContentEditModal";
import { RelatedContentsEditModal, RELATED_CONTENTS_EDITOR_CONTENTS_GROUP_FRAGMENT } from "./RelatedContentsEditModal";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import {
  ViewIndexViewerFragment,
  UpdateMemberProfilePayload,
  MutationUpdateMemberProfileArgs,
  ContentsGroupsForProfileEditorQuery,
  RelatedContentsEditorContentFragment,
} from "../@types/types";

const UPDATE_MEMBER_PROFILE_MUTATION = gql`
  mutation UpdateMemberProfile($input: UpdateMemberProfileInput!) {
    updateMemberProfile(input: $input) {
      member {
        id
        user {
          detail {
            iconUrl
            userName
          }
        }
        profile {
          twitterUrl
          noteUrl
          facebookUrl
          instagramUrl
          homepageUrl
          intro
          area
          playing
          favorite
          future
          contents {
            edges {
              node {
                id
                name
                imageUrl
                facebookUrl
              }
            }
          }
        }
      }
    }
  }
`;

const CONTENTS_GROUPS_FOR_PROFILE_EDITOR_QUERY = gql`
  query ContentsGroupsForProfileEditor {
    contentsGroups {
      ...RelatedContentsEditorContentsGroup
    }
  }
  ${RELATED_CONTENTS_EDITOR_CONTENTS_GROUP_FRAGMENT}
`;

export type ProfileFormData = {
  memberId: number;
  iconUrl: string;
  userName: string;
  twitterUrl: string;
  noteUrl: string;
  facebookUrl: string;
  instagramUrl: string;
  homepageUrl: string;
  intro: string;
  area: string;
  playing: string;
  favorite: string;
  future: string;
  relatedContentIds: number[];
};

interface Props {
  viewer: ViewIndexViewerFragment;
  closeEditor: () => void;
}

export const ProfileEditorContainer: React.FC<Props> = ({ viewer, closeEditor }) => {
  const { data } = useQuery<ContentsGroupsForProfileEditorQuery>(CONTENTS_GROUPS_FOR_PROFILE_EDITOR_QUERY);
  const contentsGroups = data?.contentsGroups;
  const [editorState, setEditorState] = useState<"profile" | "relatedContents">("profile");
  const form = useForm<ProfileFormData>({
    defaultValues: {
      memberId: viewer?.id,
      iconUrl: viewer?.user?.detail?.iconUrl,
      userName: viewer?.user?.detail?.userName,
      twitterUrl: viewer?.profile?.twitterUrl,
      noteUrl: viewer?.profile?.noteUrl,
      facebookUrl: viewer?.profile?.facebookUrl,
      instagramUrl: viewer?.profile?.instagramUrl,
      homepageUrl: viewer?.profile?.homepageUrl,
      intro: viewer?.profile?.intro,
      area: viewer?.profile?.area,
      playing: viewer?.profile?.playing,
      favorite: viewer?.profile?.favorite,
      future: viewer?.profile?.future,
      relatedContentIds: viewer?.profile?.contents?.edges?.map(({ node: content }) => content.id) || [],
    },
  });

  useEffect(() => {
    form.reset({
      memberId: viewer?.id,
      iconUrl: viewer?.user?.detail?.iconUrl,
      userName: viewer?.user?.detail?.userName,
      twitterUrl: viewer?.profile?.twitterUrl,
      noteUrl: viewer?.profile?.noteUrl,
      facebookUrl: viewer?.profile?.facebookUrl,
      instagramUrl: viewer?.profile?.instagramUrl,
      homepageUrl: viewer?.profile?.homepageUrl,
      intro: viewer?.profile?.intro,
      area: viewer?.profile?.area,
      playing: viewer?.profile?.playing,
      favorite: viewer?.profile?.favorite,
      future: viewer?.profile?.future,
      relatedContentIds: viewer?.profile?.contents?.edges?.map(({ node: content }) => content.id) || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updateMemberProfileFn] = useMutation<UpdateMemberProfilePayload, MutationUpdateMemberProfileArgs>(
    UPDATE_MEMBER_PROFILE_MUTATION
  );
  const onSubmit = useCallback(
    async (d: ProfileFormData) => {
      await updateMemberProfileFn({ variables: { input: d } });
      closeEditor();
    },
    [closeEditor, updateMemberProfileFn]
  );

  const contentsList = useMemo(
    (): RelatedContentsEditorContentFragment[] =>
      contentsGroups
        ? contentsGroups.reduce(
            (contentArray, contentGroup) => contentArray.concat(contentGroup.contents.edges.map((e) => e.node)),
            []
          )
        : [],
    [contentsGroups]
  );

  const relatedContents = useMemo(() => {
    const relatedContentIds = form.getValues("relatedContentIds");
    if (!relatedContentIds) {
      return [];
    }
    const relatedContentIdsByIds: { [id: number]: number } = relatedContentIds.reduce((a, v) => ({ ...a, [v]: v }), {});
    return contentsList.filter((c) => relatedContentIdsByIds[c.id]);
  }, [contentsList, form]);

  useEffect(() => {
    form.register("memberId");
    form.register("iconUrl");
    form.register("relatedContentIds");
    form.watch("relatedContentIds");
  }, [form, form.register]);

  const setRelatedContents = useCallback(
    (relatedContentIds: number[]) => {
      form.setValue("relatedContentIds", relatedContentIds);
    },
    [form]
  );

  if (!contentsGroups) return null;

  return (
    <>
      <ProfileContentEditModal
        name="プロフィールを編集"
        isOpen={true}
        closeModal={closeEditor}
        openRelatedContentsEditor={() => setEditorState("relatedContents")}
        onSubmit={onSubmit}
        formMethods={form}
        relatedContents={relatedContents}
      />
      <RelatedContentsEditModal
        isOpen={editorState == "relatedContents"}
        contentsGroups={contentsGroups}
        relatedContentIds={form.getValues("relatedContentIds")}
        closeModal={() => setEditorState("profile")}
        setRelatedContents={setRelatedContents}
      />
    </>
  );
};
