import axios from "axios";
import { Dispatch } from "redux";
import { EventFormData } from "../components/EventForm";
import { Event } from "../types/event";

export const Actions = {
  FETCH_EVENTS: "EVENTS::FETCH" as const,
  SEND_EVENT: "EVENTS::SEND" as const,
  ADD_EVENT: "EVENTS::ADD" as const,
  DELETE_EVENT: "EVENTS::DELETE" as const,
  REMOVE_EVENT: "EVENTS::REMOVE" as const,
};

export const fetchEvents = ({ started_at, ended_at }: { started_at: string | null; ended_at: string | null }) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const response = await axios.get("/api/events.json", {
      params: {
        started_at,
        ended_at,
      },
    });

    dispatch({
      type: Actions.FETCH_EVENTS,
      data: response.data,
    });
  };
};

export const sendEvent = (
  {
    id,
    title,
    description,
    link_url,
    start_date,
    start_time,
    end_date,
    end_time,
    with_notification,
    facebook_id,
  }: EventFormData,
  csrf_token: string,
  closeModal: () => void,
  setSubmitDisabled: (arg: boolean) => void
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const data = {
      event: {
        id,
        title,
        description,
        link_url,
        started_at: start_date + " " + start_time + " " + "+09:00",
        ended_at: end_date + " " + end_time + " " + "+09:00",
        with_notification,
        facebook_id,
      },
    };
    const header = {
      headers: {
        "X-CSRF-Token": csrf_token,
      },
    };
    axios
      .post<Event>("/api/events", data, header)
      .then((response) => {
        setSubmitDisabled(false);
        closeModal();
        dispatch(addEvent(response.data));
      })
      .catch((response) => {
        setSubmitDisabled(false);
        console.error(response);
      });
  };
};

export const addEvent = (data: Event): { type: typeof Actions.ADD_EVENT; data: Event } => ({
  type: Actions.ADD_EVENT,
  data,
});

export const deleteEvent = (id: number, csrfToken: string, closeModal: () => void) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const header = {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    };
    axios
      .delete("/api/events/" + id, header)
      .then(() => {
        closeModal();
        dispatch(removeEvent(id));
      })
      .catch((response) => {
        console.error(response);
      });
  };
};

const removeEvent = (id: number) => ({ type: Actions.REMOVE_EVENT, id });
