import axios from "axios";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

const SIZE = 5;
function createArrayWithNumbers(length) {
  const array = new Array<number>(length);
  for (var i = 0; i < array.length; i++) {
    array[i] = i;
  }
  return array;
}

export const MaedeNoteForm = ({ noteUrls }) => {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    const csrfToken = document.head.querySelector<HTMLMetaElement>(
      "meta[name=csrf-token]"
    ).content;
    axios.post("/api/admin/notes", data, {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    });
  };

  useEffect(() => {
    reset({ noteUrls });
  }, [noteUrls]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Label style={{ width: "100%" }}>what'sに載せるnoteのurl</Label>
      <div>
        {createArrayWithNumbers(SIZE).map((index) => {
          return (
            <Wrapper key={index}>
              <Input
                name={`noteUrls[${index}]`}
                placeholder="noteのurlを入れる"
                ref={register}
              />
            </Wrapper>
          );
        })}
        <input type="submit" value="更新する" />
      </div>
    </Form>
  );
};

const Form = styled.form`
  max-width: 640px;
`;

const Label = styled.div`
  width: 100%;
  margin: 8px 0;
`;

const Wrapper = styled.div`
  margin: 8px 0;
`;

const Input = styled.input`
  width: 100%;
`;
