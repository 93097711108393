import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";

const ZoomBannerPath = require("images/banner_ATR.png");

export function ZoomWidget({ videoConferenceUrl, communityId }) {
  const [displayDropDown, setDisplayDropDown] = useState(false);
  return (
    <Base
      onMouseEnter={() => setDisplayDropDown(true)}
      onMouseLeave={() => setDisplayDropDown(false)}
      hovered={displayDropDown}
    >
      <Icon className="view-zoom" />
      {displayDropDown && (
        <Widget className="widget">
          <WidgetContent>
            <AtelierLink
              target="_blank"
              rel="noreferrer noopener"
              href={videoConferenceUrl}
            >
              {communityId === 1 && <AtelierBanner src={ZoomBannerPath} />}
              <Text>会員専用オンラインスペースはこちら</Text>
            </AtelierLink>
            {communityId === 1 && (
              <AtelierTutorial
                href="https://drive.google.com/file/d/1cfmsY1HIqc28HwCGEsHiZ3IgeVZ8i0zV/view"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div>アトリエをはじめてご利用の方はこちら</div>
                <Arrow>&gt;</Arrow>
              </AtelierTutorial>
            )}
          </WidgetContent>
        </Widget>
      )}
    </Base>
  );
}

const Base = styled.div`
  position: relative;
  padding: 6px;
  padding-top: 12px;
  transition: all 0.2s;
  ${(p) =>
    p.hovered &&
    css`
      color: ${Red};
    `}
`;

const Icon = styled.i`
  font-size: 42px;
`;

const Widget = styled.div`
  color: #000;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 5;
`;

const WidgetContent = styled.div`
  margin-top: 17px;
  background-color: #fff;
  box-shadow: 1px 1.7px 6px 0 rgba(0, 0, 0, 0.1);
  min-width: 200px;
`;

const AtelierLink = styled.a`
  display: block;
  padding: 20px 20px 14px;
  border-bottom: 1px solid #d2d2d2;
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    background-color: #fff;
    text-decoration: none;
    color: ${Red};
  }
`;

const AtelierBanner = styled.img`
  width: 260px;
  margin-bottom: 12px;
`;

const Text = styled.div`
  font-size: 15px;
  letter-spacing: 1.13px;
`;

const AtelierTutorial = styled.a`
  display: block;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  padding: 15px 19px;
  background-color: unset;
  &:hover {
    color: ${Red};
    background-color: unset;
    text-decoration: none;
  }
`;

const Arrow = styled.div`
  text-align: center;
  font-size: 6px;
  line-height: 10px;
  width: 12px;
  height: 12px;
  border-radius: 7px;
  background-color: ${Red};
  color: #fff;
`;
