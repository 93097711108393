import { IconType } from "react-icons";
import {
  FiActivity,
  FiAlignLeft,
  FiArchive,
  FiBook,
  FiBox,
  FiBriefcase,
  FiCalendar,
  FiCamera,
  FiCheckCircle,
  FiCodesandbox,
  FiCoffee,
  FiCompass,
  FiCreditCard,
  FiDatabase,
  FiDollarSign,
  FiDroplet,
  FiEdit3,
  FiEye,
  FiEyeOff,
  FiFeather,
  FiFileText,
  FiFlag,
  FiFolder,
  FiGlobe,
  FiHeart,
  FiHome,
  FiInfo,
  FiMessageCircle,
  FiMic,
  FiMusic,
  FiSettings,
  FiShare2,
  FiShoppingCart,
  FiShuffle,
  FiSliders,
  FiSmile,
  FiSun,
  FiTag,
  FiTrash2,
  FiTrendingUp,
  FiUser,
  FiVideo,
} from "react-icons/fi";

export const iconList: IconType[] = [
  FiActivity,
  FiAlignLeft,
  FiArchive,
  FiBook,
  FiBox,
  FiBriefcase,
  FiCamera,
  FiCalendar,
  FiCheckCircle,
  FiCodesandbox,
  FiCoffee,
  FiCompass,
  FiCreditCard,
  FiEye,
  FiEyeOff,
  FiFeather,
  FiDatabase,
  FiDollarSign,
  FiDroplet,
  FiEdit3,
  FiFileText,
  FiFlag,
  FiFolder,
  FiGlobe,
  FiHeart,
  FiHome,
  FiInfo,
  FiMessageCircle,
  FiMic,
  FiMusic,
  FiSettings,
  FiShare2,
  FiShoppingCart,
  FiShuffle,
  FiSliders,
  FiSmile,
  FiSun,
  FiTag,
  FiTrash2,
  FiTrendingUp,
  FiUser,
  FiVideo,
];
