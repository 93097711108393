import { Actions } from "../actions/Contents";
import { Content } from "../types/contents";

const contentsReducer = (state: Content[] = [], action: any): Content[] => {
  switch (action.type) {
    case Actions.FETCH_CONTENTS:
      return action.data;
    case Actions.ADD_CONTENT: {
      const withoutUpdateContent = state.filter((element) => element.id !== parseInt(action.data.id));
      return [...withoutUpdateContent, action.data];
    }
    case Actions.REMOVE_CONTENT: {
      const newState = state.filter((element) => element.id !== parseInt(action.id));
      return newState;
    }
    default:
      return state;
  }
};

export default contentsReducer;
