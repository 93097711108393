import React from "react";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";

export const SmartPhoneMenuWidget = ({ open, setOpen }) => {
  const toggleMenu = () => {
    setOpen(!open);
  };
  return (
    <MenuButton onClick={toggleMenu}>
      <Line open={open} />
      <Line open={open} />
      <Line open={open} />
    </MenuButton>
  );
};

const MenuButton = styled.a`
  display: inline-block;
  transition: all 0.4s;
  position: relative;
  width: 20px;
  height: 14px;
  margin-right: 14px;
  &:hover,
  &:active {
    background-color: unset;
  }
`;

const Line = styled.span`
  display: inline-block;
  transition: all 0.4s;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: ${Red};
  &:nth-of-type(1) {
    top: 0;
    ${(p) =>
      p.open &&
      css`
        transform: translateY(6px) rotate(-45deg);
      `};
  }

  &:nth-of-type(2) {
    top: 6px;
    ${(p) =>
      p.open &&
      css`
        opacity: 0;
      `};
  }
  &:nth-of-type(3) {
    bottom: 0;
    ${(p) =>
      p.open &&
      css`
        transform: translateY(-6px) rotate(45deg);
      `};
  }
`;
