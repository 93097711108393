import React, { Ref } from "react";
import DefaultIconPath from "images/default_icon.png";
import { UseFormMethods } from "react-hook-form";
import styled from "styled-components";
import { RelatedContentsEditorContentFragment } from "../@types/types";
import { useLog } from "../hooks/useLog";
import { Red } from "../styles/colors";
import { AvatarImageUploader } from "./AvatarImageUploader";
import { CloseModalIcon } from "./CloseModalIcon";
import { Button, CancelButton, FormWrapper, InputWrapper, Label, SubmitButton } from "./FormParts";
import { ModalBase, ModalTitle, TextInput } from "./ModalBase";
import { ProfileFormData } from "./ProfileEditorContainer";

interface Props {
  name: string;
  isOpen: boolean;
  relatedContents: RelatedContentsEditorContentFragment[];
  closeModal: () => void;
  openRelatedContentsEditor: () => void;
  onSubmit: (data: ProfileFormData) => Promise<void>;
  formMethods: UseFormMethods<ProfileFormData>;
}

export const ProfileContentEditModal: React.FC<Props> = ({
  name,
  isOpen,
  relatedContents,
  closeModal,
  openRelatedContentsEditor,
  onSubmit,
  formMethods: { handleSubmit, getValues, setValue, register },
}) => {
  const log = useLog();
  return (
    <ModalBase isOpen={isOpen} onRequestClose={closeModal} contentLabel={"Profile Edit"} width={890} spWidth={290}>
      <ModalTitle>
        <CloseModalIcon onClick={closeModal} className="view-close" />
        {name}
      </ModalTitle>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AvatarWrapper>
            <AvatarImageUploader
              imageUrl={getValues("iconUrl") || DefaultIconPath}
              imageUrlHandler={(url) => setValue("iconUrl", url)}
            />
          </AvatarWrapper>
          <TextInputComponent label="ニックネーム" placeholder="ビュー" name="userName" register={register} />
          <TextInputComponent label="地域" placeholder="東京都" name="area" register={register} />
          <TextInputComponent label="Twitter URL" name="twitterUrl" register={register} />
          <TextInputComponent label="Note URL" name="noteUrl" register={register} />
          <TextInputComponent label="Facebook URL" name="facebookUrl" register={register} />
          <TextInputComponent label="Instagram URL" name="instagramUrl" register={register} />
          <TextInputComponent label="Homepage URL" name="homepageUrl" register={register} />
          <InputWrapper>
            <Label>自己紹介</Label>
            <div>
              <Textarea ref={register} placeholder="自己紹介" name="intro" />
            </div>
          </InputWrapper>
          <TextInputComponent
            name="playing"
            label="やっていること"
            placeholder="デザイン　プログラミング　マーケター"
            register={register}
          />
          <TextInputComponent name="favorite" label="好きなこと" placeholder="映画　読書" register={register} />
          <TextInputComponent
            name="future"
            label="このコミュニティでやりたいこと"
            placeholder="みんなと交流したいです！"
            register={register}
          />
          <RelatedContentsWrapper>
            <RelatedContentsHeaderWrapper>
              <Label>参加したコンテンツ</Label>
              <EditButton onClick={openRelatedContentsEditor} type="button">
                編集する
              </EditButton>
            </RelatedContentsHeaderWrapper>
            <ContentsListWrapper>
              {relatedContents.map((content) => (
                <ContentItem
                  key={content.id}
                  href={content.facebookUrl}
                  onClick={() =>
                    log({
                      event_target: "Content",
                      event_action: "click",
                      event_params: JSON.stringify({ id: content.id }),
                    })
                  }
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Cover className="cover">
                    <CoverImage src={content.imageUrl} />
                  </Cover>
                  <ShowDetails className="show-details">Facebookでみる</ShowDetails>
                  <TitleWrapper>
                    <Title className="title">{content.name}</Title>
                  </TitleWrapper>
                </ContentItem>
              ))}
            </ContentsListWrapper>
          </RelatedContentsWrapper>

          <Buttons>
            <RightButtons>
              <CancelButton onClick={closeModal}>キャンセル</CancelButton>
              <SubmitButton>
                <input
                  type="submit"
                  value="内容を反映する"
                  style={{ backgroundColor: "unset", border: "unset", color: "unset", cursor: "pointer" }}
                />
              </SubmitButton>
            </RightButtons>
          </Buttons>
        </form>
      </FormWrapper>
    </ModalBase>
  );
};

interface TextInputProps {
  name: string;
  label: string;
  placeholder?: string;
  must?: boolean;
  register: Ref<HTMLInputElement>;
}

const TextInputComponent: React.FC<TextInputProps> = ({ name, label, placeholder, must, register }) => {
  return (
    <InputWrapper>
      <Label must={must} width={200}>
        {label}
      </Label>
      <div>
        <TextInput placeholder={placeholder} name={name} error={null} ref={register} width={550} />
      </div>
    </InputWrapper>
  );
};

const AvatarWrapper = styled.div`
  width: 150px;
  height: 150px;
  margin: 50px auto;
`;

const Textarea = styled.textarea`
  resize: none;
  border: solid 1px #d2d2d2;
  font-size: 14px;
  width: 532px;
  height: 100px;
  padding: 18px;
  @media screen and (max-width: 1024px) {
    width: 234px;
    height: 60px;
    padding: 14px 12px;
    font-size: 13px;
  }
`;

const Buttons = styled.div`
  display: flex;
  padding-top: 40px;
  justify-content: space-between;
  border-top: solid 1px #d2d2d2;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const RelatedContentsWrapper = styled.div``;

const RelatedContentsHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EditButton = styled(Button)`
  text-align: center;
  color: ${Red};
  border: 2px solid ${Red};
  padding: 8px 12px;
  line-height: 1;
  width: 160px;
  font-size: 14px;
  border-radius: 20px;
  transition: all ease-in-out 0.175s;
  &:visited {
    color: ${Red};
  }
  &:hover {
    background-color: ${Red};
    color: white;
    text-decoration: none;
  }
  @media screen and (max-width: 1024px) {
    width: 100px;
    height: 32px;
  }
`;

const ContentsListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ContentItem = styled.a`
  transition: all 0.2s;
  cursor: pointer;
  display: block;
  position: relative;
  width: 210px;
  margin: 16px;
  &:hover {
    background-color: unset;
    text-decoration: none;
    color: #000;

    .cover:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5); /*半透明のフィルターをかける*/
    }
    .show-details {
      display: block;
    }
    .title {
      color: ${Red};
    }
  }
`;

const ShowDetails = styled.div`
  position: absolute;
  top: 40px;
  left: 30px;
  border-radius: 21px;
  width: 146px;
  height: 40px;
  border: solid 2px #fff;
  color: #fff;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1.12px;
  font-weight: bold;
  display: none;
`;

const Cover = styled.div`
  position: relative;
`;

const CoverImage = styled.img`
  object-fit: cover;
  width: 210px;
  height: 120px;
`;

const TitleWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 12px;
  line-height: 16px;
  width: 230px;
`;
