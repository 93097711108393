import axios from "axios";
import React from "react";
import { useDropzone } from "react-dropzone";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";

const ACCESS_URL = process.env.REACT_APP_S3_UPLOAD_URL
  ? process.env.REACT_APP_S3_UPLOAD_URL
  : "https://maede-web-production.s3-ap-northeast-1.amazonaws.com";

export function AvatarImageUploader({ imageUrl, imageUrlHandler, error }) {
  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      axios
        .get("/api/avatar_send_url")
        .then((response) => {
          const requestUrl = response.data.url;
          const uploadedImageUrl = ACCESS_URL + "/" + response.data.key;
          const options = {
            headers: {
              "Content-Type": file.type,
            },
          };
          axios
            .put(requestUrl, file, options)
            .then(() => {
              imageUrlHandler(uploadedImageUrl);
            })
            .catch((e) => {
              console.error(e);
            });
        })
        .catch((e) => {
          console.error(e);
        });
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const preview = (uploadedImageUrl, isDragActive) => {
    if (uploadedImageUrl) {
      return (
        <PreviewWrapper>
          <PreviewImage src={uploadedImageUrl} className="image" />
          <ChangeWrapper>
            <Icon className="view-camera" />
            <Text>変更する</Text>
          </ChangeWrapper>
        </PreviewWrapper>
      );
    } else if (isDragActive) {
      return (
        <PlaceHolder error={error}>
          <IconWrapper>
            <Icon className="view-camera" />
          </IconWrapper>
        </PlaceHolder>
      );
    } else {
      return (
        <PlaceHolder error={error}>
          <IconWrapper>
            <Icon className="view-camera" />
          </IconWrapper>
        </PlaceHolder>
      );
    }
  };

  return (
    <div
      {...getRootProps({
        style: {
          display: "flex",
          margin: " 0 auto 0",
          cursor: "pointer",
        },
      })}
    >
      <input {...getInputProps()} />
      <Dragged>{preview(imageUrl, isDragActive)}</Dragged>
    </div>
  );
}

const Dragged = styled.div`
  margin: 3px auto 0;
  width: 150px;
  min-height: 150px;
  @media screen and (max-width: 1024px) {
    width: 150px;
    min-height: 150px;
  }
`;

const PreviewWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /*半透明のフィルターをかける*/
    border-radius: 50%;
  }
  @media screen and (max-width: 1024px) {
    width: 150px;
    height: 150px;
  }
`;

const PreviewImage = styled.img`
  object-fit: cover;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  @media screen and (max-width: 1024px) {
    width: 150px;
    height: 150px;
  }
`;

const ChangeWrapper = styled.div`
  position: absolute;
  top: 45px;
  left: 37px;
  border-radius: 40px;
  width: 76px;
  height: 76px;
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  @media screen and (max-width: 1024px) {
    top: 45px;
    width: 70px;
    height: 70px;
  }
`;

const Text = styled.div``;

const PlaceHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e9e9;
  height: 180px;
  line-height: 180px;
  ${(p) =>
    p.error !== null &&
    css`
      border: solid 2px ${Red};
    `};
`;

const IconWrapper = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 23px;
  background-color: #9e9e9e;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.i`
  font-size: 42px;
  color: #fff;
`;
