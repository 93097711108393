import { Actions } from "../actions/ContentBookmarks";
import { ContentBookmark } from "../types/contents";

const contentBookmarksReducer = (state: ContentBookmark[] = [], action: any): ContentBookmark[] => {
  switch (action.type) {
    case Actions.FETCH_CONTENT_BOOKMARKS:
      return action.data;
    case Actions.ADD_CONTENT_BOOKMARKS:
      return [...state, action.data];
    case Actions.REMOVE_CONTENT_BOOKMARKS:
      return state.filter((element) => element.id !== action.id);
    default:
      return state;
  }
};

export default contentBookmarksReducer;
