import axios from "axios";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Red } from "../styles/colors";

const CloseEyeImagePath = require("images/close_eye.png");
const OpenEyeImagePath = require("images/open_eye.png");
const LogoImagePath = require("images/VIEW_logo.png");

export const UpdatePasswordContainer = ({ userId }) => {
  const history = useHistory();
  const [formState, setFormState] = useState({
    userId,
    password: "",
    passwordConfirmation: "",
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);

  const inputHundler = useCallback(
    (e) => {
      const target = e.currentTarget;
      setFormState({
        ...formState,
        [target.name]: target.value,
      });
    },
    [formState]
  );

  const validate = ({ password, passwordConfirmation }) => {
    if (password !== passwordConfirmation) {
      return "パスワードと確認欄は同じ値を入力してください";
    } else if (password.length < 7) {
      return "パスワードは８文字以上入力してください";
    }
    return null;
  };

  const submitHundler = useCallback(() => {
    const error = validate(formState);
    if (error) {
      setErrorMessage(error);
      return;
    }
    const csrfToken = document.head.querySelector<HTMLMetaElement>(
      "meta[name=csrf-token]"
    ).content;
    const data = {
      password: formState,
    };
    const header = {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    };
    axios
      .post("/api/update_password.json", data, header)
      .then(() => {
        window.location.href = "/view";
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  }, [formState, validate, setErrorMessage, history]);

  return (
    <Base>
      <FormWrapper>
        <Logo src={LogoImagePath} />
        <Message>
          新しいパスワードを入力してください
          <br />
          ※英数字・記号のみ。８文字以上。
        </Message>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Label>パスワード</Label>
        <InputWrapper>
          <Input
            type={passwordVisible ? "text" : "password"}
            name="password"
            onChange={inputHundler}
          />
          <VisibleButton onClick={() => setPasswordVisible(!passwordVisible)}>
            {passwordVisible ? (
              <Icon src={CloseEyeImagePath} />
            ) : (
              <Icon src={OpenEyeImagePath} />
            )}
          </VisibleButton>
        </InputWrapper>
        <Label>パスワード確認</Label>
        <InputWrapper>
          <Input
            type={passwordConfirmVisible ? "text" : "password"}
            name="passwordConfirmation"
            onChange={inputHundler}
          />
          <VisibleButton
            onClick={() => setPasswordConfirmVisible(!passwordConfirmVisible)}
          >
            {passwordConfirmVisible ? (
              <Icon src={CloseEyeImagePath} />
            ) : (
              <Icon src={OpenEyeImagePath} />
            )}
          </VisibleButton>
        </InputWrapper>
        <SubmitButton
          type="submit"
          onClick={submitHundler}
          value="パスワードをリセット"
        />
      </FormWrapper>
    </Base>
  );
};

const Base = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #f5f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.div`
  width: 600px;
  margin: auto;
  padding: 59px 64px 50px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 1024px) {
    width: 400px;
  }
`;

const Logo = styled.img`
  width: 400px;
  margin-bottom: 40px;
  @media screen and (max-width: 1024px) {
    width: 240px;
  }
`;

const Message = styled.div`
  background-color: #eaf9ff;
  padding: 18px 25px;
  color: #3b89b5;
  font-size: 14px;
  line-height: 22px;
  margin: 4px 0;
  border-radius: 3px;
`;

const ErrorMessage = styled(Message)`
  background-color: #fae4e4;
  color: #cd4f4f;
`;

const Label = styled.div`
  font-size: 14px;
  color: #72777c;
  margin-top: 20px;
  margin-bottom: 8px;
`;

const InputWrapper = styled.div`
  display: flex;
`;

const Input = styled.input`
  width: 332px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  height: 46px;
  line-height: 46px;
  padding-left: 10px;
  color: #72777c;
  font-size: 14px;
  flex-grow: 1;
`;

const VisibleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  width: 46px;
  height: 46px;
  margin-left: 8px;
`;

const Icon = styled.img``;

const SubmitButton = styled.input`
  background-color: ${Red};
  border: unset;
  color: #fff;
  border-radius: 30px;
  width: 250px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  text-align: center;
  margin: 26px auto 0;
  display: block;
`;
