import React from "react";
import styled from "styled-components";
import { GuideHeader } from "./GuideHeader";
import { GuideContents } from "./GuideContents";

export const GuideContainer = () => {
  return (
    <>
      <GuideHeader />
      <ContentsWrapper>
        <GuideContents />
      </ContentsWrapper>
    </>
  );
};

const ContentsWrapper = styled.div`
  margin: 100px 0 163px;
  display: flex;
  justify-content: center;
`;