import React, { useCallback, useMemo, useState } from "react";
import { gql } from "@apollo/client";
import { RelatedContentsEditorContentsGroupFragment } from "../@types/types";
import styled, { css } from "styled-components";
import { CloseModalIcon } from "./CloseModalIcon";
import { ModalBase, ModalTitle } from "./ModalBase";
import { Red, Red100 } from "../styles/colors";
import { IconComponent } from "./IconComponent";
import { SubmitButton } from "./FormParts";

export const RELATED_CONTENTS_EDITOR_CONTENT_FRAGMENT = gql`
  fragment RelatedContentsEditorContent on Content {
    id
    name
    imageUrl
    facebookUrl
  }
`;

export const RELATED_CONTENTS_EDITOR_CONTENTS_GROUP_FRAGMENT = gql`
  fragment RelatedContentsEditorContentsGroup on ContentsGroup {
    id
    name
    iconName
    contents {
      edges {
        node {
          ...RelatedContentsEditorContent
        }
      }
    }
  }
  ${RELATED_CONTENTS_EDITOR_CONTENT_FRAGMENT}
`;

interface Props {
  isOpen: boolean;
  contentsGroups: RelatedContentsEditorContentsGroupFragment[];
  relatedContentIds: number[];
  closeModal: () => void;
  setRelatedContents: (ids: number[]) => void;
}

export const RelatedContentsEditModal: React.FC<Props> = ({
  isOpen,
  contentsGroups,
  relatedContentIds,
  closeModal,
  setRelatedContents,
}) => {
  const [selectedContentsGroup, setSelectedContentsGroup] = useState<RelatedContentsEditorContentsGroupFragment>(
    contentsGroups[0]
  );
  const relatedContentIdsByIds: { [id: number]: number } = useMemo(
    () => (relatedContentIds ? relatedContentIds.reduce((a, v) => ({ ...a, [v]: v }), {}) : {}),
    [relatedContentIds]
  );

  const toggleSelectedContent = useCallback(
    (selectedId: number) => {
      if (relatedContentIdsByIds[selectedId]) {
        const newRelatedContentIds = relatedContentIds.filter((id) => id !== selectedId);
        setRelatedContents(newRelatedContentIds);
      } else {
        setRelatedContents([...relatedContentIds, selectedId]);
      }
    },
    [relatedContentIds, relatedContentIdsByIds, setRelatedContents]
  );

  return (
    <ModalBase isOpen={isOpen} onRequestClose={closeModal} contentLabel="Day Content Modal" width={583} spWidth={290}>
      <Base>
        <CloseModalIcon onClick={closeModal} className="view-close" />
        <ModalTitle>参加したコンテンツを編集</ModalTitle>
        <ModalContentsWrapper>
          <ContentsGroupsWrapper>
            {contentsGroups &&
              contentsGroups.map((contentsGroup) => (
                <ContentsGroupItem
                  isSelected={selectedContentsGroup.id === contentsGroup.id}
                  key={contentsGroup.id}
                  onClick={() => setSelectedContentsGroup(contentsGroup)}
                >
                  <IconComponent iconName={contentsGroup.iconName} />
                  <Title>{contentsGroup.name}</Title>
                </ContentsGroupItem>
              ))}
          </ContentsGroupsWrapper>
          <ContentsWrapper>
            {selectedContentsGroup.contents.edges.map(({ node: content }) => (
              <ContentItem
                key={content.id}
                isSelected={!!relatedContentIdsByIds[content.id]}
                onClick={() => toggleSelectedContent(content.id)}
              >
                <Arrow>&gt;</Arrow>
                <div>{content.name}</div>
              </ContentItem>
            ))}
          </ContentsWrapper>
        </ModalContentsWrapper>
        <Buttons>
          <RightButtons>
            <SubmitButton onClick={closeModal}>プロフィール編集に戻る</SubmitButton>
          </RightButtons>
        </Buttons>
      </Base>
    </ModalBase>
  );
};

const Base = styled.div`
  min-height: 300px;
  max-height: 800px;
  position: relative;
`;

const ModalContentsWrapper = styled.div`
  display: flex;
  position: relative;
  max-height: 650px;
`;

const ContentsGroupsWrapper = styled.div`
  width: 243px;
  overflow: scroll;
  box-shadow: 1px 0px 8px 0px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 1024px) {
    width: 48px;
    flex-shrink: 0;
  }
`;

const ContentsGroupItem = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  display: flex;
  font-size: 13px;
  padding: 11.5px 14px;
  align-items: center;
  transition: all 0.2s;
  @media screen and (max-width: 1024px) {
    padding: 6px;
  }
  ${({ isSelected }) =>
    isSelected
      ? css`
          color: ${Red};
          background-color: ${Red100};
        `
      : css`
          color: #000;
          &:hover {
            color: #e50213;
          }
        `};
`;

const Title = styled.div`
  margin-left: 6px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const ContentsWrapper = styled.div`
  width: 500px;
  max-height: 750px;
  overflow: scroll;
`;

const ContentItem = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;

  justify-content: space-between;
  padding: 18px 20px 18px 16px;
  font-size: 16px;
  border-bottom: solid 1px #e3e3e3;
  transition: all 0.2s;
  ${({ isSelected }) =>
    isSelected
      ? css`
          color: ${Red};
          background-color: ${Red100};
        `
      : css`
          color: #000;
          &:hover {
            color: #e50213;
          }
        `};
  @media screen and (max-width: 1024px) {
    padding: 18px 8px 18px 12px;
    font-size: 12px;
  }
`;

const Arrow = styled.div`
  font-size: 24px;
  transform: scale(0.6, 1.4);
`;

const Buttons = styled.div`
  display: flex;
  padding: 16px 16px 16px 0;
  justify-content: space-between;
  border-top: solid 1px #d2d2d2;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;
