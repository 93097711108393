import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { GetMemberQuery, GetMemberQueryVariables } from "../@types/types";
import { Red } from "../styles/colors";
import { ProfileContent, PROFILE_CONTENT_MEMBER_FRAGMENT } from "./ProfileContent";

const GET_MEMBER_QUERY = gql`
  query GetMember($id: Int!) {
    getMember(id: $id) {
      ...ProfileContentMember
    }
  }
  ${PROFILE_CONTENT_MEMBER_FRAGMENT}
`;

export const MemberShowContainer: React.FC<{ viewerId: number }> = ({ viewerId }) => {
  const { id } = useParams<{ id: string }>();
  const { data } = useQuery<GetMemberQuery, GetMemberQueryVariables>(GET_MEMBER_QUERY, {
    variables: { id: parseInt(id, 10) },
  });
  const member = data?.getMember;
  if (!member) {
    return null;
  }
  return (
    <Base>
      <ProfileWrapper>
        <ReturnLayout>
          <ReturnWrapper to={"/view/members"}>
            <Arrow>&lt;</Arrow>
            <div>一覧に戻る</div>
          </ReturnWrapper>
        </ReturnLayout>
        <ProfileContent member={member} editable={viewerId === member.id} />
      </ProfileWrapper>
    </Base>
  );
};

const Base = styled.div`
  padding: 16px;
  background-color: #f5f7f7;
`;

const ProfileWrapper = styled.div`
  margin: 104px auto;
  max-width: 980px;
`;

const Arrow = styled.div`
  font-size: 24px;
  transform: scale(0.6, 1.4);
  margin-right: 8px;
`;

const ReturnLayout = styled.div`
  margin: 80px auto 8px;
  max-width: 980px;
`;

const ReturnWrapper = styled(Link)`
  margin-left: 80px;
  &:hover {
    text-decoration: none;
    color: ${Red};
  }
  display: flex;
  align-items: center;
  @media screen and (max-width: 1024px) {
    margin-left: 15px;
  }
`;
