import React from "react";
import { IconContext } from "react-icons";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalStyle from "../styles/global";
import { OnboardingPage } from "./OnboardingPage";

export default function OnboardingApp() {
  return (
    <Router>
      <GlobalStyle />
      <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
        <OnboardingPage />
      </IconContext.Provider>
    </Router>
  );
}
