import React from "react";
import { matchPath } from "react-router-dom";
import styled from "styled-components";
import { Red } from "../styles/colors";
import { CloseModalIcon } from "./CloseModalIcon";
import { ModalBase } from "./ModalBase";

interface Props {
  handleDelete: (id: number) => void;
  backToVideoIndex: () => void;
}

export const DeleteVideoModal: React.FC<Props> = ({ handleDelete, backToVideoIndex }) => {
  const matchedPath = matchPath<{ id: string }>(location.pathname, {
    path: "/view/archives/video/:id/delete",
    exact: true,
  });

  return (
    <ModalBase isOpen={true} onRequestClose={backToVideoIndex} width={420}>
      <Base>
        <CloseModalIcon onClick={backToVideoIndex} className="view-close" />
        <Title>この動画を一覧から削除しますか？</Title>
        <SubText>一覧から削除すると再度追加が必要になります</SubText>

        <Buttons>
          <CancelButton onClick={backToVideoIndex}>キャンセル</CancelButton>
          <DeleteButton onClick={() => handleDelete(matchedPath ? parseInt(matchedPath.params.id, 10) : null)}>
            削除する
          </DeleteButton>
        </Buttons>
      </Base>
    </ModalBase>
  );
};

const Base = styled.div`
  padding: 69px 38px 51px;
  position: relative;
  @media screen and (max-width: 1024px) {
    padding: 50px 34px 51px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
`;

const SubText = styled.div`
  font-size: 14px;
  text-align: center;
  margin: 24px 0 46px;
  line-height: 24px;
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    margin: 24px auto 46px;
    width: 165px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const Button = styled.div`
  cursor: pointer;
  height: 36px;
  border-radius: 22px;
  border: solid 2px #6c6c6c;
  box-shadow: 0.5px 0.9px 2px 0px rgba(30, 30, 30, 0.2);
  line-height: 37px;
  text-align: center;
`;

const DeleteButton = styled(Button)`
  margin-left: 21px;
  width: 136px;
  border: solid 2px ${Red};
  color: ${Red};
  transition: all 0.2s;
  &:hover {
    color: #fff;
    background-color: ${Red};
  }
  @media screen and (max-width: 1024px) {
    margin-left: 0;
    margin-bottom: 20px;
  }
`;

const CancelButton = styled(Button)`
  width: 140px;
  color: #6c6c6c;
  transition: all 0.2s;
  &:hover {
    color: #fff;
    background-color: #6c6c6c;
  }
`;
