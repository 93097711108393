import React from "react";
import ReactModal from "react-modal";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";

ReactModal.setAppElement("body");

const Base = ({ width, spWidth, children, className, isOpen, onRequestClose, contentLabel }) => {
  const contentClassName = `${className}__Content`;
  const overlayClassName = `${className}__Overlay`;
  return (
    <ReactModal
      width={width}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className={contentClassName}
      overlayClassName={overlayClassName}
    >
      {children}
    </ReactModal>
  );
};

Base.defaultProps = {
  className: "modal",
};

export const ModalBase = styled(Base)`
  &__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Set z-index to higher than other components. */
    z-index: 100;
    overflow: scroll;
  }
  &__Content {
    width: ${(p) => p.width}px;
    margin: 5% auto;
    background: #fff;
    overflow: visible;
    -webkit-overflow-scrolling: touch;
    border-radius: 10px;
    @media screen and (max-width: 1024px) {
      width: ${(p) => p.spWidth}px;
      margin: 10% auto 5%;
    }
  }
`;

export const ModalTitle = styled.div`
  z-index: 2;
  color: #fff;
  font-size: 16px;
  background-color: ${Red};
  padding: 17px;
  letter-spacing: 1.28px;
  text-align: center;
  border-radius: 10px 10px 0 0;
  position: relative;
  @media screen and (max-width: 1024px) {
    font-size: 13px;
    padding: 13px;
    letter-spacing: 0.4px;
  }
`;

export const TextInput = styled.input<{ error: string | null; width?: number }>`
  font-size: 14px;
  width: ${({ width }) => width || 650}px;
  height: 48px;
  border: solid 1px #d2d2d2;
  line-height: 48px;
  padding: 0;
  padding-left: 18px;
  ${(p) =>
    p.error !== null &&
    css`
      border: solid 1px ${Red};
      background-color: rgba(229, 2, 19, 0.1);
    `};
  @media screen and (max-width: 1024px) {
    width: 248px;
    height: 38px;
    line-height: 38px;
    padding-left: 10px;
    font-size: 13px;
  }
`;
