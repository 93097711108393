import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { deleteContentBookmark, sendContentBookmark } from "../actions/ContentBookmarks";
import { useLog } from "../hooks/useLog";
import { Red } from "../styles/colors";
import { Content, ContentBookmark } from "../types/contents";
import { ContentsGroup } from "../types/contentsGroup";
import { FiSearch } from "react-icons/fi";

interface Props {
  contentsGroups: ContentsGroup[];
  contents: Content[];
  contentBookmarks: ContentBookmark[];
}

export const ContentsList: React.FC<Props> = ({ contentsGroups, contents, contentBookmarks }) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const log = useLog();
  const [searchWord, setSearchWord] = useState("");
  const groupedContents = useMemo(() => {
    if (contentsGroups.length > 0 && contents.length > 0) {
      if (id === "favorite") {
        const bookmarkIds = contentBookmarks.map((e) => e.content_id);
        return contents.filter((e) => bookmarkIds.includes(e.id));
      }
      if (id === "search") {
        if (searchWord === "") return contents;
        return contents.filter((e) => e.name.includes(searchWord) || e.comment.includes(searchWord));
      }

      const selectedContentsGroup = id
        ? contentsGroups.find((element) => element.id === parseInt(id, 10))
        : contentsGroups[0];
      return contents.filter((content) => {
        return content.contents_group_id === selectedContentsGroup.id;
      });
    }
  }, [id, contentsGroups, contents, searchWord, contentBookmarks]);

  const toggleBookmark = useCallback(
    (bookmarked, content) => {
      const csrf_token = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
      if (bookmarked) {
        dispatch(deleteContentBookmark(bookmarked.id, csrf_token));
      } else {
        dispatch(sendContentBookmark(content.id, csrf_token));
      }
    },
    [dispatch]
  );

  return (
    <Base>
      <SearchBar id={id} value={searchWord} onChange={(event) => setSearchWord(event.target.value)} />
      {groupedContents &&
        groupedContents.map((content) => {
          const bookmarked = contentBookmarks.find((contentBookmark) => contentBookmark.content_id === content.id);
          return (
            <ContentItem
              key={content.id}
              href={content.facebook_url}
              onClick={() =>
                log({
                  event_target: "Content",
                  event_action: "click",
                  event_params: JSON.stringify({ id: content.id }),
                })
              }
              target="_blank"
              rel="noreferrer noopener"
            >
              <Cover className="cover">
                <CoverImage src={content.image_url} />
              </Cover>
              <ShowDetails className="show-details">Facebookでみる</ShowDetails>
              <TitleWrapper>
                <Title className="title">{content.name}</Title>
                <Star
                  className="view-favorite"
                  bookmarked={bookmarked}
                  onClick={(e) => {
                    e.preventDefault();
                    toggleBookmark(bookmarked, content);
                  }}
                />
              </TitleWrapper>
              <Description>
                {content.comment.length < 80 ? content.comment : `${content.comment.slice(0, 80)}...`}
              </Description>
            </ContentItem>
          );
        })}
    </Base>
  );
};

const Base = styled.div`
  margin: 24px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
`;

const ContentItem = styled.a`
  transition: all 0.2s;
  cursor: pointer;
  display: block;
  position: relative;
  width: 265px;
  margin: 16px;
  &:hover {
    background-color: unset;
    text-decoration: none;
    color: #000;

    .cover:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5); /*半透明のフィルターをかける*/
    }
    .show-details {
      display: block;
    }
    .title {
      color: ${Red};
    }
  }
`;

const ShowDetails = styled.div`
  position: absolute;
  top: 60px;
  left: 60px;
  border-radius: 21px;
  width: 146px;
  height: 40px;
  border: solid 2px #fff;
  color: #fff;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1.12px;
  font-weight: bold;
  display: none;
`;

const Cover = styled.div`
  position: relative;
`;

const Star = styled.i<{ bookmarked: ContentBookmark }>`
  cursor: pointer;
  font-size: 20px;
  transition: all 0.2s;
  ${(p) =>
    p.bookmarked
      ? css`
          color: ${Red};
        `
      : css`
          color: #d2d2d2;
          &:hover {
            color: rgba(229, 2, 19, 0.1);
          }
        `};
`;

const CoverImage = styled.img`
  object-fit: cover;
  width: 265px;
  height: 152px;
`;

const TitleWrapper = styled.div`
  margin-top: 17px;
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  width: 300px;
`;

const Description = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.77;
`;

const SearchInput = styled.input`
  font-size: 13px;
  margin-left: 13px;
  font-weight: 500;
  border: none;
  box-sizing: border-box;
  width: calc(100% -54px);
  height: 100%;
  outline: none;
`;

const SearchInputContainer = styled.div`
  width: 90%;
  height: 44px;
  border-radius: 13px;
  border: 1px solid rgb(118, 118, 118);
  background-color: #fff;
  margin: 16px;
  display: flex;
`;

type PropsSearchBar = {
  id: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
const SearchBar = (props: PropsSearchBar) => {
  if (props.id === "search")
    return (
      <SearchInputContainer>
        <FiSearch size={24} style={{ margin: "8px 4px" }} />
        <SearchInput value={props.value} onChange={props.onChange} placeholder="検索" />
      </SearchInputContainer>
    );
  return null;
};
