import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Red } from "../styles/colors";
import { IconComponent } from "./IconComponent";

const ArrowImagePath = require("images/arrow.svg");

export const SmartPhoneMenu = ({ communityId, open, setOpen, contentsGroups }) => {
  const [openItem, setOpenItem] = useState(null);

  const toggleOpenItem = (e, item) => {
    e.stopPropagation();
    if (item === openItem) {
      setOpenItem(null);
    } else {
      setOpenItem(item);
    }
  };

  return (
    <>
      {open && (
        <Background
          onClick={() => {
            setOpen(false);
          }}
        >
          <Menu>
            <MenuItem>
              <ItemLink to={"/view"}>
                <MenuTitle>
                  <Title>TOP</Title>
                  <Arrow>&gt;</Arrow>
                </MenuTitle>
              </ItemLink>
            </MenuItem>
            <MenuItem>
              <MenuTitle onClick={(e) => toggleOpenItem(e, "contents")}>
                <Title>コンテンツ</Title>
                {openItem === "contents" ? (
                  <DownArrow>
                    <ArrowImage src={ArrowImagePath} />
                  </DownArrow>
                ) : (
                  <Arrow>&gt;</Arrow>
                )}
              </MenuTitle>
              {openItem === "contents" && (
                <Contents>
                  {contentsGroups &&
                    contentsGroups.map((element) => (
                      <ContentsGroup key={element.id} to={"/view/" + element.id + "?from=header"}>
                        <IconWrapper>
                          <IconComponent iconName={element.icon_name} color={Red} />
                        </IconWrapper>
                        <ContentsGroupText>{element.name}</ContentsGroupText>
                      </ContentsGroup>
                    ))}
                </Contents>
              )}
            </MenuItem>
            {communityId === 1 && (
              <MenuItem>
                <ItemLink to={"/view/members"}>
                  <MenuTitle>
                    <Title>メンバー</Title>
                    <Arrow>&gt;</Arrow>
                  </MenuTitle>
                </ItemLink>
              </MenuItem>
            )}
            <MenuItem>
              <ItemLink to={"/view/archives"}>
                <MenuTitle>
                  <Title>アーカイブ</Title>
                  <Arrow>&gt;</Arrow>
                </MenuTitle>
              </ItemLink>
            </MenuItem>
            {/* <MenuItem>
              <MenuTitle>
                <Title>アトリエ（ZOOM）はこちら</Title>
                <Arrow>&gt;</Arrow>
              </MenuTitle>
            </MenuItem>
            <MenuItem>
              <MenuTitle>
                <Title>アトリエを初めてご利用の方はコチラ</Title>
                <Arrow>&gt;</Arrow>
              </MenuTitle>
            </MenuItem> */}
          </Menu>
        </Background>
      )}
    </>
  );
};

const Background = styled.div`
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Menu = styled.div`
  width: 289px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 13px;
`;

const MenuItem = styled.div`
  border-bottom: 1px solid #efefef;
`;

const ItemLink = styled(Link)`
  color: #000;
  &:hover {
    background-color: #fff;
    text-decoration: none;
  }
  &:visited {
    color: #000;
  }
`;

const MenuTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
`;

const Title = styled.div`
  line-height: 25px;
`;

const Arrow = styled.div`
  font-size: 24px;
  transform: scale(0.6, 1.4);
  color: ${Red};
`;

const ArrowImage = styled.img`
  width: 100%;
`;

const DownArrow = styled.div`
  width: 13px;
`;

const Contents = styled.div`
  margin-left: 15px;
  border: 1px solid #efefef;
  border-top: unset;
  border-bottom: unset;
`;

const ContentsGroup = styled(Link)`
  border-top: 1px solid #efefef;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #fff;
    text-decoration: none;
    color: ${Red};
    .icon-wrapper {
      background-color: ${Red};
      .icon {
        color: #fff;
      }
    }
  }
`;
const IconWrapper = styled.div`
  margin: 7px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.i`
  font-size: 33px;
  color: ${Red};
  &.view-project {
    margin-top: 3px;
  }
  &.view-communication {
    margin-top: 3px;
    margin-left: 1px;
  }
  &.view-flag {
    margin-top: 1px;
    margin-left: 3px;
  }
`;
const ContentsGroupText = styled.div``;
