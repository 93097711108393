import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { deleteContentBookmark, sendContentBookmark } from "../actions/ContentBookmarks";
import { Content, ContentBookmark } from "../types/contents";
import { ContentsGroup } from "../types/contentsGroup";
import { SmartPhoneContentsGroup } from "./SmartPhoneContentsGroup";
import { IconComponent } from "./IconComponent";
import { Red } from "../styles/colors";
import { Link } from "react-router-dom";

interface Props {
  contentsGroups: ContentsGroup[];
  contents: Content[];
  contentBookmarks: ContentBookmark[];
}

export const SmartPhoneContents: React.FC<Props> = ({ contentsGroups, contents, contentBookmarks }) => {
  const dispatch = useDispatch();
  const [openGroupId, setOpenGroupId] = useState(null);
  const toggleBookmark = useCallback((bookmarked, content) => {
    const csrf_token = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
    if (bookmarked) {
      dispatch(deleteContentBookmark(bookmarked.id, csrf_token));
    } else {
      dispatch(sendContentBookmark(content.id, csrf_token));
    }
  }, []);

  const toggleContentsGroup = useCallback(
    (id) => {
      if (id === openGroupId) {
        setOpenGroupId(null);
      } else {
        setOpenGroupId(id);
      }
    },
    [openGroupId]
  );

  return (
    <Base>
      {contentsGroups.map((group) => (
        <SmartPhoneContentsGroup
          key={group.id}
          group={group}
          toggleContentsGroup={toggleContentsGroup}
          openGroupId={openGroupId}
          contents={contents}
          contentBookmarks={contentBookmarks}
          toggleBookmark={toggleBookmark}
        />
      ))}
      <SmartPhoneContentsGroup
        key={-1}
        group={{
          id: -1,
          name: "お気に入り",
          icon_name: "view-favorite-b",
          date_limitable: false,
          with_event: false,
          has_contents: contentBookmarks.length > 0,
          slag: "",
          description: "",
        }}
        toggleContentsGroup={toggleContentsGroup}
        openGroupId={openGroupId}
        contents={contents}
        contentBookmarks={contentBookmarks}
        toggleBookmark={toggleBookmark}
      />
      <ContentsGroupWrapper>
        <ContentsGroupItem to="/view/archives">
          <GroupTitleWrapper>
            <IconComponent iconName={"view-archive"} color={Red} size={31} />
            <Title>アーカイブ</Title>
          </GroupTitleWrapper>
        </ContentsGroupItem>
      </ContentsGroupWrapper>
    </Base>
  );
};

const Base = styled.div`
  margin: 0 15px;
  width: 100%;
  &:first-child {
    border-top: solid 1px #efefef;
  }
`;

const ContentsGroupWrapper = styled.div`
  border-bottom: solid 1px #efefef;
`;

const ContentsGroupItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  padding: 10px 8px 10px;
  align-items: center;
`;

const GroupTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled.div`
  margin-left: 8px;
`;
