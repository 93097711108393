import { gql } from "@apollo/client";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import { ContainerCommunityFragment, ViewIndexViewerFragment } from "../@types/types";
import { fetchCarousel } from "../actions/Carousel";
import { useLog } from "../hooks/useLog";
import { Red } from "../styles/colors";
import { ContentsGroup } from "../types/contentsGroup";
import { ArchivesContainer } from "./ArchivesContainer";
import { MemberListContainer } from "./MemberListContainer";
import { ContentsGroupsContainer } from "./ContentsGroupsContainer";
import { GuideContainer } from "./GuideContainer";
import { MyPageContainer } from "./MyPageContainer";
import { ScheduleContainer } from "./ScheduleContainer";
import { MemberShowContainer } from "./MemberShowContainer";

const selector = (state) => state.carousel;

const CAROUSEL_CONTENTS_LENGTH = 3;

const settings = {
  dots: true,
  infinite: true,
  speed: 700,
  slidesToShow: CAROUSEL_CONTENTS_LENGTH,
  autoplay: true,
  autoplaySpeed: 5000,
};

const CONTAINER_COMMUNITY_FRAGMENT = gql`
  fragment ContainerCommunity on Community {
    id
    iconUrl
    videoConferenceUrl
    defaultContentImageUrl
    howToWalkUrl
    defaultEventUrl
    name
  }
`;

export const VIEW_INDEX_VIEWER_FRAGMENT = gql`
  fragment ViewIndexViewer on Member {
    id
    editable
    createdAt
    user {
      id
      detail {
        userName
        iconUrl
      }
    }
    community {
      ...ContainerCommunity
    }
  }
  ${CONTAINER_COMMUNITY_FRAGMENT}
`;

interface Props {
  userId: null | number;
  contentsGroups: ContentsGroup[];
  community: ContainerCommunityFragment;
  editable: boolean;
  viewer: ViewIndexViewerFragment;
}

export const ViewIndex: React.FC<Props> = ({ userId, contentsGroups, community, editable, viewer }) => {
  const log = useLog();
  const { path } = useRouteMatch();
  const carouselContents = useSelector(selector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCarousel());
  }, [dispatch]);

  const displayCarouselContents = useMemo(() => {
    let tmpCarouselContents = [];
    for (let i = 0; i < CAROUSEL_CONTENTS_LENGTH; i++) {
      tmpCarouselContents = tmpCarouselContents.concat(carouselContents);
      if (tmpCarouselContents.length >= CAROUSEL_CONTENTS_LENGTH) {
        break;
      }
    }
    return tmpCarouselContents;
  }, [carouselContents]);

  return (
    <>
      <Switch>
        <Route path="/view/guide">
          <GuideContainer />
        </Route>
        <Route path="/view/my_page">
          <MyPageContainer viewer={viewer} />
        </Route>
        <Route path="/view/archives">
          <ArchivesContainer community={community} />
        </Route>
        <Route path="/view/members">
          <Route path="/view/members/:id">
            <MemberShowContainer viewerId={viewer.id} />
          </Route>
          <Route path="/view/members" exact>
            <MemberListContainer />
          </Route>
        </Route>
        <Route path="/view">
          <MainVisualsWrapper>
            <SliderWrapper>
              <ViewSlider {...settings}>
                {displayCarouselContents &&
                  displayCarouselContents.length > 0 &&
                  displayCarouselContents.map((content) => (
                    <MainVisualItem
                      key={content.id}
                      href={content.facebook_url}
                      target="_blank"
                      rel="noreferrer noopener"
                      onClick={() =>
                        log({
                          event_target: "Carousel",
                          event_action: "click",
                          event_params: JSON.stringify({ id: content.id }),
                        })
                      }
                    >
                      <MainVisualImage src={content.image_url} />
                    </MainVisualItem>
                  ))}
              </ViewSlider>
            </SliderWrapper>
          </MainVisualsWrapper>
          {community.howToWalkUrl && (
            <HowToWalkLink href={community.howToWalkUrl} target="_blank" rel="noopener noreferrer">
              <HowToWalkButton>
                <BeginnerIcon className="view-beginner" />
                <Texts>
                  <SubText>\ まずはここを読んでね /</SubText>
                  <MainText>{community.name}の歩き方</MainText>
                </Texts>
                <Arrow>&gt;</Arrow>
              </HowToWalkButton>
            </HowToWalkLink>
          )}
          <ScheduleContainer userId={userId} community={community} editable={editable} />
          <ContentsGroupsContainer contentsGroups={contentsGroups} community={community} editable={editable} />
        </Route>
      </Switch>
    </>
  );
};

const MainVisualsWrapper = styled.div`
  padding-bottom: 3px;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const ViewSlider = styled(Slider)`
  .slick-dots {
    position: static;
  }
`;

const SliderWrapper = styled.div`
  max-width: 2697px;
  width: 300vw;
`;

const MainVisualItem = styled.a`
  display: block;
  max-width: 899px;
  width: 100vw;
  max-height: 475px;
  height: 53vw;
  border: 1px solid #fff;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
`;

const MainVisualImage = styled.img`
  object-fit: cover;
  max-width: 899px;
  width: 100vw;
  max-height: 475px;
  height: 53vw;
`;

const HowToWalkLink = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const HowToWalkButton = styled.div`
  position: relative;
  display: flex;
  width: 582px;
  height: 88px;
  margin: 60px auto;
  border-radius: 45px;
  border: solid 2px #e50213;
  box-shadow: 0.5px 0.9px 3.8px 0.2px rgba(30, 30, 30, 0.2);
  background-color: #fff;
  color: #e50213;
  /*background-color: #e4e4e4;
  color: #fff;*/
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  transition: all 0.2s;
  &:hover {
    background-color: ${Red};
    color: #fff;
  }
  @media screen and (max-width: 1024px) {
    width: 258px;
    height: 50px;
    margin: 26px auto;
  }
`;

const BeginnerIcon = styled.i`
  font-size: 42px;
  @media screen and (max-width: 1024px) {
    font-size: 23px;
  }
`;

const Texts = styled.div`
  margin-left: 4px;
`;

const SubText = styled.div`
  font-size: 13px;
  margin-bottom: 8px;
  letter-spacing: 0.91px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 4px;
    font-size: 11px;
    letter-spacing: 0.55px;
  }
`;

const MainText = styled.div`
  font-size: 20px;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
    letter-spacing: 0.98px;
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 27px;
  right: 32px;
  font-size: 32px;
  transform: scale(0.6, 1.4);
  @media screen and (max-width: 1024px) {
    top: 12px;
    right: 17px;
    font-size: 24px;
    transform: scale(0.6, 1.4);
  }
`;
