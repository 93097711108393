import { Actions } from "../actions/ContentsGroups";
import { ContentsGroup } from "../types/contentsGroup";

const EMPTY_CONTENTS_GROUPS: ContentsGroup[] = [];

const contentsGroupsReducer = (state: ContentsGroup[] = EMPTY_CONTENTS_GROUPS, action: any): ContentsGroup[] => {
  switch (action.type) {
    case Actions.FETCH_CONTENTS_GROUPS:
      return action.data;
    default:
      return state;
  }
};

export default contentsGroupsReducer;
