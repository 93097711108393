import axios from "axios";
import { Content } from "../types/contents";
import { addEvent } from "./Events";

export const Actions = {
  FETCH_CONTENTS: "CONTENTS::FETCH",
  SEND_CONTENT: "CONTENTS::SEND",
  ADD_CONTENT: "CONTENTS::ADD",
  REMOVE_CONTENT: "CONTENTS::REMOVE",
};

export const fetchContents = () => {
  return async (dispatch) => {
    const response = await axios.get("/api/contents");

    dispatch({
      type: Actions.FETCH_CONTENTS,
      data: response.data,
    });
  };
};

// TODO 型をつける
export const sendContent = (
  {
    id,
    name,
    comment,
    image_url,
    with_event,
    with_notification,
    contents_group_id,
    facebook_url,
    start_date,
    start_time,
    end_date,
    end_time,
    facebook_id,
  },
  csrfToken,
  closeModal,
  setSubmitDisabled
) => {
  return async (dispatch) => {
    const data = {
      content: {
        id,
        name,
        comment,
        image_url,
        with_event,
        with_notification,
        contents_group_id,
        facebook_url,
        started_at: start_date + " " + start_time + " " + "+09:00",
        ended_at: end_date + " " + end_time + " " + "+09:00",
        facebook_id,
      },
    };
    const header = {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    };
    axios
      .post("/api/contents", data, header)
      .then((response) => {
        setSubmitDisabled(false);
        closeModal();
        dispatch(addContent(response.data.content));
        if (response.data.event) {
          dispatch(addEvent(response.data.event));
        }
      })
      .catch((response) => {
        setSubmitDisabled(false);
        console.error(response);
      });
  };
};

const addContent = (data) => ({ type: Actions.ADD_CONTENT, data });

export const deleteContent = (id, csrfToken, closeModal) => {
  return async (dispatch) => {
    const header = {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    };
    axios
      .delete("/api/contents/" + id, header)
      .then(() => {
        closeModal();
        dispatch(removeContent(id));
      })
      .catch((response) => {
        console.error(response);
      });
  };
};

const removeContent = (id) => ({ type: Actions.REMOVE_CONTENT, id });
