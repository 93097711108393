import React from "react";
import styled from "styled-components";

export default function ImageTrimming() {
  return <Back></Back>;
}

const Back = styled.div`
  width: 1000px;
  height: 1000px;
  background-color: rgba(0, 0, 0, 0.6);
`;
