import axios from "axios";
import React, { useCallback, useEffect, useReducer } from "react";
import styled from "styled-components";

const INITIAL_SETTINGS = [];

const descriptions = [
  {
    type: "events_created",
    text: "予定が作成されたときにメール通知を受け取る",
  },
  {
    type: "contents_created",
    text: "コンテンツが作成されたときにメール通知を受け取る",
  },
  {
    type: "today_events",
    text: "今日のイベントの通知を受け取る",
  },
];

const reducer = (state, action) => {
  switch (action.type) {
    case "init":
      return action.data;
    case "update":
      return state.map((s) =>
        s.id === action.data.id ? { ...s, enabled: action.data.enabled } : s
      );
  }
};

export const NotificationSetting = () => {
  const [settings, dispatch] = useReducer(reducer, INITIAL_SETTINGS);
  useEffect(() => {
    axios
      .get("/api/notification_settings")
      .then((res) => {
        dispatch({ type: "init", data: res.data });
      })
      .catch((e) => console.log(e));
  }, []);

  const changeHandler = useCallback((event, setting) => {
    dispatch({
      type: "update",
      data: { id: setting.id, enabled: event.currentTarget.checked },
    });
    axios.patch(
      `/api/notification_settings/${setting.id}`,
      {
        enabled: event.currentTarget.checked,
      },
      {
        headers: {
          "X-CSRF-Token": document.head.querySelector("meta[name=csrf-token]")
            .content,
        },
      }
    );
  }, []);
  return (
    <Wrapper>
      <ContentWrapper>
        <Title>通知設定</Title>
        {settings.map((setting) => {
          const description = descriptions.find(
            (d) => d.type === setting.notification_type
          );
          return (
            <CheckBoxWrapper key={setting.id}>
              <CheckBox
                type="checkbox"
                onChange={(e) => changeHandler(e, setting)}
                name="with_notification"
                value="true"
                checked={setting.enabled}
              />
              <label style={{ marginLeft: 8, lineHeight: "47px" }}>
                {description ? description.text : ""}
              </label>
            </CheckBoxWrapper>
          );
        })}
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 104px auto;
  max-width: 980px;
`;

const ContentWrapper = styled.div`
  border-radius: 10px;
  background-color: white;
  max-width: 900px;
  margin: 0 80px;
  padding: 60px 40px;
`;

const Title = styled.h1`
  font-size: 30px;
  margin-bottom: 24px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
`;

const CheckBox = styled.input`
  margin: 18px 8px;
`;
