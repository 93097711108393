import React, { useState, useCallback } from "react";
import { ContainerCommunityFragment } from "../@types/types";
import { Event } from "../types/event";
import { EventForm } from "./EventForm";
import { ModalBase } from "./ModalBase";

interface Props {
  open: boolean;
  modalEvent: Event | null;
  community: ContainerCommunityFragment;
  onCloseModal: () => void;
  openEventDelete?: (event: Event) => void;
}

export const useEventFormModal = (
  community: ContainerCommunityFragment
): Props & { onOpenModal: (e: Event) => void } => {
  const [open, setOpen] = useState<boolean>(false);
  const [event, setEvent] = useState<Event | null>(null);

  const onCloseModal = useCallback(() => {
    setOpen(false);
    setEvent(null);
  }, []);

  const onOpenModal = useCallback((event: Event) => {
    setOpen(true);
    setEvent(event);
  }, []);

  return {
    open,
    modalEvent: event,
    community,
    onCloseModal,
    onOpenModal,
  };
};

export const EventFormModal: React.FC<Props> = ({ open, modalEvent, community, onCloseModal, openEventDelete }) => {
  return (
    <ModalBase isOpen={open} onRequestClose={onCloseModal} contentLabel="Day Event Modal" width={890} spWidth={290}>
      <EventForm
        modalEvent={modalEvent}
        community={community}
        closeModal={onCloseModal}
        openEventDelete={openEventDelete}
      />
    </ModalBase>
  );
};
