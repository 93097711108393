import React from "react";
import { IconContext } from "react-icons";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalStyle from "../styles/global";
import { AdminPage } from "./AdminPage";
import { Provider } from "react-redux";
import configureStore from "../stores/configureStore";

const store = configureStore();

export default function AdminApp() {
  return (
    <Provider store={store}>
      <Router>
        <GlobalStyle />
        <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
          <AdminPage />
        </IconContext.Provider>
      </Router>
    </Provider>
  );
}
