import React from "react";
import styled from "styled-components";
import { ContentHeadline } from "./ContentsHeadline";

interface Props {
  headline: string;
}

export const ProfileContentItem: React.FC<Props> = (props) => {
  return (
    <ProfileContentWrapper>
      <ContentHeadline headline={props.headline} />
      <ProfileContentDetail>{props.children}</ProfileContentDetail>
    </ProfileContentWrapper>
  );
};

const ProfileContentWrapper = styled.div`
  margin-top: 32px;
  @media screen and (max-width: 1023px) {
    margin-top: 16px;
  }
`;

const ProfileContentDetail = styled.div`
  margin-top: 16px;
  @media screen and (max-width: 1023px) {
    margin-top: 8px;
  }
`;
