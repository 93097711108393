import axios from "axios";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const ACCESS_URL = process.env.REACT_APP_S3_UPLOAD_URL
  ? process.env.REACT_APP_S3_UPLOAD_URL
  : "https://maede-web-production.s3-ap-northeast-1.amazonaws.com";

interface Props {
  imageUrlHandler: (arg: string) => void;
}

export const useUploadImageWithDrop = ({ imageUrlHandler }: Props) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      try {
        const res = await axios.get("/api/s3url");
        const requestToS3Url = res.data.url;
        const uploadedImageUrl = ACCESS_URL + "/" + res.data.key;
        const options = {
          headers: {
            "Content-Type": file.type,
          },
        };
        await axios.put(requestToS3Url, file, options);
        imageUrlHandler(uploadedImageUrl);
      } catch (e) {
        console.error(e);
      }
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return { getRootProps, getInputProps };
};
