import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ViewerQuery } from "../@types/types";
import { fetchContentsGroups } from "../actions/ContentsGroups";
import { useLog } from "../hooks/useLog";
import { ContentsGroup } from "../types/contentsGroup";
import { PROFILE_CONTENT_MEMBER_FRAGMENT } from "./ProfileContent";
import { SmartPhoneMenu } from "./SmartPhoneMenu";
import ViewFooter from "./ViewFooter";
import ViewHeader from "./ViewHeader";
import { ViewIndex, VIEW_INDEX_VIEWER_FRAGMENT } from "./ViewIndex";

const selector = (state: { contentsGroups: ContentsGroup[] }) => ({
  contentsGroups: state.contentsGroups,
});

const VIEWER_QUERY = gql`
  query viewer {
    viewer {
      ...ViewIndexViewer
      ...ProfileContentMember
    }
  }
  ${VIEW_INDEX_VIEWER_FRAGMENT}
  ${PROFILE_CONTENT_MEMBER_FRAGMENT}
`;

const ViewContainer: React.FC = () => {
  const dispatch = useDispatch();
  const log = useLog();
  const { contentsGroups } = useSelector(selector);
  const [openSPMenu, setOpenSPMenu] = useState(false);

  useEffect(() => {
    dispatch(fetchContentsGroups());
    log({ event_target: "Page", event_action: "open" });
  }, []);

  const { data } = useQuery<ViewerQuery>(VIEWER_QUERY);

  const viewer = data?.viewer;
  const community = data?.viewer?.community;
  const user = data?.viewer?.user;

  return (
    <>
      <Switch>
        <Route path="/view">
          {community && user && (
            <>
              <ViewHeader
                communityId={community.id}
                iconUrl={community.iconUrl}
                videoConferenceUrl={community.videoConferenceUrl}
                contentsGroups={contentsGroups}
                subscribeNotification={user.detail.subscribeNotification}
                userName={user.detail.userName}
                openSPMenu={openSPMenu}
                setOpenSPMenu={setOpenSPMenu}
              />
              <div style={{ position: "relative" }}>
                <SmartPhoneMenu
                  communityId={community.id}
                  open={openSPMenu}
                  setOpen={setOpenSPMenu}
                  contentsGroups={contentsGroups}
                />
                <ViewIndex
                  editable={viewer.editable}
                  userId={user.id}
                  contentsGroups={contentsGroups}
                  community={community}
                  viewer={viewer}
                />
                <ViewFooter communityName={community.name} />
              </div>
            </>
          )}
        </Route>
      </Switch>
    </>
  );
};

export default ViewContainer;
