import React from "react";
import DefaultIconPath from "images/default_icon.png";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Red } from "../styles/colors";
import { ProfileContentItem } from "./ProfileContentItem";
import { ProfileContentMemberFragment } from "../@types/types";
import moment from "moment";
import { useLog } from "../hooks/useLog";
import { SnsIcons, SNSList } from "./SnsIcons";
import { gql } from "@apollo/client";

export const PROFILE_CONTENT_MEMBER_FRAGMENT = gql`
  fragment ProfileContentMember on Member {
    id
    createdAt
    user {
      id
      detail {
        userName
        iconUrl
      }
    }
    profile {
      id
      area
      facebookUrl
      favorite
      future
      homepageUrl
      instagramUrl
      intro
      noteUrl
      playing
      twitterUrl
      contents {
        edges {
          node {
            id
            name
            imageUrl
            facebookUrl
          }
        }
      }
    }
  }
`;

interface Props {
  member: ProfileContentMemberFragment;
  editable?: boolean;
}

export const ProfileContent: React.FC<Props> = ({ member, editable }) => {
  const log = useLog();
  const profile = member.profile;

  return (
    <Wrapper>
      {editable && <EditButton to="/view/my_page/edit">プロフィールを編集</EditButton>}
      <ProfileWrapper>
        <ImageWrapper>
          <PreviewImage src={member?.user?.detail?.iconUrl || DefaultIconPath} />
        </ImageWrapper>
        <Name>
          <NickName>{member?.user?.detail?.userName || "-"}</NickName>
        </Name>
        <SummaryData>
          <SummaryDataItem>
            <SummaryDataList>
              <SummaryDataTitle>入会月</SummaryDataTitle>
              <SummaryDataDesc>{member?.createdAt ? moment(member?.createdAt).format("YYYY/MM") : "-"}</SummaryDataDesc>
            </SummaryDataList>
            <SummaryDataList>
              <SummaryDataTitle>地域</SummaryDataTitle>
              <SummaryDataDesc>{profile?.area || "-"}</SummaryDataDesc>
            </SummaryDataList>
          </SummaryDataItem>
          <SummaryDataItem>
            <SNSList>{profile && <SnsIcons snsUrls={profile} />}</SNSList>
          </SummaryDataItem>
        </SummaryData>
        <Intro>
          <IntroText>{profile?.intro || "-"}</IntroText>
        </Intro>
        <ProfileContentItem headline="やっていること">
          <ProfileContentData>
            <Text>{profile?.playing || "-"}</Text>
          </ProfileContentData>
        </ProfileContentItem>
        <ProfileContentItem headline="好きなこと">
          <ProfileContentData>
            <Text>{profile?.favorite || "-"}</Text>
          </ProfileContentData>
        </ProfileContentItem>
        <ProfileContentItem headline="このコミュニティでやりたいこと">
          <ProfileContentData>
            <Text>{profile?.future || "-"}</Text>
          </ProfileContentData>
        </ProfileContentItem>
        <ProfileContentItem headline="これまで参加したコンテンツ">
          <ProfileContentData>
            {profile?.contents?.edges?.map((content_node) => {
              const content = content_node?.node;
              return (
                content && (
                  <ContentItem
                    key={content.id}
                    href={content.facebookUrl}
                    onClick={() =>
                      log({
                        event_target: "Content",
                        event_action: "click",
                        event_params: JSON.stringify({ id: content.id }),
                      })
                    }
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Cover className="cover">
                      <CoverImage src={content.imageUrl} />
                    </Cover>
                    <ShowDetails className="show-details">Facebookでみる</ShowDetails>
                    <TitleWrapper>
                      <Title className="title">{content.name}</Title>
                    </TitleWrapper>
                  </ContentItem>
                )
              );
            })}
          </ProfileContentData>
        </ProfileContentItem>
      </ProfileWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  border-radius: 10px;
  background-color: white;
  max-width: 900px;
  margin: 0 80px;
  @media screen and (max-width: 1024px) {
    margin: 0 15px;
  }
  padding: 60px 40px;
`;

const EditButton = styled(Link)`
  display: block;
  text-align: center;
  margin-left: auto;
  @media screen and (max-width: 1024px) {
    margin-right: auto;
  }
  color: ${Red};
  border: 2px solid ${Red};
  height: auto;
  padding: 8px 12px;
  line-height: 1;
  width: 160px;
  font-size: 14px;
  border-radius: 20px;
  transition: all ease-in-out 0.175s;
  &:visited {
    color: ${Red};
  }
  &:hover {
    background-color: ${Red};
    color: white;
    text-decoration: none;
  }
`;

const ProfileWrapper = styled.div``;

const ImageWrapper = styled.div`
  margin: 30px auto 0;
  width: 150px;
  min-height: 150px;
`;

const PreviewImage = styled.img`
  object-fit: cover;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  @media screen and (max-width: 1024px) {
    width: 150px;
    height: 150px;
  }
`;

const Name = styled.p`
  margin-top: 26px;
  font-size: 24px;
  text-align: center;
`;

const NickName = styled.span`
  margin-top: 8px;
  font-size: 14px;
  text-align: center;
  display: block;
`;

const SummaryData = styled.div`
  margin-top: 20px;

  @media screen and (min-width: 1024px) {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }
`;

const SummaryDataList = styled.dl`
  color: #7c7c7c;
  font-size: 14px;
  display: flex;
  margin-top: 8px;
  &:first-child {
    margin-top: 0;
  }
`;

const SummaryDataTitle = styled.dt`
  margin-right: 1em;
`;
const SummaryDataDesc = styled.dd``;

const SummaryDataItem = styled.div`
  @media screen and (max-width: 1023px) {
    margin-top: 8px;
  }
`;

const Intro = styled.div`
  margin-top: 40px;
`;

const IntroText = styled.p`
  font-size: 14px;
  line-height: 2.28;
`;

const ProfileContentData = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 2;
`;
const ContentItem = styled.a`
  transition: all 0.2s;
  cursor: pointer;
  display: block;
  position: relative;
  width: 210px;
  margin: 8px;
  &:hover {
    background-color: unset;
    text-decoration: none;
    color: #000;

    .cover:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5); /*半透明のフィルターをかける*/
    }
    .show-details {
      display: block;
    }
    .title {
      color: ${Red};
    }
  }
`;

const ShowDetails = styled.div`
  position: absolute;
  top: 40px;
  left: 30px;
  border-radius: 21px;
  width: 146px;
  height: 40px;
  border: solid 2px #fff;
  color: #fff;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1.12px;
  font-weight: bold;
  display: none;
`;

const Cover = styled.div`
  position: relative;
`;

const CoverImage = styled.img`
  object-fit: cover;
  width: 210px;
  height: 120px;
`;

const TitleWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 12px;
  line-height: 16px;
  width: 230px;
`;
