import { combineReducers } from "redux";
import contentsGroupsReducer from "./contentsGroups";
import eventsReducer from "./events";
import appDataReducer from "./appData";
import contentsReducer from "./contents";
import videosReducer from "./videos";
import videoCategoriesReducer from "./videoCategories";
import contentBookmarksReducer from "./contentBookmarks";
import notificationsReducer from "./notifications";
import carouselReducer from "./carousel";

const rootStore = combineReducers({
  events: eventsReducer,
  contentsGroups: contentsGroupsReducer,
  appData: appDataReducer,
  contents: contentsReducer,
  contentBookmarks: contentBookmarksReducer,
  notifications: notificationsReducer,
  carousel: carouselReducer,
  videos: videosReducer,
  videoCategories: videoCategoriesReducer,
});

export type RootStore = ReturnType<typeof rootStore>;
export default rootStore;
