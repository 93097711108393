import React from "react";
import styled from "styled-components";
import { Red } from "../styles/colors";

export default function SmartPhoneNotificationWidget({ notifications }) {
  return (
    <Base>
      <Widget className="view-notification"></Widget>
      <WidgetContent className="widget">
        <Header>
          <Title>お知らせ</Title>
          <CreateButton>＋お知らせを作成</CreateButton>
        </Header>
        {notifications
          ? notifications.map((value) => (
              <NotificationLink href={value.url} key={value.id}>
                <Content href={value.url} key={value.id}>
                  <Icon></Icon>
                  <Right>
                    <Description>{value.description}</Description>
                    <Date>1日前</Date>
                  </Right>
                </Content>
              </NotificationLink>
            ))
          : "通知が来るとここに表示されます"}
      </WidgetContent>
    </Base>
  );
}

const Base = styled.div`
  position: relative;
  transition: all 0.2s;
  margin-right: 4px;
  margin-left: -4px;
  &:hover .widget {
    display: block;
  }
`;

const Widget = styled.i`
  font-size: 42px;
`;

const WidgetContent = styled.div`
  z-index: 5;
  display: none;
  position: absolute;
  margin-top: 34px;
  width: 470px;
  height: 644px;
  background-color: #fff;
  box-shadow: 1px 1.7px 6px 0 rgba(0, 0, 0, 0.1);
  overflow: scroll;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 13px 20px;
  border-bottom: 1px solid #d2d2d2;
`;

const Title = styled.div`
  margin: 5px 0;
  font-size: 14px;
  letter-spacing: 1.05px;
`;

const CreateButton = styled.div`
  cursor: pointer;
  width: 117px;
  height: 22px;
  border: 1px solid ${Red};
  border-radius: 13px;
  font-size: 11px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.83px;
  color: ${Red};
  transition: all 0.2s;
  &:hover {
    color: #fff;
    background-color: ${Red};
  }
`;

const NotificationLink = styled.a`
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    color: #000;
    text-decoration: none;
  }
`;

const Content = styled.div`
  display: flex;
  padding: 29px 33px 19px 21px;
  min-height: 61px;
  transition: all 0.2s;
  &:hover {
    background-color: rgba(229, 2, 19, 0.1);
  }
`;

const Icon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 25px;
  background-color: #ddd;
`;

const Right = styled.div`
  margin-left: 16px;
`;

const Description = styled.div`
  font-size: 14px;
  width: 352px;
  margin-bottom: 14px;
`;

const Date = styled.div`
  font-size: 12px;
  color: #666;
`;
