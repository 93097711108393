import React, { useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { ArchivedVideosProps } from "../hooks/useArchivedVideos";
import { Red } from "../styles/colors";
import { Content } from "../types/contents";
import { ContentsGroup } from "../types/contentsGroup";
import { SmartPhoneContentsGroup } from "./SmartPhoneContentsGroup";
import { SmartPhoneArchivedVideo } from "./SmartPhoneArcivedVideo";

type Props = {
  contentsGroups: ContentsGroup[];
  archivedContents: Content[];
} & ArchivedVideosProps;

export const SmartPhoneArchivesContents: React.FC<Props> = (props) => {
  const [openGroupId, setOpenGroupId] = useState(null);
  const toggleContentsGroup = useCallback(
    (id) => {
      if (id === openGroupId) {
        setOpenGroupId(null);
      } else {
        setOpenGroupId(id);
      }
    },
    [openGroupId]
  );

  const archivedContentsGroups = useMemo(() => {
    const limitedContentsGroups = props.contentsGroups.filter((cg) => cg.date_limitable === true);
    return limitedContentsGroups.map((cg) => ({
      ...cg,
      name: `過去の${cg.name}`,
    }));
  }, [props.contentsGroups]);
  return (
    <Base>
      <SmartPhoneArchivedVideo
        addVideo={props.addVideo}
        categories={props.categories}
        videos={props.videos}
        backToVideoIndex={props.backToVideoIndex}
        categoriesFilter={props.categoriesFilter}
        handleDelete={props.handleDelete}
        handleCategoriesFilter={props.handleCategoriesFilter}
        openGroupId={openGroupId}
        toggleContentsGroup={toggleContentsGroup}
      />
      {archivedContentsGroups.map((group) => (
        <SmartPhoneContentsGroup
          key={group.id}
          group={group}
          toggleContentsGroup={toggleContentsGroup}
          openGroupId={openGroupId}
          contents={props.archivedContents}
          contentBookmarks={[]}
          toggleBookmark={null}
        />
      ))}
    </Base>
  );
};

const Base = styled.div`
  margin: 0 15px;
  width: 100%;
  &:first-child {
    border-top: solid 1px #efefef;
  }
`;
