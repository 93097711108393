import React from "react";
import styled from "styled-components";
import { Red, White } from "../styles/colors";
import { SMARTPHONE_WIDTH } from "../styles/constants";

export const PageHeader = ({ icon, title, subtitle, lead, description }) => {
  return (
    <Wrapper>
      <TitleWrapper>
        <IconWrapper>
          <Icon src={icon} />
        </IconWrapper>
        <TextWrapper>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </TextWrapper>
      </TitleWrapper>
      {lead && <Lead>{lead}</Lead>}
      <Description>{description.map(({ text, em }) => (em ? <Em>{text}</Em> : text))}</Description>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  background-color: ${Red};
  color: ${White};
  width: 100%;
  padding: 104px 236px 146px;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 240px 100vw;
    border-color: transparent transparent ${White} transparent;
  }
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    padding: 20px 24px 30px;
    &::before {
      border-width: 0 0 40px 100vw;
    }
  }
`;

const IconWrapper = styled.div`
  width: 58px;
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    width: 32px;
  }
`;

const Icon = styled.img`
  width: 100%;
`;

const TextWrapper = styled.div`
  margin-left: 22px;
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    margin-left: 8px;
  }
`;

const Title = styled.h1`
  font-size: 30px;
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    font-size: 18px;
  }
`;

const SubTitle = styled.p`
  margin-top: 12px;
  font-size: 14px;
  letter-spacing: 2.8px;
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    margin-top: 6px;
    font-size: 11px;
  }
`;

const Lead = styled.p`
  position: relative;
  text-align: center;
  font-size: 30px;
  margin-bottom: 30px;
  &::before {
    content: "";
    position: absolute;
    left: calc(50% - 8px);
    bottom: -10px;
    display: inline-block;
    width: 274px;
    height: 2px;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
    background-color: ${Red};
  }
`;

const Description = styled.p`
  margin-top: 26px;
  text-align: center;
  font-size: 15px;
  line-height: 2.33;
  letter-spacing: 0.8px;
  white-space: pre-wrap;
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    margin: 26px 16px 0;
    font-size: 12px;
  }
`;

const Em = styled.em`
  color: ${Red};
  font-weight: bold;
`;
