import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { ContainerCommunityFragment } from "../@types/types";
import { fetchContentBookmarks } from "../actions/ContentBookmarks";
import { deleteContent, fetchContents } from "../actions/Contents";
import { RootStore } from "../reducers";
import { Content } from "../types/contents";
import { ContentsGroup } from "../types/contentsGroup";
import { ContentModals } from "./ContentModals";
import { ContentsGroupList } from "./ContentsGroupList";
import { ContentsList } from "./ContentsList";
import { SmartPhoneContents } from "./SmartPhoneContents";

const contentsSelector = (state: RootStore) => {
  return {
    contents: state.contents,
    contentBookmarks: state.contentBookmarks,
  };
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface Props {
  contentsGroups: ContentsGroup[];
  community: ContainerCommunityFragment;
  editable: boolean;
}

export const ContentsGroupsContainer: React.FC<Props> = ({ contentsGroups, community, editable }) => {
  const query = useQuery();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { contents, contentBookmarks } = useSelector(contentsSelector);
  const [isOpenContentModal, setIsOpenContentModal] = useState<boolean>(false);
  const [contentModalState, setContentModalState] = useState<
    "editableContentList" | "contentEdit" | "contentDelete" | null
  >(null);
  const [modalContent, setModalContent] = useState<Content | null>(null);
  const [showContents, setShowContents] = useState<Content[] | null>(null);
  const contentsTopRef = useRef(null);

  useEffect(() => {
    dispatch(fetchContents());
    dispatch(fetchContentBookmarks());
  }, [dispatch]);

  useEffect(() => {
    const from = query.get("from");
    if (from === "header" && contentsTopRef) {
      window.scrollTo(0, contentsTopRef.current.offsetTop);
    }
  }, [query]);

  const openContentEdit = useCallback((content) => {
    setIsOpenContentModal(true);
    setModalContent(content);
    setContentModalState("contentEdit");
  }, []);

  const openMyContents = useCallback(() => {
    setIsOpenContentModal(true);
    setShowContents(contents);
    setContentModalState("editableContentList");
  }, [setIsOpenContentModal, setShowContents, setContentModalState, contents]);

  const openContentDelete = useCallback((content: Content) => {
    setModalContent(content);
    setIsOpenContentModal(true);
    setContentModalState("contentDelete");
  }, []);

  const closeContentModal = useCallback(() => {
    setIsOpenContentModal(false);
    setModalContent(null);
    setShowContents(null);
    setContentModalState(null);
  }, []);

  const handleDeleteContent = useCallback(
    (id: number) => {
      const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
      dispatch(deleteContent(id, csrfToken, closeContentModal));
    },
    [dispatch, closeContentModal]
  );

  return (
    <>
      <Title ref={contentsTopRef}>コンテンツ</Title>
      <SubTitle>CONTENT</SubTitle>
      <ContentsWidget>
        <Switch>
          <Route path={[`${path}/:id`, `${path}`]}>
            <MediaQuery query="(min-width: 1040px)">
              <ContentsGroupList contentsGroups={contentsGroups} />
              <ContentsListWrapper>
                <ContentsList contentsGroups={contentsGroups} contents={contents} contentBookmarks={contentBookmarks} />
              </ContentsListWrapper>
            </MediaQuery>
            <MediaQuery query="(max-width: 1039px)">
              <SmartPhoneContents
                contentsGroups={contentsGroups}
                contents={contents}
                contentBookmarks={contentBookmarks}
              />
            </MediaQuery>
          </Route>
        </Switch>
      </ContentsWidget>
      <Buttons>
        {editable && (
          <>
            <Button
              onClick={() => {
                openContentEdit(null);
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "21px",
                  marginRight: "10px",
                }}
              >
                ＋
              </span>
              コンテンツの追加
            </Button>
            {contents.length > 0 && (
              <EditButton onClick={openMyContents}>
                <Icon className="view-edit" />
                <div style={{ marginLeft: "-10px" }}>コンテンツの編集</div>
              </EditButton>
            )}
          </>
        )}
      </Buttons>
      <ContentModals
        community={community}
        contentsGroups={contentsGroups}
        isOpenContentModal={isOpenContentModal}
        closeContentModal={closeContentModal}
        openContentDelete={openContentDelete}
        handleDeleteContent={handleDeleteContent}
        contentModalState={contentModalState}
        showContents={showContents}
        openContentEdit={openContentEdit}
        modalContent={modalContent}
      />
    </>
  );
};

const Title = styled.h2`
  margin: 115px auto 12px;
  text-align: center;
  font-size: 38px;
  @media screen and (max-width: 1024px) {
    margin: 32px auto 7px;
    font-size: 22px;
  }
`;

const SubTitle = styled.h2`
  margin: 0 auto 42px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.5px;
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;

const ContentsWidget = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
`;

const ContentsListWrapper = styled.div`
  display: flex;
  background-color: #f5f7f7;
  width: 954px;
  height: 670px;
  flex-wrap: wrap;
  overflow: scroll;
`;

const Buttons = styled.div`
  display: flex;
  margin: 40px auto 160px;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    margin: 18px auto;
  }
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 246px;
  height: 60px;
  box-shadow: 0.5px 0.9px 3.8px 0.2px rgba(30, 30, 30, 0.2);
  border: solid 2px #e50213;
  border-radius: 60px;
  text-align: center;
  font-size: 17px;
  color: #e50213;
  letter-spacing: 0.6px;
  transition: all 0.2s;
  &:hover {
    background-color: #e50213;
    color: #fff;
  }
  @media screen and (max-width: 1024px) {
    width: 256px;
    height: 50px;
    font-size: 14px;
  }
`;

const EditButton = styled(Button)`
  margin-left: 60px;
  @media screen and (max-width: 1024px) {
    margin-left: 0;
    margin-top: 20px;
  }
`;

const Icon = styled.i`
  margin-left: -23px;
  font-size: 50px;
  line-height: 56px;
`;
