import moment from "moment";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Red } from "../styles/colors";
import { CloseModalIcon } from "./CloseModalIcon";
import { ModalBase, ModalTitle } from "./ModalBase";

export const EditableVideosModal = ({ videos }) => {
  const history = useHistory();
  const backToVideoIndex = useCallback(() => {
    history.push("/view/archives/video");
  }, [history]);

  return (
    <ModalBase isOpen={true} onRequestClose={backToVideoIndex} width={520}>
      <ModalTitle>
        <CloseModalIcon onClick={backToVideoIndex} className="view-close" />
        内容の編集
      </ModalTitle>
      <VideosWrapper>
        {videos &&
          videos.map((v) => (
            <Video
              key={v.id}
              onClick={() => history.push(`/view/archives/video/${v.id}/edit`)}
            >
              <Title>
                {v.title.length > 15 ? v.title.slice(0, 15) + "..." : v.title}
              </Title>
              <Right>
                <Date>{moment(v.date).format("YYYY年MM月DD日")}</Date>
                <Arrow>></Arrow>
              </Right>
            </Video>
          ))}
      </VideosWrapper>
    </ModalBase>
  );
};

const VideosWrapper = styled.div`
  margin: 0 23px;
  @media screen and (max-width: 1024px) {
    margin: 0 10px;
  }
`;

const Video = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px 18px 4px;
  font-size: 16px;
  border-bottom: solid 1px #e3e3e3;
  transition: all 0.2s;
  &:hover {
    color: ${Red};
  }
  @media screen and (max-width: 1024px) {
    padding: 9px 4px 9px 4px;
  }
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 280px;
`;

const Date = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.56px;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Arrow = styled.div`
  font-size: 24px;
  transform: scale(0.6, 1.4);
  margin-left: 8px;
`;
