import { Actions } from "../actions/Notifications";

const notificationsReducer = (state = [], action) => {
  switch (action.type) {
    case Actions.FETCH_NOTIFICATIONS:
      return action.data;
    default:
      return state;
  }
};

export default notificationsReducer;
