import React from "react";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";

interface Props {
  selectHandler: (arg: boolean) => void;
  value: boolean;
}

export const SelectBox: React.FC<Props> = ({ selectHandler, value }) => {
  const clicked = () => {
    selectHandler(!value);
  };
  return <Selector onClick={clicked} value={value}></Selector>;
};

const Selector = styled.div<{ value: boolean }>`
  width: 48px;
  height: 48px;
  ${({ value }) =>
    value
      ? css`
          background-color: ${Red};
        `
      : css`
          background-color: #fff;
        `}
`;
