import React from "react";
import styled, { css } from "styled-components";
import { IconComponent } from "./IconComponent";
import { Red } from "../styles/colors";
import { ContentsGroup } from "../types/contentsGroup";
import { Content, ContentBookmark } from "../types/contents";

const ArrowImagePath = require("images/arrow.svg");
const UpArrowImagePath = require("images/u_arrow.svg");

interface Props {
  group: ContentsGroup;
  toggleContentsGroup: (id: number) => void;
  openGroupId: number;
  contents: Content[];
  contentBookmarks: ContentBookmark[];
  toggleBookmark: (bookmarked: boolean, content: Content) => void;
}

export const SmartPhoneContentsGroup: React.FC<Props> = ({
  group,
  toggleContentsGroup,
  openGroupId,
  contents,
  contentBookmarks,
  toggleBookmark,
}) => {
  return (
    <ContentsGroupWrapper key={group.id}>
      <ContentsGroupItem
        onClick={() => {
          toggleContentsGroup(group.id);
        }}
      >
        <GroupTitleWrapper>
          <IconComponent iconName={group.icon_name} color={Red} size={31} />
          <Title>{group.name}</Title>
        </GroupTitleWrapper>
        <Arrow>
          <ArrowImage src={group.id === openGroupId ? UpArrowImagePath : ArrowImagePath} />
        </Arrow>
      </ContentsGroupItem>
      {openGroupId === group.id && (
        <ContentsWrapper>
          {contents
            .filter((c) => group.id === c.contents_group_id)
            .map((content) => {
              const bookmarked = !!contentBookmarks.find(
                (contentBookmark) => contentBookmark.content_id === content.id
              );
              return (
                <ContentItem key={content.id} href={content.facebook_url} target="_blank" rel="noreferrer noopener">
                  <Cover>
                    <CoverImage src={content.image_url} />
                  </Cover>
                  <TextWrapper>
                    <TitleWrapper>
                      <ContentTitle>
                        {content.name.length < 9 ? content.name : `${content.name.slice(0, 9)}...`}
                      </ContentTitle>
                      <Star
                        className={"view-favorite"}
                        bookmarked={bookmarked}
                        onClick={(e) => {
                          e.preventDefault();
                          toggleBookmark(bookmarked, content);
                        }}
                      />
                    </TitleWrapper>
                    <Description>
                      {content.comment.length < 36 ? content.comment : `${content.comment.slice(0, 36)}...`}
                    </Description>
                  </TextWrapper>
                </ContentItem>
              );
            })}
        </ContentsWrapper>
      )}
    </ContentsGroupWrapper>
  );
};

const ContentsGroupWrapper = styled.div`
  border-bottom: solid 1px #efefef;
`;
const ContentsGroupItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  padding: 10px 8px 10px;
  align-items: center;
`;

const GroupTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled.div`
  margin-left: 8px;
`;

const Arrow = styled.div`
  width: 13px;
`;
const ArrowImage = styled.img`
  width: 100%;
`;

const ContentsWrapper = styled.div``;
const ContentItem = styled.a`
  transition: all 0.2s;
  display: flex;
  margin-bottom: 19px;
  &:hover {
    background-color: unset;
    text-decoration: none;
    color: #000;
  }
`;

const Cover = styled.div`
  position: relative;
`;

const CoverImage = styled.img`
  object-fit: cover;
  width: 120px;
  height: 68px;
`;

const TextWrapper = styled.div`
  margin-left: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const Star = styled.i`
  ${(p) =>
    p.bookmarked
      ? css`
          color: ${Red};
        `
      : css`
          color: #d2d2d2;
          &:hover {
            color: rgba(229, 2, 19, 0.1);
          }
        `};
`;

const Description = styled.div`
  font-size: 12px;
  color: #424242;
  line-height: 1.42;
`;
