import React, { useState, useMemo } from "react";
import styled, { css } from "styled-components";
import { Red, Red100 } from "../styles/colors";
import { CloseModalIcon } from "./CloseModalIcon";
import { ModalTitle } from "./ModalBase";
import { ContentsGroup } from "../types/contentsGroup";
import { IconComponent } from "./IconComponent";
import { Content } from "../types/contents";

interface Props {
  contentsGroups: ContentsGroup[];
  showContents: Content[];
  handleClick: any;
  closeModal: any;
}

export const ModalContentsList: React.FC<Props> = ({ showContents, handleClick, closeModal, contentsGroups }) => {
  const [selectedContentsGroupId, setSelectedContentsGroupId] = useState(contentsGroups[0]?.id);
  const displayContents = useMemo(() => {
    return showContents.filter((c) => c.contents_group_id === selectedContentsGroupId);
  }, [selectedContentsGroupId, showContents]);
  return (
    <Base>
      <CloseModalIcon onClick={closeModal} className="view-close" />
      <ModalTitle>コンテンツの編集</ModalTitle>
      <ModalContentsWrapper>
        <ContentsGroupsWrapper>
          {contentsGroups &&
            contentsGroups.map((contentsGroup) => (
              <ContentsGroupItem
                selected={selectedContentsGroupId === contentsGroup.id ? "selected" : null}
                key={contentsGroup.id}
                onClick={() => setSelectedContentsGroupId(contentsGroup.id)}
              >
                <IconComponent iconName={contentsGroup.icon_name} />
                <Title>{contentsGroup.name}</Title>
              </ContentsGroupItem>
            ))}
        </ContentsGroupsWrapper>
        <ContentsWrapper>
          {displayContents.map((content) => (
            <ContentItem key={content.id} onClick={() => handleClick(content)}>
              <div>{content.name}</div>
              <Arrow>&gt;</Arrow>
            </ContentItem>
          ))}
        </ContentsWrapper>
      </ModalContentsWrapper>
    </Base>
  );
};

const Base = styled.div`
  min-height: 300px;
  max-height: 800px;
  position: relative;
`;

const ModalContentsWrapper = styled.div`
  display: flex;
  position: relative;
  max-height: 750px;
`;

const ContentsGroupsWrapper = styled.div`
  width: 243px;
  overflow: scroll;
  box-shadow: 1px 0px 8px 0px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 1024px) {
    width: 48px;
    flex-shrink: 0;
  }
`;

const ContentsGroupItem = styled.div`
  cursor: pointer;
  display: flex;
  font-size: 13px;
  padding: 11.5px 14px;
  align-items: center;
  transition: all 0.2s;
  @media screen and (max-width: 1024px) {
    padding: 6px;
  }
  ${(p) =>
    p.selected === "selected"
      ? css`
          color: ${Red};
          background-color: ${Red100};
        `
      : css`
          color: #000;
          &:hover {
            color: #e50213;
          }
        `};
`;

const Title = styled.div`
  margin-left: 6px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const ContentsWrapper = styled.div`
  width: 500px;
  max-height: 750px;
  overflow: scroll;
`;

const ContentItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  justify-content: space-between;
  padding: 18px 20px 18px 16px;
  font-size: 16px;
  border-bottom: solid 1px #e3e3e3;
  transition: all 0.2s;
  &:hover {
    color: ${Red};
  }
  @media screen and (max-width: 1024px) {
    padding: 18px 8px 18px 12px;
    font-size: 12px;
  }
`;

const Arrow = styled.div`
  font-size: 24px;
  transform: scale(0.6, 1.4);
`;
