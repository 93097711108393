import axios from "axios";
import { Video } from "../types/video";

export const Actions = {
  FETCH_VIDEOS: "VIDEOS::FETCH",
  ADD_VIDEO: "VIDEOS::ADD",
  REMOVE_VIDEO: "VIDEOS::REMOVE",
};

export const fetchVideos = () => {
  return async (dispatch) => {
    const response = await axios.get("/api/videos");

    dispatch({
      type: Actions.FETCH_VIDEOS,
      data: response.data,
    });
  };
};

interface SendVideo {
  video: Video;
  csrfToken: string;
  setUploading: (arg: boolean) => void;
  backToVideoIndex: () => void;
}

export const sendVideos = ({ video, csrfToken, setUploading, backToVideoIndex }: SendVideo) => {
  return async (dispatch) => {
    axios
      .post(
        "/api/videos",
        {
          video,
        },
        {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        }
      )
      .then((response) => {
        dispatch(addVideo(response.data));
        setUploading(false);
        backToVideoIndex();
      })
      .catch((response) => {
        console.error(response);
        setUploading(false);
      });
  };
};

const addVideo = (data) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.ADD_VIDEO,
      data,
    });
  };
};

interface DeleteVideo {
  id: number;
  csrfToken: string;
  backToVideoIndex: () => void;
}

export const deleteVideo = ({ id, csrfToken, backToVideoIndex }: DeleteVideo) => {
  return async (dispatch) => {
    axios
      .delete(`/api/videos/${id}`, {
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      })
      .then(() => {
        dispatch(removeVideo(id));
        backToVideoIndex();
      })
      .catch((response) => {
        console.error(response);
      });
  };
};

const removeVideo = (id) => ({ type: Actions.REMOVE_VIDEO, id });
