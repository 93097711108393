import moment from "moment";
import React from "react";
import styled from "styled-components";
import { DefaultZoomUrl } from "../constants";
import { CloseModalIcon } from "./CloseModalIcon";
import { useLog } from "../hooks/useLog";
import { Event } from "../types/event";

moment.locale("ja");

const createGoogleCalendarUrl = (modalEvent: Event) => {
  const googleCalendarDetails = `${modalEvent.description}\nURL: ${modalEvent.link_url}`;
  return `http://www.google.com/calendar/event?action=TEMPLATE&dates=${moment(modalEvent.started_at).format(
    "YYYYMMDDTHHmmss"
  )}/${moment(modalEvent.ended_at).format("YYYYMMDDTHHmmss")}&text=${
    modalEvent.title
  }&details=${googleCalendarDetails}`;
};

interface Props {
  modalEvent: Event;
  closeModal: () => void;
}

export const EventShow: React.FC<Props> = ({ modalEvent, closeModal }) => {
  const log = useLog();
  return (
    <Base>
      <CloseModalIcon onClick={closeModal} className="view-close" />
      <Title>{modalEvent.title}</Title>
      <DateWrapper>
        <StartWrapper>
          <Day>{moment(modalEvent.started_at).format(" MM 月 DD 日（ddd）")}</Day>
          <Time>{moment(modalEvent.started_at).format("HH:mm")}</Time>
        </StartWrapper>
        <Arrow>{">"}</Arrow>
        <EndWrapper>
          <Day>{moment(modalEvent.ended_at).format(" MM 月 DD 日（ddd）")}</Day>
          <Time>{moment(modalEvent.ended_at).format("HH:mm")}</Time>
        </EndWrapper>
      </DateWrapper>
      <Description>{modalEvent.description}</Description>
      <Creator>製作者：{modalEvent.user_name}</Creator>
      <Button
        href={modalEvent.link_url}
        target="_blank"
        rel="noreferrer noopener"
        onClick={() =>
          log({ event_target: "EventLink", event_action: "click", event_params: JSON.stringify({ id: modalEvent.id }) })
        }
      >
        {modalEvent.link_url === DefaultZoomUrl ? "MTGに参加（ZOOMが起動します）" : "詳細を見る"}
      </Button>
      <GoogleLink href={createGoogleCalendarUrl(modalEvent)} target="_blank" rel="noreferrer noopener">
        Googleカレンダーに登録する
      </GoogleLink>
    </Base>
  );
};

const Base = styled.div`
  padding: 60px 52px;
  width: 420px;
  position: relative;
  text-align: center;
  @media screen and (max-width: 1024px) {
    padding: 32px 24px;
    width: 288px;
  }
`;

const Title = styled.div`
  margin: 8px;
  text-align: center;
  font-size: 18px;
`;

const DateWrapper = styled.div`
  margin: 30px 0 40px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    margin: 24px 0 30px;
  }
`;

const StartWrapper = styled.div``;

const EndWrapper = styled.div``;

const Day = styled.div`
  text-align: center;
  font-size: 13px;
  margin-bottom: 6px;
  letter-spacing: 0.52px;
  @media screen and (max-width: 1024px) {
    font-size: 8px;
  }
`;

const Time = styled.div`
  text-align: center;
  font-size: 20px;
  letter-spacing: 0.8px;
`;

const Arrow = styled.div`
  margin: 0 40px;
  transform: scale(0.6, 1.4);
  font-size: 22px;
  line-height: 36px;
  @media screen and (max-width: 1024px) {
    margin: 0 8px;
  }
`;

const Description = styled.div`
  margin-bottom: 22px;
  font-size: 13px;
  letter-spacing: 1.04px;
  line-height: 2;
  word-break: break-all;
`;

const Creator = styled.div`
  margin-bottom: 50px;
  font-size: 13px;
  letter-spacing: 0.52px;
`;

const Button = styled.a`
  display: block;
  text-decoration: none;
  padding: 0 20px;
  height: 56px;
  box-shadow: 0.5px 0.9px 3.8px 0.2px rgba(30, 30, 30, 0.2);
  border: solid 2px #e50213;
  border-radius: 60px;
  text-align: center;
  font-size: 17px;
  color: #e50213;
  line-height: 56px;
  transition: all 0.2s;
  &:visited {
    color: #e50213;
  }
  &:hover {
    text-decoration: none;
    background-color: #e50213;
    color: #fff;
  }
  @media screen and (max-width: 1024px) {
    font-size: 13px;
    height: 36px;
    line-height: 36px;
    padding: 0 14px;
  }
`;

const GoogleLink = styled.a`
  color: #888;
  line-height: 40px;
  font-size: 14px;
  &:hover {
    color: #111;
    background-color: unset;
  }
  &:visited {
    color: #888;
    background-color: unset;
  }
`;
