import React from "react";
import styled from "styled-components";

interface Props {
  onChange: (args: string) => void;
  value: string;
  label: string;
}

export const AdminTextInput: React.FC<Props> = ({ onChange, value, label }) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Input value={value} onChange={(e) => onChange(e.currentTarget.value)} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Label = styled.label`
  margin: 16px 0 8px;
`;

const Input = styled.input`
  width: 100%;
  height: 32px;
  line-height: 32px;
`;
