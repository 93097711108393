import { Actions } from "../actions/Videos";
import { Video } from "../types/video";

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const dateA = a.date;
  const dateB = b.date;

  let comparison = 0;
  if (dateA < dateB) {
    comparison = 1;
  } else if (dateA > dateB) {
    comparison = -1;
  }
  return comparison;
}

const videosReducer: (state: Video[], action: any) => Video[] = (state = [], action) => {
  switch (action.type) {
    case Actions.FETCH_VIDEOS:
      return action.data;
    case Actions.ADD_VIDEO: {
      const withoutUpdateVideo = state.filter((element) => element.id !== parseInt(action.data.id));
      return [...withoutUpdateVideo, action.data].sort(compare);
    }
    case Actions.REMOVE_VIDEO: {
      const newState = state.filter((element) => element.id !== parseInt(action.id));
      return newState;
    }
    default:
      return state;
  }
};

export default videosReducer;
