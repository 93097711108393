import axios from "axios";
import React, { useEffect, useState } from "react";
import { OnboardingIndexContainer } from "./OnboardingIndexContainer";

export const OnboardingPage = () => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    axios.get("/api/me").then((r) => setUserId(r.data.id));
  }, []);
  return <OnboardingIndexContainer userId={userId} />;
};
