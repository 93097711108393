import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";
import { Content } from "../types/contents";

const labelFormat = "YYYY年MM月";

interface Props {
  contentsGroupId: number;
  contents: Content[];
}

export const ArchivedContentsList: React.FC<Props> = ({ contentsGroupId, contents }) => {
  const [months, setMonths] = useState([]);
  const [contentBookmarks, setContentBookmarks] = useState([]);

  useEffect(() => {
    if (contents) {
      const uniqueMonths = contents
        .map((e) => moment(e.ended_at).format(labelFormat))
        .filter((x, i, self) => self.indexOf(x) === i);
      setMonths(uniqueMonths);
    }
  }, [contents]);

  useEffect(() => {
    const f = async () => {
      const bookmarksResp = await axios.get("/api/content_bookmarks");
      setContentBookmarks(bookmarksResp.data);
    };
    f();
  }, [contentsGroupId]);

  return (
    <Wrapper>
      {months &&
        months.map((m) => (
          <Year key={m}>
            <LabelWrapper>
              <Border />
              <Label>{m}</Label>
            </LabelWrapper>
            <Events>
              {contents &&
                contents
                  .filter((e) => moment(e.ended_at).format(labelFormat) === m)
                  .map((e) => {
                    const bookmarked =
                      contentBookmarks &&
                      contentBookmarks.find((contentBookmark) => contentBookmark.content_id === e.id);
                    return (
                      <ContentItem key={e.id} href={e.facebook_url} target="_blank" rel="noreferrer noopener">
                        <Cover className="cover">
                          <CoverImage src={e.image_url} />
                        </Cover>
                        <ShowDetails className="show-details">Facebookでみる</ShowDetails>
                        <TitleWrapper>
                          <Title className="title">{e.name}</Title>
                          {/* <Star
                            className="view-favorite"
                            bookmarked={bookmarked}
                            onClick={(e) => {
                              e.preventDefault();
                              toggleBookmark(bookmarked, e);
                            }}
                          /> */}
                        </TitleWrapper>
                        <Description>{e.comment.length < 80 ? e.comment : `${e.comment.slice(0, 80)}...`}</Description>
                      </ContentItem>
                    );
                  })}
            </Events>
          </Year>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 750px;
  background-color: #f5f7f7;
  overflow: scroll;
  padding: 0 40px;
`;

const Year = styled.div`
  padding-top: 10px;
  width: 900px;
  margin: 0 auto;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
`;

const Border = styled.div`
  width: 4px;
  height: 18px;
  border-radius: 2px;
  background-color: ${Red};
  margin-right: 12px;
`;

const Label = styled.p`
  font-size: 20px;
  letter-spacing: 1.6px;
`;

const Events = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ContentItem = styled.a`
  transition: all 0.2s;
  cursor: pointer;
  display: block;
  position: relative;
  width: 265px;
  margin: 16px;
  &:hover {
    background-color: unset;
    text-decoration: none;
    color: #000;

    .cover:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5); /*半透明のフィルターをかける*/
    }
    .show-details {
      display: block;
    }
    .title {
      color: ${Red};
    }
  }
`;

const ShowDetails = styled.div`
  position: absolute;
  top: 60px;
  left: 60px;
  border-radius: 21px;
  width: 146px;
  height: 40px;
  border: solid 2px #fff;
  color: #fff;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1.12px;
  font-weight: bold;
  display: none;
`;

const Cover = styled.div`
  position: relative;
`;

const Star = styled.i`
  cursor: pointer;
  font-size: 20px;
  transition: all 0.2s;
  ${(p) =>
    p.bookmarked
      ? css`
          color: ${Red};
        `
      : css`
          color: #d2d2d2;
          &:hover {
            color: rgba(229, 2, 19, 0.1);
          }
        `};
`;

const CoverImage = styled.img`
  object-fit: cover;
  width: 265px;
  height: 152px;
`;

const TitleWrapper = styled.div`
  margin-top: 17px;
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  width: 300px;
`;

const Description = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.77;
`;
