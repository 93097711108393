import React, { useMemo, useRef, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useClickAway } from "react-use";
import styled, { css } from "styled-components";
import { iconList } from "../constants/iconList";
import { Red } from "../styles/colors";
import { IconComponent } from "./IconComponent";

interface Props {
  iconSelectHandler: (arg: string) => void;
  selectedIconName: string;
}

export const IconSelector: React.FC<Props> = ({ iconSelectHandler, selectedIconName }) => {
  const [open, setOpen] = useState(false);
  const selectorRef = useRef(null);

  useClickAway(selectorRef, () => {
    setOpen(false);
  });

  const toggleOpen = () => {
    setOpen(!open);
  };
  const selectedIcon = useMemo(() => iconList.find((v) => v.name === selectedIconName), [selectedIconName]);

  const selectIcon = (iconName) => {
    setOpen(false);
    iconSelectHandler(iconName);
  };

  return (
    <Wrapper ref={selectorRef}>
      <SelectorWrapper onClick={toggleOpen}>
        <SelectedIcon>
          <IconComponent iconName={selectedIconName} />
        </SelectedIcon>
        <ChevronWrapper>{open ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}</ChevronWrapper>
      </SelectorWrapper>
      <IconsWrapper open={open}>
        {iconList.map((icon) => (
          <Icon key={icon.name} onClick={() => selectIcon(icon.name)}>
            {icon({ size: 32 })}
          </Icon>
        ))}
      </IconsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const IconsWrapper = styled.div<{ open: boolean }>`
  position: absolute;
  background-color: #fff;
  max-width: 370px;
  padding: 16px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  flex-wrap: wrap;
  ${({ open }) =>
    open
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`;

const Icon = styled.div`
  &:hover {
    background-color: rgba(229, 2, 19, 0.1);
  }
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectorWrapper = styled.div`
  display: flex;
`;

const ChevronWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Red};
  width: 24px;
  height: 48px;
  border: 1px solid #dedede;
  border-left: unset;
`;

const SelectedIcon = styled(Icon)`
  border: 1px solid #dedede;
`;
