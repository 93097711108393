import React, { useRef, useState } from "react";
import { useClickAway } from "react-use";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";
import { VideoCategory } from "../types/video";
import { ErrorMessage } from "./FormParts";

interface Props {
  categories: VideoCategory[];
  formData: any;
  formDispatch: any;
}

export const VideoCategoryInput: React.FC<Props> = ({
  categories,
  formData,
  formDispatch,
}) => {
  const optionsRef = useRef();
  const [openOptions, setOpenOptions] = useState(false);

  useClickAway(optionsRef, () => {
    setOpenOptions(false);
  });

  return (
    <div style={{ position: "relative", zIndex: 2 }}>
      <CategoryInput
        error={null}
        onClick={() => {
          setOpenOptions(true);
        }}
        selected={formData.video_category_id !== undefined}
      >
        {formData.video_category_id === undefined
          ? "カテゴリー"
          : categories.find((c) => c.id === formData.video_category_id)?.name}
      </CategoryInput>
      <Options open={openOptions ? "open" : null} ref={optionsRef}>
        {categories &&
          categories.map((c) => (
            <Option
              key={c.id}
              onClick={() => {
                formDispatch({
                  type: "update",
                  name: "video_category_id",
                  value: c.id,
                });
                setOpenOptions(false);
              }}
            >
              {c.name}
            </Option>
          ))}
      </Options>
      <ErrorMessage>{null}</ErrorMessage>
    </div>
  );
};

const CategoryInput = styled.div<{ selected: boolean; error: null | string }>`
  cursor: pointer;
  font-size: 14px;
  width: 200px;
  height: 48px;
  border: solid 1px #d2d2d2;
  line-height: 48px;
  padding: 0;
  padding-left: 18px;
  ${({ error }) =>
    error !== null &&
    css`
      border: solid 1px ${Red};
      background-color: rgba(229, 2, 19, 0.1);
    `};
  ${({ selected }) =>
    selected
      ? css`
          color: #000;
        `
      : css`
          color: #777;
        `}
  @media screen and (max-width: 1024px) {
    margin-left: 0;
    width: 242px;
  }
`;

const Options = styled.div`
  position: absolute;
  top: 60px;
  max-height: 220px;
  width: 250px;
  background-color: #fff;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  overflow: scroll;
  ${(p) =>
    p.open === "open"
      ? css`
          display: block;
        `
      : css`
          display: none;
        `};
`;

const Option = styled.div`
  cursor: pointer;
  padding: 16px;
  transition: all 0.2s;
  &:hover {
    background-color: #f1f3f4;
  }
`;
