import axios from "axios";

export const Actions = {
  FETCH: "CAROUSEL::FETCH",
};

export const fetchCarousel = () => {
  return async (dispatch) => {
    const response = await axios.get("/api/carousel");

    dispatch({
      type: Actions.FETCH,
      data: response.data,
    });
  };
};
