import React from "react";
import styled from "styled-components";
import { ContainerCommunityFragment } from "../@types/types";
import { Red } from "../styles/colors";
import { Content } from "../types/contents";
import { ContentsGroup } from "../types/contentsGroup";
import { CloseModalIcon } from "./CloseModalIcon";
import { ContentFormModal } from "./ContentFormModal";
import { ModalBase } from "./ModalBase";
import { ModalContentsList } from "./ModalContentsList";

interface Props {
  community: ContainerCommunityFragment;
  isOpenContentModal: boolean;
  contentModalState: "editableContentList" | "contentEdit" | "contentDelete" | null;
  showContents: Content[];
  modalContent: Content | null;
  contentsGroups: ContentsGroup[];
  closeContentModal: () => void;
  openContentDelete: (content: Content) => void;
  handleDeleteContent: (id: number) => void;
  openContentEdit: (content: Content) => void;
}

export const ContentModals: React.FC<Props> = ({
  community,
  isOpenContentModal,
  closeContentModal,
  openContentDelete,
  contentModalState,
  handleDeleteContent,
  showContents,
  openContentEdit,
  modalContent,
  contentsGroups,
}) => {
  return (
    <>
      {contentModalState === "editableContentList" && showContents && (
        <ModalBase
          isOpen={isOpenContentModal}
          onRequestClose={closeContentModal}
          contentLabel="Day Content Modal"
          width={583}
          spWidth={290}
        >
          <ModalContentsList
            contentsGroups={contentsGroups}
            showContents={showContents}
            handleClick={openContentEdit}
            closeModal={closeContentModal}
          />
        </ModalBase>
      )}
      {contentModalState === "contentEdit" && (
        <ContentFormModal
          community={community}
          open={isOpenContentModal}
          modalContent={modalContent}
          contentsGroups={contentsGroups}
          onCloseModal={closeContentModal}
          openContentDelete={openContentDelete}
        />
      )}
      {contentModalState === "contentDelete" && modalContent && (
        <ModalBase
          isOpen={isOpenContentModal}
          onRequestClose={closeContentModal}
          contentLabel="Day Content Modal"
          width={420}
          spWidth={290}
        >
          <Base>
            <CloseModalIcon onClick={closeContentModal} className="view-close" />
            <Title>このコンテンツを一覧から削除しますか？</Title>
            <SubText>
              一覧から削除すると再度追加が必要になります。
              <br />
              ※Facebook内のスレッドは削除されません。
            </SubText>

            <Buttons>
              <CancelButton onClick={closeContentModal}>キャンセル</CancelButton>
              <DeleteButton
                onClick={() => {
                  handleDeleteContent(modalContent.id);
                }}
              >
                削除する
              </DeleteButton>
            </Buttons>
          </Base>
        </ModalBase>
      )}
    </>
  );
};

const Base = styled.div`
  padding: 69px 38px 51px;
  position: relative;
  @media screen and (max-width: 1024px) {
    padding: 50px 4px 51px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
`;

const SubText = styled.div`
  font-size: 14px;
  text-align: center;
  margin: 24px 0 46px;
  line-height: 24px;
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    margin: 24px auto 46px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const Button = styled.div`
  cursor: pointer;
  height: 36px;
  border-radius: 22px;
  border: solid 2px #6c6c6c;
  box-shadow: 0.5px 0.9px 2px 0px rgba(30, 30, 30, 0.2);
  line-height: 37px;
  text-align: center;
`;

const DeleteButton = styled(Button)`
  margin-left: 21px;
  width: 136px;
  border: solid 2px ${Red};
  color: ${Red};
  transition: all 0.2s;
  &:hover {
    color: #fff;
    background-color: ${Red};
  }
  @media screen and (max-width: 1024px) {
    margin-left: 0;
    margin-bottom: 20px;
  }
`;

const CancelButton = styled(Button)`
  width: 140px;
  color: #6c6c6c;
  transition: all 0.2s;
  &:hover {
    color: #fff;
    background-color: #6c6c6c;
  }
`;
