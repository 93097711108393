import axios from "axios";

export const Actions = {
  FETCH_CONTENT_BOOKMARKS: "CONTENTBOOKMARKS::FETCH",
  SEND_CONTENT_BOOKMARKS: "CONTENTBOOKMARKS::SEND",
  DELETE_CONTENT_BOOKMARKS: "CONTENTBOOKMARKS::DELETE",
  ADD_CONTENT_BOOKMARKS: "CONTENTBOOKMARKS::ADD",
  REMOVE_CONTENT_BOOKMARKS: "CONTENTBOOKMARKS::REMOVE",
};

export const fetchContentBookmarks = () => {
  return async (dispatch) => {
    const response = await axios.get("/api/content_bookmarks");

    dispatch({
      type: Actions.FETCH_CONTENT_BOOKMARKS,
      data: response.data,
    });
  };
};

export const sendContentBookmark = (contentId, csrfToken) => {
  return async (dispatch) => {
    const data = {
      content_bookmarks: { content_id: contentId },
    };
    const header = {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    };
    axios
      .post("/api/content_bookmarks", data, header)
      .then((response) => {
        dispatch(addContentBookmark(response.data));
      })
      .catch((response) => {
        console.error(response);
      });
  };
};

export const deleteContentBookmark = (contentBookmarkId, csrfToken) => {
  return async (dispatch) => {
    const header = {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    };
    axios
      .delete(`/api/content_bookmarks/${contentBookmarkId}`, header)
      .then(() => {
        dispatch(removeContentBookmark(contentBookmarkId));
      })
      .catch((response) => {
        console.error(response);
      });
  };
};

const addContentBookmark = (data) => ({
  type: Actions.ADD_CONTENT_BOOKMARKS,
  data,
});

const removeContentBookmark = (id) => ({
  type: Actions.REMOVE_CONTENT_BOOKMARKS,
  id,
});
