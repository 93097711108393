import React from "react";
import styled from "styled-components";
import { Red } from "../styles/colors";

const LogoImagePath = require("images/VIEW_logo.png");

interface Props {
  iconUrl: string;
}
export const AdminHeader: React.FC<Props> = ({ iconUrl }) => {
  return (
    <Base>
      <TopLinkWrapper href="/view">
        {iconUrl && (
          <IconWrapper>
            <Icon src={iconUrl} />
          </IconWrapper>
        )}
        <LogoWrapper>
          <Logo src={LogoImagePath} />
        </LogoWrapper>
      </TopLinkWrapper>
    </Base>
  );
};

const Base = styled.div`
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 3px solid ${Red};
  font-weight: 500;
  font-size: 15px;
  @media screen and (max-width: 1024px) {
    border-bottom: 2px solid ${Red};
  }
`;

const TopLinkWrapper = styled.a`
  display: flex;
  align-items: center;
  margin: 21px 0 16px 38px;
  &:hover {
    background-color: white;
  }
  @media screen and (max-width: 1024px) {
    margin: 19px 0 15px 15px;
  }
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #eee;
  @media screen and (max-width: 1024px) {
    width: 32px;
    height: 32px;
  }
`;

const Icon = styled.img`
  width: 100%;
  border-radius: 50%;
`;

const LogoWrapper = styled.div`
  width: 120px;
  margin-left: 8px;
  @media screen and (max-width: 1024px) {
    width: 68px;
  }
`;

const Logo = styled.img`
  width: 100%;
`;
