import axios from "axios";
import { useCallback } from "react";
import { useLocation } from "react-router";

type EventLogParams = {
  event_target: string;
  event_action: string;
  event_params?: string;
};

export const useLog = () => {
  const location = useLocation();
  const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
  const log = useCallback((eventParams: EventLogParams) => {
    axios
      .post(
        "/logs",
        {
          ...eventParams,
          path: location.pathname,
          query_params: location.search,
        },
        {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        }
      )
      .catch();
  }, []);
  return log;
};
