import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { ContainerCommunityFragment } from "../@types/types";
import { deleteEvent, fetchEvents } from "../actions/Events";
import { useGroupingEventsByDay } from "../hooks/useGroupingEventsByDay";
import { RootStore } from "../reducers";
import { Red } from "../styles/colors";
import { Event } from "../types/event";
import { AppDispatch } from "./App";
import { EventListComponent } from "./EventListComponent";
import { EventModals, EventModalState } from "./EventModals";

moment.locale("ja");

const appDataSelector = (state: RootStore) => state.events;

interface Props {
  userId: number;
  community: ContainerCommunityFragment;
  editable: boolean;
}

export const ScheduleContainer: React.FC<Props> = ({ userId, community, editable }) => {
  const [isOpenEventModal, setIsOpenEventModal] = useState(false);
  const [eventModalState, setEventModalState] = useState<EventModalState>(null);
  const [showEvents, setShowEvents] = useState<Event[] | null>(null);
  const [modalEvent, setModalEvent] = useState<Event | null>(null);
  const events = useSelector(appDataSelector);
  const [groupedEventsByDay, useGroupedEventsByDay] = useGroupingEventsByDay();
  const dispatch = useDispatch<AppDispatch>();
  const [currentStartedAt, setCurrentStartedAt] = useState(null);

  const fetchEventsWithSetStartedAt = useCallback(
    (startedAt, endedAt) => {
      setCurrentStartedAt(startedAt);
      dispatch(
        fetchEvents({
          started_at: startedAt,
          ended_at: endedAt,
        })
      );
    },
    [setCurrentStartedAt, dispatch]
  );

  useEffect(() => {
    const startedAt = moment().startOf("month").toJSON();
    fetchEventsWithSetStartedAt(startedAt, null);
  }, [fetchEventsWithSetStartedAt]);

  useEffect(() => {
    useGroupedEventsByDay(events);
  }, [events, useGroupedEventsByDay]);

  const openEventListOnDate = useCallback(
    (todayEventIds) => {
      setShowEvents(events.filter((event) => todayEventIds.includes(event.id)));
      setIsOpenEventModal(true);
      setEventModalState("showEventList");
    },
    [events, setShowEvents]
  );

  const openEventShow = useCallback(
    (eventId: number) => {
      const event = events.find((e) => e.id === eventId);
      if (event) {
        setModalEvent(event);
        setIsOpenEventModal(true);
        setEventModalState("eventShow");
      }
    },
    [events]
  );

  const renderDayContent = useCallback(
    ({ _activeStartDate, date, view }) => {
      if (view === "month" && groupedEventsByDay) {
        const todayEvents = groupedEventsByDay[moment(date).format("M月D日 (ddd)")];
        return (
          <TileContent>
            {todayEvents &&
              todayEvents.slice(0, 3).map((e) => (
                <EventItem key={e.id} onClick={() => openEventShow(e.id)}>
                  {e.title}
                </EventItem>
              ))}
            {todayEvents && todayEvents.length > 3 && (
              <MoreEventsButton onClick={() => openEventListOnDate(todayEvents.map((e) => e.id))}>
                他{todayEvents.length - 3}件
              </MoreEventsButton>
            )}
          </TileContent>
        );
      }
      return null;
    },
    [groupedEventsByDay, openEventListOnDate, openEventShow]
  );

  const openEvents = useCallback(() => {
    setShowEvents(events);
    setIsOpenEventModal(true);
    setEventModalState("editableEventList");
  }, [events]);

  const openEventEdit = useCallback(
    (eventId) => {
      const event = events.find((e) => e.id === parseInt(eventId));
      setModalEvent(event || null);
      setIsOpenEventModal(true);
      setEventModalState("eventEdit");
    },
    [events]
  );

  const openEventDelete = useCallback((event: Event) => {
    setModalEvent(event);
    setIsOpenEventModal(true);
    setEventModalState("eventDelete");
  }, []);

  const closeEventModal = useCallback(() => {
    setIsOpenEventModal(false);
    setShowEvents(null);
    setModalEvent(null);
    setEventModalState(null);
  }, []);

  const handleDeleteEvent = useCallback(
    (id: number) => {
      const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]")?.content;
      if (!csrfToken) return null;
      dispatch(deleteEvent(id, csrfToken, closeEventModal));
    },
    [dispatch, closeEventModal]
  );

  const handleNavigationLabel = useCallback(
    ({ date, label, locale, view }) => {
      useEffect(() => {
        const startDate = moment(date);
        const startOfMonthOfToday = moment().startOf("month").toJSON();
        if (startDate.toJSON() !== currentStartedAt) {
          fetchEventsWithSetStartedAt(
            startDate.toJSON(),
            startOfMonthOfToday >= startDate.toJSON() ? startDate.endOf("month").toJSON() : null
          );
        }
      }, [label, date]);

      return label;
    },
    [currentStartedAt, fetchEventsWithSetStartedAt]
  );

  return (
    <Base>
      <Title>スケジュール</Title>
      <SubTitle>SCHEDULE</SubTitle>
      <Schedule>
        <MonthlyCal
          locale="ja-JP"
          value={new Date()}
          tileContent={renderDayContent}
          className={"view-calendar"}
          calendarType={"US"}
          navigationLabel={handleNavigationLabel}
        />
        <MediaQuery query="(min-width: 1200px)">
          <EventListComponent groupedEventsByDay={groupedEventsByDay} openEventShow={openEventShow} />
        </MediaQuery>
      </Schedule>
      <Buttons>
        {editable && (
          <>
            <Button
              onClick={() => {
                openEventEdit(null);
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "21px",
                  marginRight: "10px",
                }}
              >
                ＋
              </span>
              予定の作成
            </Button>
            {events && events.length > 0 && (
              <EditButton onClick={openEvents}>
                <Icon className="view-edit" />
                <div style={{ marginLeft: "-10px" }}>予定の編集</div>
              </EditButton>
            )}
          </>
        )}
      </Buttons>
      <EventModals
        community={community}
        closeEventModal={closeEventModal}
        openEventShow={openEventShow}
        openEventEdit={openEventEdit}
        openEventDelete={openEventDelete}
        isOpenEventModal={isOpenEventModal}
        eventModalState={eventModalState}
        showEvents={showEvents}
        handleDeleteEvent={handleDeleteEvent}
        modalEvent={modalEvent}
      />
    </Base>
  );
};

const Base = styled.div`
  background-color: rgba(245, 247, 247, 1);
  padding-bottom: 100px;
  @media screen and (max-width: 1024px) {
    padding-bottom: 28px;
  }
`;

const EventItem = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 96px;
  font-size: 12px;
  color: #000;
  transition: all 0.2s;
  margin-left: 4px;
  &:hover {
    color: ${Red};
  }
  @media screen and (max-width: 1024px) {
    font-size: 10px;
  }
`;
const MoreEventsButton = styled.div`
  cursor: pointer;
  width: 52px;
  height: 22px;
  text-align: center;
  border: solid 1px #d2d2d2;
  border-radius: 11px;
  color: #000;
  transition: all 0.2s;
  &:hover {
    background-color: #d2d2d2;
  }
  @media screen and (max-width: 1024px) {
    width: 41px;
    height: 14px;
    line-height: 14px;
  }
`;
const Title = styled.h2`
  margin: -104px auto 12px;
  padding-top: 160px;
  text-align: center;
  font-size: 38px;
  @media screen and (max-width: 1024px) {
    margin: -52px auto 7px;
    padding-top: 56px;
    font-size: 22px;
  }
`;

const MonthlyCal = styled(Calendar)`
  &.view-calendar {
    width: 938px;
    border: none;
    button {
      background-color: #fff;
      border: none;
      height: 110px;
      .react-calendar__tile--active {
        color: red;
      }
    }
    > .react-calendar__navigation {
      height: 24px;
      background-color: rgba(245, 247, 247, 1);
      margin-bottom: 0;
      padding-bottom: 8px;
      @media screen and (max-width: 1024px) {
        justify-content: center;
      }

      > button {
        height: 24px;
        background-color: rgba(245, 247, 247, 1);
      }
      .react-calendar__navigation__label {
        flex-grow: 0 !important;
        font-size: 18px;
        &:hover {
          background-color: rgba(245, 247, 247, 1);
        }
        @media screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
      .react-calendar__navigation__prev-button {
        font-size: 24px;
        color: #9e9e9e;
        line-height: 24px;
        &:hover {
          background-color: rgba(245, 247, 247, 1);
          color: ${Red};
        }
        @media screen and (max-width: 1024px) {
          line-height: 16px;
          color: ${Red};
          min-width: 0;
        }
      }
      .react-calendar__navigation__next-button {
        font-size: 24px;
        color: #9e9e9e;
        line-height: 24px;
        &:hover {
          background-color: rgba(245, 247, 247, 1);
          color: ${Red};
        }
        @media screen and (max-width: 1024px) {
          line-height: 16px;
          color: ${Red};
          min-width: 0;
        }
      }
      .react-calendar__navigation__prev2-button {
        display: none;
      }
      .react-calendar__navigation__next2-button {
        display: none;
      }
    }
    .react-calendar__month-view__weekdays__weekday {
      border: solid 1px rgba(245, 247, 247, 1);
      abbr {
        text-decoration: none;
      }
      @media screen and (max-width: 1024px) {
        background-color: rgba(245, 247, 247, 1);
      }
    }

    .react-calendar__month-view__days {
      background-color: rgba(245, 247, 247, 1);
    }

    .react-calendar__month-view__days__day {
      text-align: right;
      @media screen and (max-width: 1024px) {
        font-size: 10px;
        padding: 5px 2px;
        text-align: center;
        height: 84px;
        margin-bottom: 10px;
      }
    }

    .react-calendar__tile {
      border: solid 1px rgba(245, 247, 247, 1);
      background-color: #fff;
      &:hover,
      &:active,
      &:focus {
        background-color: #fff;
      }
    }

    .react-calendar__tile--active {
      color: #000;
      border: solid 2px #e50213;
      background-color: #fff;
      &:hover,
      &:active {
        background-color: #fff;
      }
    }
  }
`;

const SubTitle = styled.h2`
  margin: 0 auto 42px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.5px;
  @media screen and (max-width: 1024px) {
    margin: 0 auto 20px;
    font-size: 12px;
    letter-spacing: 1.2px;
  }
`;

const Schedule = styled.div`
  display: flex;
  justify-content: center;
`;

const TileContent = styled.div`
  height: 100%;
  text-align: left;
  margin-top: 6px;
`;

const Buttons = styled.div`
  display: flex;
  margin: 40px auto;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    margin: 18px auto;
  }
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 246px;
  height: 60px;
  box-shadow: 0.5px 0.9px 3.8px 0.2px rgba(30, 30, 30, 0.2);
  border: solid 2px #e50213;
  border-radius: 60px;
  text-align: center;
  font-size: 17px;
  background-color: #fff;
  color: #e50213;
  letter-spacing: 0.6px;
  transition: all 0.2s;
  &:hover {
    background-color: #e50213;
    color: #fff;
  }
  @media screen and (max-width: 1024px) {
    width: 256px;
    height: 50px;
    font-size: 14px;
  }
`;

const EditButton = styled(Button)`
  margin-left: 60px;
  @media screen and (max-width: 1024px) {
    margin-left: 0;
    margin-top: 20px;
  }
`;

const Icon = styled.i`
  margin-left: -23px;
  font-size: 50px;
  line-height: 56px;
`;
