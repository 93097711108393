import React from "react";
import styled from "styled-components";
import { Red, White } from "../styles/colors";

export const GuideContentHeader = ({
  index,
  title,
  description,
}) => {
  return (
    <Wrapper>
      <TitleWrapper>
        <Index>{index}</Index>
        <Title>{title}</Title>
      </TitleWrapper>
      <Description>
        {description}
      </Description>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  height: 34px;
`;

const Index = styled.div`
  width: 35px;
  font-size: 20px;
  font-weight: bold;
  background: ${Red};
  color: ${White};
  border-radius: 50% 0 0 50%;
  padding: 6px 4px 6px 10px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 16px;
  padding: 6px 18px;
  border: solid 2px ${Red};
  width: calc(100% - 35px);
  background: ${White};
`;

const Description = styled.p`
  margin-top: 32px;
  font-size: 13px;
  line-height: 23px;
  letter-spacing: 1.04px;
  white-space: pre-wrap;
`;

