import React from "react";
import { SubmitButton } from "./FormParts";
import styled from "styled-components";
import { Red } from "../styles/colors";

interface Props {
  files: File[];
  checkCsv: () => void;
  uploaded: boolean;
  withEditable: boolean;
  handleChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleEditable: () => void;
}

export const CsvUploadSection: React.FC<Props> = ({
  files,
  checkCsv,
  uploaded,
  withEditable,
  handleChangeFile,
  toggleEditable,
}) => {
  return (
    <>
      <H2>CSVアップロード</H2>
      <UploadWrapper>
        <UploadButtonWrapper>
          <CSVUploadButton htmlFor="file_upload">
            ファイルを選択
            <br />
            （2ファイル以上アップロードする場合はファイルを複数選択してください）
            <input
              style={{ display: "none" }}
              id="file_upload"
              type="file"
              multiple
              onChange={(e) => handleChangeFile(e)}
            />
          </CSVUploadButton>
          <div>
            {files && files ? files.map((file) => <p key={file.name}>{file.name}</p>) : "現在選択されていません"}
          </div>
        </UploadButtonWrapper>
        <p style={{ color: Red, fontSize: "13px" }}>
          ※CSVファイルは Campfire コミュニティの管理画面からダウンロードしたものを使用してください。
          <br />
          退会済みユーザーやCSVに含まれないユーザーは削除されます。 削除したくない場合はロックしてください。
        </p>
      </UploadWrapper>
      <UploadSettings>
        <EditableWrapper>
          <EditableSelectBox
            id="with-editable"
            type="checkbox"
            checked={withEditable}
            onChange={() => toggleEditable()}
          />
          <label htmlFor="with-editable" style={{ fontSize: "13px" }}>
            CSVアップロード時に追加するメンバーをデフォルトで編集可能メンバーにする（管理者権限は付与しません。）
          </label>
        </EditableWrapper>
        <SubmitUploadButton onClick={checkCsv}>{uploaded ? "完了しました" : "CSVアップロード"}</SubmitUploadButton>
      </UploadSettings>
    </>
  );
};

const H2 = styled.h2`
  font-size: 24px;
  margin: 8px 0 16px;
`;

const UploadWrapper = styled.div``;

const UploadButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CSVUploadButton = styled.label`
  cursor: pointer;
  text-align: center;
  border: 1px solid #aaa;
  padding: 30px 50px;
  margin: 8px 16px 8px 0;

  width: 400px;
`;

const UploadSettings = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #aaa;
`;

const EditableWrapper = styled.div`
  display: flex;
  margin: 16px 0;
`;

const EditableSelectBox = styled.input`
  margin: 0;
  margin-right: 8px;
`;

const SubmitUploadButton = styled(SubmitButton)``;
