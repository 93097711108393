import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiUsers } from "react-icons/fi";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";

const fetchUserData = async () => {
  const response = await axios.get("/api/users/current");
  return response.data;
};

export const ProfileWidget = ({ userName }) => {
  const [displayDropDown, setDisplayDropDown] = useState(false);
  const [profileData, setProfileData] = useState(null);
  useEffect(() => {
    fetchUserData().then((data) => {
      setProfileData(data);
    });
  }, []);

  const handleLogout = () => {
    const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
    axios
      .delete("/users/sign_out.json", {
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      })
      .then(() => {
        location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <Base
        onMouseEnter={() => setDisplayDropDown(true)}
        onMouseLeave={() => setDisplayDropDown(false)}
        hovered={displayDropDown}
      >
        <Icon className="view-person" />
        {displayDropDown && (
          <Widget className="widget">
            <WidgetContent>
              <Profile>
                <ProfileIcon>
                  <ProfileImage src={profileData?.icon_url} />
                </ProfileIcon>
                <Name>{userName}</Name>
              </Profile>
              <ActionList>
                <Link to="/view/my_page">
                  <Action>
                    <ActionIcon className="view-person" />
                    <ActionText>マイページ</ActionText>
                  </Action>
                </Link>
                {profileData?.members_count > 1 && (
                  <ATag href="/communities">
                    <Action style={{ marginLeft: "4px", marginTop: "-4px" }}>
                      <IconWrapper>
                        <FiUsers size={24} />
                      </IconWrapper>
                      <ActionText style={{ marginLeft: "0" }}>コミュニティを切り替える</ActionText>
                    </Action>
                  </ATag>
                )}
                <Action onClick={handleLogout} style={{ marginLeft: "4px", marginTop: "-4px" }}>
                  <ActionIcon className="view-logout" />
                  <ActionText style={{ marginLeft: "0" }}>ログアウト</ActionText>
                </Action>
              </ActionList>
            </WidgetContent>
          </Widget>
        )}
      </Base>
    </>
  );
};

const Base = styled.div`
  position: relative;
  padding: 6px;
  padding-top: 12px;
  transition: all 0.2s;
  ${(p) =>
    p.hovered &&
    css`
      color: ${Red};
    `}
  @media screen and (max-width: 1024px) {
    padding: 0;
  }
`;

const Icon = styled.i`
  font-size: 42px;
`;

const Widget = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 5;
  color: #000;
  @media screen and (max-width: 1024px) {
    top: 30px;
    right: -34px;
  }
`;

const WidgetContent = styled.div`
  width: 300px;
  margin-top: 17px;
  background-color: #fff;
  box-shadow: 1px 1.7px 6px 0 rgba(0, 0, 0, 0.1);
`;

const Profile = styled.div`
  padding: 15px 20px;
  display: flex;
  border-bottom: 1px solid #d2d2d2;
  align-items: center;
`;

const ProfileIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 25px;
  background-color: #ddd;
`;

const ProfileImage = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 25px;
`;

const Name = styled.div`
  font-size: 16px;
  letter-spacing: 1.2px;
  margin-left: 8px;
`;

const ActionList = styled.div`
  padding: 16px;
`;

const Action = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s;
  &:hover {
    color: ${Red};
  }
`;

const ActionIcon = styled.i`
  font-size: 42px;
`;

const ActionText = styled.div`
  margin-left: 4px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ATag = styled.a``;
