import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";
import { EventFormData } from "./EventForm";

export const TimeInput = ({ formData, setFormData, name, changeHandler, error }) => {
  const documentClickHandler = useRef();
  const optionsRef = useRef<HTMLDivElement>(null);
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const selectTime = useCallback(
    (time) => {
      setFormData({
        ...formData,
        [name]: time,
      });
      setIsOpenOptions(false);
      removeDocumentClickHandler();
    },
    [formData]
  );

  const removeDocumentClickHandler = () => {
    if (documentClickHandler.current) {
      document.removeEventListener("click", documentClickHandler.current);
    }
  };

  useEffect(() => {
    documentClickHandler.current = (e: React.MouseEvent<Event, MouseEvent>): void => {
      if (optionsRef.current && optionsRef.current.contains(e.target as Node)) {
        return;
      }
      setIsOpenOptions(false);
      removeDocumentClickHandler();
    };
  }, []);

  const timeOptions = [...Array(24 * 2).keys()].map((e) => {
    const hour = Math.floor((e * 30) / 60);
    const formatted_hour = hour.toString().length === 1 ? "0" + hour : hour;
    const minute = (e * 30) % 60;
    const formatted_minute = minute.toString().length === 1 ? "0" + minute : minute;
    const time = formatted_hour + ":" + formatted_minute;
    return (
      <Option
        key={time}
        onClick={() => {
          selectTime(time);
        }}
      >
        {time}
      </Option>
    );
  });

  return (
    <div style={{ position: "relative" }}>
      <TextInput
        onClick={() => {
          setIsOpenOptions(true);
          if (documentClickHandler.current) {
            document.addEventListener("click", documentClickHandler.current);
          }
        }}
        name={name}
        value={formData[name]}
        onChange={changeHandler}
        error={error}
      />
      <Options open={isOpenOptions ? "open" : null} ref={optionsRef}>
        {timeOptions}
      </Options>
    </div>
  );
};

const TextInput = styled.input<{ error: string | null }>`
  cursor: pointer;
  font-size: 14px;
  width: 115px;
  height: 48px;
  border: solid 1px #d2d2d2;
  line-height: 48px;
  padding: 0;
  padding-left: 18px;
  ${(p) =>
    p.error !== null &&
    css`
      border: solid 1px ${Red};
      background-color: rgba(229, 2, 19, 0.1);
    `};
  @media screen and (max-width: 1024px) {
    width: 90px;
    padding-left: 10px;
    height: 38px;
    font-size: 13px;
  }
`;

const Options = styled.div<{ open: string | null }>`
  position: absolute;
  top: 60px;
  height: 210px;
  width: 150px;
  background-color: #fff;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  overflow: scroll;
  ${(p) =>
    p.open === "open"
      ? css`
          display: block;
        `
      : css`
          display: none;
        `};
`;

const Option = styled.div`
  cursor: pointer;
  padding: 16px;
  transition: all 0.2s;
  &:hover {
    background-color: #f1f3f4;
  }
`;
