import React from "react";
import styled, { css } from "styled-components";
import { useUploadImageWithDrop } from "../hooks/useUploadImageWithDrop";
import { Red } from "../styles/colors";

interface Props {
  imageUrl: string;
  imageUrlHandler: (arg: string) => void;
  error: string;
}

export const CommunityIconUploadInput: React.FC<Props> = ({
  imageUrl,
  imageUrlHandler,
  error,
}) => {
  const { getRootProps, getInputProps } = useUploadImageWithDrop({
    imageUrlHandler,
  });

  return (
    <div
      {...getRootProps({
        style: {
          display: "flex",
          margin: "0 auto 16px",
          cursor: "pointer",
        },
      })}
    >
      <input {...getInputProps()} />
      <Dragged>
        {imageUrl ? (
          <PreviewWrapper>
            <PreviewImage src={imageUrl} className="image" />
            <ChangeWrapper>
              <Icon className="view-camera" />
              <Text>変更する</Text>
            </ChangeWrapper>
          </PreviewWrapper>
        ) : (
          <PlaceHolder error={error}>
            <IconWrapper>
              <Icon className="view-camera" />
            </IconWrapper>
          </PlaceHolder>
        )}
      </Dragged>
    </div>
  );
};

const Dragged = styled.div`
  margin: 3px auto 0;
  width: 180px;
  height: 180px;
  border-radius: 50%;
`;

const PreviewWrapper = styled.div`
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /*半透明のフィルターをかける*/
    border-radius: 50%;
  }
`;

const PreviewImage = styled.img`
  object-fit: cover;
  width: 180px;
  height: 180px;
`;

const ChangeWrapper = styled.div`
  position: absolute;
  top: 54px;
  left: 54px;
  border-radius: 40px;
  width: 76px;
  height: 76px;
  border: solid 2px #f5f5f5;
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  @media screen and (max-width: 1024px) {
    top: 34px;
    left: 94px;
    width: 70px;
    height: 70px;
  }
`;

const Text = styled.div``;

const PlaceHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e9e9;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  line-height: 180px;
  ${(p) =>
    p.error !== null &&
    css`
      border: solid 2px ${Red};
    `};
`;

const IconWrapper = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 23px;
  background-color: #9e9e9e;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.i`
  font-size: 42px;
  color: #fff;
`;
