import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Red } from "../styles/colors";
import { IconComponent } from "./IconComponent";

export default function ContentsGroupsDropDownMenu({
  contentsGroups,
  setDisplayDropDown,
}) {
  return (
    <Base
      onMouseEnter={() => setDisplayDropDown(true)}
      onMouseLeave={() => setDisplayDropDown(false)}
    >
      <DropDownContentsWrapper>
        <DropDownContents>
          {contentsGroups &&
            contentsGroups.map((element) => {
              const names = element.name.split(" ");
              return (
                <MenuContent
                  to={"/view/" + element.id + "?from=header"}
                  key={element.id}
                >
                  <IconWrapper className="icon-wrapper">
                    <IconComponent iconName={element.icon_name} />
                  </IconWrapper>
                  <Texts>
                    <Name>
                      {names[0]}
                      {names[1] && (
                        <>
                          <br /> {names[1]}
                        </>
                      )}
                    </Name>
                    <SubName>{element.description}</SubName>
                  </Texts>
                </MenuContent>
              );
            })}
        </DropDownContents>
      </DropDownContentsWrapper>
    </Base>
  );
}

const Base = styled.div`
  top: 90px;
  position: absolute;
  width: 100%;
  z-index: 2;
`;

const DropDownContentsWrapper = styled.div`
  margin-top: 0px;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
`;

const DropDownContents = styled.div`
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  padding: 22px;
  padding-bottom: 18px;
  width: 1200px;
`;

const MenuContent = styled(Link)`
  display: flex;
  padding: 11px 12px;
  width: 276px;
  transition: all 0.1s;

  .icon-wrapper {
    i,
    svg {
      color: ${Red};
    }
  }
  &:hover {
    background-color: #fff;
    text-decoration: none;
    color: ${Red};
    .icon-wrapper {
      background-color: ${Red};
      i,
      svg {
        color: #fff;
      }
    }
  }
`;

const IconWrapper = styled.div`
  width: 76px;
  height: 76px;
  border: 2px solid ${Red};
  border-radius: 41px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
`;

const Texts = styled.div`
  margin: 20px 12px;
`;

const Name = styled.div`
  font-size: 15px;
  letter-spacing: 1.2px;
`;

const SubName = styled.div`
  margin-top: 15px;
  font-size: 12px;
  letter-spacing: 1.2px;
`;
