import React, { useEffect, useRef, useState, useCallback } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";
import { ErrorMessage } from "./FormParts";

const DEFAULT_WIDTH = 60;

export const CategoryInput = ({ errorMessages, formData, name, options, selectHandler, width }) => {
  const documentClickHandler = useRef();
  const [isOpenOptions, setIsOpenOptions] = useState(false);

  const removeDocumentClickHandler = useCallback(() => {
    document.removeEventListener("click", documentClickHandler.current);
  }, []);

  useEffect(() => {
    documentClickHandler.current = (e) => {
      setIsOpenOptions(false);
      removeDocumentClickHandler();
    };
  }, [removeDocumentClickHandler]);

  return (
    <Wrapper>
      <CategoryInputWrapper
        error={errorMessages[name]}
        onClick={() => {
          setIsOpenOptions(true);
          document.addEventListener("click", documentClickHandler.current);
        }}
      >
        <CategoryBase width={width ? width : DEFAULT_WIDTH}>
          {formData[name] ? options.find((o) => o.id === formData[name]).name : ""}
        </CategoryBase>
        <CategoryIconWrapper>
          {isOpenOptions ? (
            <MdExpandLess style={{ fontSize: 30, color: "#9e9e9e" }} />
          ) : (
            <MdExpandMore style={{ fontSize: 30, color: "#9e9e9e" }} />
          )}
        </CategoryIconWrapper>
      </CategoryInputWrapper>
      <Options open={isOpenOptions ? "open" : null}>
        {options &&
          options.map((o) => (
            <Option
              key={o.id}
              onClick={() => {
                selectHandler(name, o.id);
              }}
            >
              {o.name}
            </Option>
          ))}
      </Options>
      <ErrorMessage>{errorMessages[name]}</ErrorMessage>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-right: 30px;
`;

const CategoryInputWrapper = styled.div`
  display: flex;
  border: solid 1px #d2d2d2;
  ${(p) =>
    p.error !== null &&
    css`
      border: solid 1px ${Red};
      background-color: rgba(229, 2, 19, 0.1);
    `};
`;

const CategoryBase = styled.div`
  cursor: pointer;
  font-size: 14px;
  width: ${(p) => p.width}px;
  height: 48px;
  line-height: 48px;
  padding: 0;
  padding-left: 18px;
  @media screen and (max-width: 1024px) {
    margin-left: 0;
    width: 242px;
  }
`;

const CategoryIconWrapper = styled.div`
  cursor: pointer;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: solid 1px #d2d2d2;
`;

const Options = styled.div`
  position: absolute;
  z-index: 4;
  top: 60px;
  max-height: 210px;
  width: 250px;
  background-color: #fff;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  overflow: scroll;
  ${(p) =>
    p.open === "open"
      ? css`
          display: block;
        `
      : css`
          display: none;
        `};
`;

const Option = styled.div`
  cursor: pointer;
  padding: 16px;
  transition: all 0.2s;
  &:hover {
    background-color: #f1f3f4;
  }
`;
