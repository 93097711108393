import axios from "axios";

export const Actions = {
  FETCH_CONTENTS_GROUPS: "CONTENTS_GROUPS::FETCH",
};

export const fetchContentsGroups = () => {
  return async (dispatch) => {
    const response = await axios.get("/api/contents_groups.json");

    dispatch({
      type: Actions.FETCH_CONTENTS_GROUPS,
      data: response.data,
    });
  };
};
