import axios from "axios";

export const Actions = {
  FETCH_VIDEO_CATEGORIES: "VIDEO_CATEGORIES::FETCH",
};

export const fetchVideoCategories = () => {
  return async (dispatch) => {
    const response = await axios.get("/api/videos/categories");

    dispatch({
      type: Actions.FETCH_VIDEO_CATEGORIES,
      data: response.data,
    });
  };
};
