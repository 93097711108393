import React from "react";
import styled from "styled-components";
import { SMARTPHONE_WIDTH } from "../styles/constants";
import { Red, Gray, White } from "../styles/colors";

const contents = [
  {
    title: "Facebookグループに参加しよう",
  },
  {
    title: "自己紹介を書き込もう",
  },
  {
    title: "viewにログインしよう",
  },
  {
    title: "ツールを用意しよう",
  },
  {
    title: "知っておくといいこと",
  },
  {
    title: "はじめの一歩！",
    subTitle: "-メンバーとコミュニケーション-",
  }
]

export const GuideContents = () => {

  return (
    <Wrapper>
      <Index>
        <Lead>はじめの一歩を踏み出そう!</Lead>
        <Description>前デに入ってみてどうですか？
          <br />
        「情報が多くて何をしたら良いか分からない…」と思っている人もいるかも知れません。そんな時はこれから紹介する手順を参考に、メンバーとコミュニケーションを取ること（＝はじめの一歩）からスタートしましょう！</Description>
        <LinkButtons>
          {contents.map((content, index) => (
            <LinkButton key={index}>
              <LinkButtonIndex>{index + 1}</LinkButtonIndex>
              <LinkButtonText>{content.title}
                {content.subTitle && <LinkButtonSubText>{content.subTitle}</LinkButtonSubText>}
              </LinkButtonText>
            </LinkButton>
          ))}
        </LinkButtons>
      </Index>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 10vh 12vw;
  background-color: ${Gray};
`;

const Index = styled.div`
  background-color: ${White};
  padding: 10vh 8vw;
`;

const Lead = styled.h2`
  font-size: 18px;
  margin-bottom: 30px;
  position: relative;
  width: 228px;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -6px;
    display: inline-block;
    width: 228px;
    height: 2px;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
    background-color: ${Red};
  }
`;

const Description = styled.p`
  font-size: 13px;
  margin-bottom: 18px;
  line-height: 26px;
  letter-spacing: 1.04px;
`;

const LinkButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -15px;
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    justify-content: center;
  }
`;

const LinkButton = styled.div`
  margin: 7.5px 15px;
  padding: 14px 15px;
  width: calc((100% - 60px) / 2);
  min-width: 210px;
  display: flex;
  align-items: center;
  height: 40px;
  border: solid 2px ${Red}
  font-size: 14px;
  letter-spacing: 1.12px;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: ${Red};
    color: ${White};
  }
`;

const LinkButtonIndex = styled.span`
  margin-right: 15px;
  color: ${Red}
`;

const LinkButtonText = styled.span`
`;

const LinkButtonSubText = styled.span`
  font-size: 12px;
`;