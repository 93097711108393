import axios from "axios";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { useLog } from "../hooks/useLog";
import { AdminHeader } from "./AdminHeader";
import { AdminMenu } from "./AdminMenu";
import { CommunitySettingsForm } from "./CommunitySettingsForm";
import { ManageContentsGroups } from "./ManageContentsGroups";
import { ManageMembers } from "./ManageMembers";
import { ManageVideoCategories } from "./ManageVideoCategories";
import { AdminFacebook } from "./AdminFacebook";

const fetchAppData = async () => {
  const response = await axios.get("/api/app_data");
  return response.data;
};

export type Community = {
  id: number;
  name: string;
  icon_url: string;
  default_event_url: string;
  default_content_image_url: string;
  how_to_walk_url: string;
  video_conference_url: string;
  note_urls: undefined | string[];
};

export const AdminPage = () => {
  const log = useLog();
  const [community, setCommunity] = useState<Community>(null);

  useEffect(() => {
    log({ event_target: "Page", event_action: "open" });
    fetchAppData().then((data) => {
      setCommunity(data.community);
    });
  }, [log]);

  return (
    <Page>
      <AdminHeader iconUrl={community?.icon_url} />
      <Wrapper>
        <AdminMenu community={community} />
        <MainContentWrapper>
          <Switch>
            <Route exact path="/admin">
              <MainWrapper>
                <ContentWrapper>
                  <CommunitySettingsForm community={community} />
                </ContentWrapper>
              </MainWrapper>
            </Route>
            <Route path="/admin/members">
              <MembersMainWrapper>
                <MembersContentWrapper>
                  <ManageMembers />
                </MembersContentWrapper>
              </MembersMainWrapper>
            </Route>
            <Route path="/admin/contents_categories">
              <MainWrapper>
                <ContentWrapper>
                  <ManageContentsGroups />
                </ContentWrapper>
              </MainWrapper>
            </Route>
            <Route path="/admin/videos_categories">
              <MainWrapper>
                <ContentWrapper>
                  <ManageVideoCategories />
                </ContentWrapper>
              </MainWrapper>
            </Route>
            {community?.id === 1 && (
              <Route path="/admin/facebook">
                <MainWrapper>
                  <ContentWrapper>
                    <AdminFacebook />
                  </ContentWrapper>
                </MainWrapper>
              </Route>
            )}
          </Switch>
        </MainContentWrapper>
      </Wrapper>
    </Page>
  );
};

const Page = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
`;

const MainContentWrapper = styled.div`
  flex-grow: 1;
  background-color: #f5f7f7;
`;

const MainWrapper = styled.div`
  margin: 104px auto;
  max-width: 980px;
`;

const ContentWrapper = styled.div`
  border-radius: 10px;
  background-color: white;
  max-width: 900px;
  margin: 0 80px;
  padding: 60px 40px;
`;

const MembersMainWrapper = styled(MainWrapper)`
  max-width: 1280px;
`;

const MembersContentWrapper = styled(ContentWrapper)`
  max-width: 1200px;
`;
