import axios from "axios";
import React, { useCallback, useState } from "react";
import { SubmitButton } from "./FormParts";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";
import { User } from "./ManageMembers";

interface Props {
  users: User[];
  setUsers: (arg: User[]) => void;
}

export const UserList: React.FC<Props> = ({ users, setUsers }) => {
  const [updatedUsers, setUpdatedUsers] = useState<number[]>([]);

  const memberCount = users.length;
  const confirmedMemberCount = users.filter((u) => u.confirmed).length;

  const resendEmail = (id) => {
    const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
    axios
      .post(
        `/admin/${id}/resend_email`,
        {},
        {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        }
      )
      .then(() => setUpdatedUsers(updatedUsers.concat(id)));
  };

  const updateUsers = useCallback(
    (user: User) => {
      const index = users.findIndex((v) => user.id === v.id);
      setUsers([...users.slice(0, index), user, ...users.slice(index + 1)]);
    },
    [users]
  );

  const toggleOwner = (id) => {
    const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
    axios
      .post<null, { data: User }>(
        `/admin/${id}/toggle_owner`,
        {},
        {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        }
      )
      .then((res) => updateUsers(res.data));
  };

  const toggleLocked = (id) => {
    const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
    axios
      .post<null, { data: User }>(
        `/admin/${id}/toggle_locked`,
        {},
        {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        }
      )
      .then((res) => updateUsers(res.data));
  };

  const toggleEditable = (id) => {
    const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
    axios
      .post<null, { data: User }>(
        `/admin/${id}/toggle_editable`,
        {},
        {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        }
      )
      .then((res) => updateUsers(res.data));
  };

  const clickSendAllUnconfirmedMemvers = () => {
    const unconfirmedMembers = users.filter((u) => !u.confirmed);
    if (confirm(`${unconfirmedMembers.length}人の未認証メンバーに招待メールが送信されます。よろしいですか？`)) {
      const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
      axios
        .post(
          `/admin/resend_email_to_all_unconfirmed_members`,
          {},
          {
            headers: {
              "X-CSRF-Token": csrfToken,
            },
          }
        )
        .then(() => {
          alert("送信されました。");
          setUpdatedUsers(updatedUsers.concat(unconfirmedMembers.map((u) => u.id)));
        });
    }
  };

  return (
    <>
      <HeaderWrapper>
        <HeadingWrapper>
          <H2>メンバー一覧</H2>
          <MemberCount>
            認証済み: {confirmedMemberCount}人 全メンバー: {memberCount}人
          </MemberCount>
        </HeadingWrapper>
        <SubmitButton width={400} onClick={clickSendAllUnconfirmedMemvers}>
          未認証メンバー全員に招待メールを再送する
        </SubmitButton>
      </HeaderWrapper>
      <Base>
        <Columns>
          <RealName>本名</RealName>
          <UserName>ユーザー名</UserName>
          <Email>メールアドレス</Email>
          <Owner>管理者</Owner>
          <Locked>ロック</Locked>
          <Editable>編集</Editable>
          <ConfirmedLabel>認証</ConfirmedLabel>
          <ResendEmail>招待メール</ResendEmail>
        </Columns>
        {users &&
          users.map((u) => (
            <UserItem key={u.id}>
              <RealName>{u.real_name}</RealName>
              <UserName>{u.user_name}</UserName>
              <Email>{u.email}</Email>
              <Owner>
                <input type="checkbox" checked={u.owner} onChange={() => toggleOwner(u.id)} value="owner" />
              </Owner>
              <Locked>
                <input type="checkbox" checked={u.locked} onChange={() => toggleLocked(u.id)} value="locked" />
              </Locked>
              <Editable>
                <input type="checkbox" checked={u.editable} onChange={() => toggleEditable(u.id)} value="editable" />
              </Editable>
              <Confirmed confirmed={u.confirmed}>{u.confirmed ? "✓" : "✘"}</Confirmed>
              <ResendEmail>
                {!u.confirmed && (
                  <ResendButton onClick={() => resendEmail(u.id)}>
                    {updatedUsers.includes(u.id) ? "送信しました" : "再送する"}
                  </ResendButton>
                )}
              </ResendEmail>
            </UserItem>
          ))}
      </Base>
    </>
  );
};

const HeadingWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0 16px;
`;

const H2 = styled.h2`
  font-size: 24px;
`;

const MemberCount = styled.p`
  margin-left: 8px;
  font-size: 14px;
  color: #aaa;
`;

const Base = styled.div`
  margin-top: 12px;
`;

const Columns = styled.div`
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #d2d2d2;
`;

const UserItem = styled.div`
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #d2d2d2;
`;

const Owner = styled.div`
  width: 80px;
`;

const Locked = styled.div`
  width: 80px;
`;

const Editable = styled.div`
  width: 100px;
`;

const RealName = styled.div`
  width: 200px;
`;

const UserName = styled.div`
  width: 200px;
`;

const Email = styled.div`
  width: 320px;
  overflow: scroll;
`;

const ConfirmedLabel = styled.div`
  width: 100px;
`;

const Confirmed = styled.div`
  width: 100px;
  ${({ confirmed }) =>
    confirmed
      ? css`
          color: rgba(60, 230, 34, 1);
        `
      : css`
          color: rgba(255, 56, 59, 1);
        `}
`;

const ResendEmail = styled.div`
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResendButton = styled.button`
  cursor: pointer;
  background-color: #fff;
  color: ${Red};
  border-radius: 16px;
  border: 1px solid ${Red};
  &:hover {
    background-color: ${Red};
    color: #fff;
  }
`;
