import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Red } from "../styles/colors";

export default function NotificationWidget({ notifications }) {
  const [displayDropDown, setDisplayDropDown] = useState(false);
  return (
    <Base
      onMouseEnter={() => setDisplayDropDown(true)}
      onMouseLeave={() => setDisplayDropDown(false)}
      hovered={displayDropDown}
    >
      <Icon className="view-notification" />
      {displayDropDown && (
        <Widget className="widget">
          <WidgetContent>
            <Header>
              <Title>お知らせ</Title>
            </Header>
            {notifications
              ? notifications.map((value) => (
                  <NotificationLink href={value.url} key={value.id}>
                    <Content href={value.url} key={value.id}>
                      <ProfileIcon />
                      <Right>
                        <Description>{value.description}</Description>
                        <Date>1日前</Date>
                      </Right>
                    </Content>
                  </NotificationLink>
                ))
              : "通知が来るとここに表示されます"}
          </WidgetContent>
        </Widget>
      )}
    </Base>
  );
}

const Base = styled.div`
  position: relative;
  padding: 14px 4px 6px 8px;
  transition: all 0.2s;
  ${(p) =>
    p.hovered &&
    css`
      color: ${Red};
    `}
`;

const Icon = styled.i`
  font-size: 42px;
`;

const Widget = styled.div`
  color: #000;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 5;
`;

const WidgetContent = styled.div`
  margin-top: 18px;
  width: 470px;
  height: 644px;
  background-color: #fff;
  box-shadow: 1px 1.7px 6px 0 rgba(0, 0, 0, 0.1);
  overflow: scroll;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 13px 20px;
  border-bottom: 1px solid #d2d2d2;
`;

const Title = styled.div`
  margin: 5px 0;
  font-size: 14px;
  letter-spacing: 1.05px;
`;

const NotificationLink = styled.a`
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    color: #000;
    text-decoration: none;
  }
`;

const Content = styled.div`
  display: flex;
  padding: 29px 33px 19px 21px;
  min-height: 61px;

  transition: all 0.2s;
  &:hover {
    background-color: rgba(229, 2, 19, 0.1);
  }
`;

const ProfileIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 25px;
  background-color: #ddd;
`;

const Right = styled.div`
  margin-left: 16px;
`;

const Description = styled.div`
  font-size: 14px;
  width: 352px;
  margin-bottom: 14px;
`;

const Date = styled.div`
  font-size: 12px;
  color: #666;
`;
