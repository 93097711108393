import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { iconList } from "../constants/iconList";

interface Props {
  iconName: string;
  color?: string;
  size?: number;
}

const viewIconNames = [
  "view-communication",
  "view-flag",
  "view-project",
  "view-man",
  "view-club",
  "view-school",
  "view-favorite-b",
  "view-past-video",
  "view-archive",
];

export const IconComponent: React.FC<Props> = ({ iconName, color, size }) => {
  const iconComponent = useMemo(() => {
    return iconList.find((v) => v.name === iconName);
  }, [iconName]);
  return (
    <>
      {viewIconNames.find((name) => name === iconName) ? (
        <Icon className={iconName} color={color} size={size} />
      ) : (
        iconComponent && iconComponent({ size: size ? size : 32, color: color })
      )}
    </>
  );
};

const Icon = styled.i<{ color?: string; size?: number }>`
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  ${({ size }) =>
    size
      ? css`
          font-size: ${size}px;
        `
      : css`
          font-size: 38px;
        `}
  &.view-favorite-b {
    ${({ size }) =>
      size
        ? css`
            font-size: ${size}px;
          `
        : css`
            font-size: 36px;
          `}
  }
`;
