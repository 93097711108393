import axios from "axios";

export const Actions = {
  FETCH_NOTIFICATIONS: "NOTIFICATIONS::FETCH"
};

export const fetchNotifications = () => {
  return async dispatch => {
    const response = await axios.get("/api/notifications");

    dispatch({
      type: Actions.FETCH_NOTIFICATIONS,
      data: response.data
    });
  };
};
