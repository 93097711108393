import React, { useEffect } from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { useGroupingEventsByDay } from "../hooks/useGroupingEventsByDay";
import { Red } from "../styles/colors";
import { Event } from "../types/event";
import { CloseModalIcon } from "./CloseModalIcon";
import { ModalTitle } from "./ModalBase";

interface Props {
  showEvents: Event[];
  handleClick: (id: number) => void;
  closeModal: () => void;
}

export const EventList: React.FC<Props> = ({ showEvents, handleClick, closeModal }) => {
  const [groupedEventsByDay, setGroupedEventsByDay] = useGroupingEventsByDay();

  useEffect(() => {
    setGroupedEventsByDay(showEvents);
  }, [setGroupedEventsByDay, showEvents]);

  return (
    <Base>
      <ModalTitle>
        <CloseModalIcon onClick={closeModal} className="view-close" />
        予定の編集
      </ModalTitle>
      <EventsWrapper>
        {groupedEventsByDay &&
          Object.keys(groupedEventsByDay).map((date) => (
            <div key={date}>
              <DateLabel>{date}</DateLabel>
              {groupedEventsByDay[date].map((event) => (
                <EventItem key={event.id} onClick={() => handleClick(event.id)}>
                  <Title>{event.title}</Title>
                  <Right>
                    {event.start_time === "00:00" && event.end_time === "23:59" ? (
                      <div>終日</div>
                    ) : (
                      <>
                        <MediaQuery query="(min-width: 1040px)">
                          <Times>{`${event.start_time} ~ ${event.end_time}`}</Times>
                        </MediaQuery>
                        <MediaQuery query="(max-width: 1039px)">
                          <Times>{`${event.start_time}\n${event.end_time}`}</Times>
                        </MediaQuery>
                      </>
                    )}
                    <Arrow>&gt;</Arrow>
                  </Right>
                </EventItem>
              ))}
            </div>
          ))}
      </EventsWrapper>
    </Base>
  );
};

const Base = styled.div`
  min-height: 300px;
`;

const EventsWrapper = styled.div`
  margin: 0 23px;
  @media screen and (max-width: 1024px) {
    margin: 0 10px;
  }
`;

const DateLabel = styled.div`
  border-bottom: solid 2px ${Red};
  font-size: 13px;
  padding: 30px 0 10px 6px;
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;

const EventItem = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px 18px 4px;
  font-size: 16px;
  border-bottom: solid 1px #e3e3e3;
  transition: all 0.2s;
  &:hover {
    color: ${Red};
  }
  @media screen and (max-width: 1024px) {
    padding: 9px 4px 9px 4px;
  }
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 280px;
`;

const Times = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.56px;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Arrow = styled.div`
  font-size: 24px;
  transform: scale(0.6, 1.4);
  margin-left: 8px;
`;
