import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { sendContent } from "../actions/Contents";
import { Red } from "../styles/colors";
import { SMARTPHONE_WIDTH } from "../styles/constants";
import { CategoryInput } from "./CategoryInput";
import { CloseModalIcon } from "./CloseModalIcon";
import CoverImageUploadComponent from "./CoverImageUploadComponent";
import {
  CancelButton,
  DeleteButton,
  ErrorMessage,
  FormWrapper,
  Hint,
  InformationsWrapper,
  InputWrapper,
  Label,
  SubmitButton,
  Textarea,
} from "./FormParts";
import { ModalTitle, TextInput } from "./ModalBase";
import { TimeInput } from "./TimeInput";

const defaultFormData = (imageUrl) => {
  return {
    id: "",
    name: "",
    comment: "",
    image_url: imageUrl,
    contents_group_id: "",
    facebook_url: "",
    with_event: "",
    with_notification: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    facebook_id: null,
  };
};

const initialErrorMessages = {
  name: null,
  comment: null,
  image_url: null,
  contents_group_id: null,
  facebook_url: null,
  date_limit: null,
};

export const ContentForm = ({ community, contentsGroups, modalContent, closeModal, openContentDelete }) => {
  const dispatch = useDispatch();
  const initialFormData = useMemo(
    () =>
      modalContent
        ? {
            id: modalContent.id,
            name: modalContent.name,
            comment: modalContent.comment,
            image_url: modalContent.image_url,
            contents_group_id: modalContent.contents_group_id,
            facebook_url: modalContent.facebook_url ? modalContent.facebook_url : "",
            with_event: modalContent.with_event ? modalContent.with_event : "",
            with_notification: modalContent.with_notification ? modalContent.with_notification : "",
            start_date: modalContent.started_at ? moment(modalContent.started_at).format("YYYY-MM-DD") : "",
            start_time: modalContent.started_at ? moment(modalContent.started_at).format("HH:mm") : "",
            end_date: modalContent.ended_at ? moment(modalContent.ended_at).format("YYYY-MM-DD") : "",
            end_time: modalContent.ended_at ? moment(modalContent.ended_at).format("HH:mm") : "",
            facebook_id: modalContent.facebook_id,
          }
        : defaultFormData(community.defaultContentImageUrl),
    [community.defaultContentImageUrl, modalContent]
  );

  const [formData, setFormData] = useState(initialFormData);
  const [errorMessages, setErrorMessages] = useState(initialErrorMessages);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const selectedContentsGroup = useMemo(
    () => contentsGroups.find((e) => e.id === parseInt(formData.contents_group_id, 10)),
    [contentsGroups, formData.contents_group_id]
  );

  const [dateLimitable, createWithEvent] = useMemo(() => {
    if (selectedContentsGroup) {
      const dateLimitable = selectedContentsGroup.date_limitable;
      const createWithEvent = selectedContentsGroup.with_event;

      return [dateLimitable, createWithEvent];
    }
    return [false, false];
  }, [selectedContentsGroup]);

  useEffect(() => {
    if (modalContent) {
      setFormData({
        id: modalContent.id,
        name: modalContent.name,
        comment: modalContent.comment,
        image_url: modalContent.image_url,
        contents_group_id: modalContent.contents_group_id,
        facebook_url: modalContent.facebook_url ? modalContent.facebook_url : "",
        with_event: modalContent.with_event ? modalContent.with_event : "",
        with_notification: modalContent.with_notification ? modalContent.with_notification : "",
        start_date: modalContent.started_at ? moment(modalContent.started_at).format("YYYY-MM-DD") : "",
        start_time: modalContent.started_at ? moment(modalContent.started_at).format("HH:mm") : "",
        end_date: modalContent.ended_at ? moment(modalContent.ended_at).format("YYYY-MM-DD") : "",
        end_time: modalContent.ended_at ? moment(modalContent.ended_at).format("HH:mm") : "",
        facebook_id: modalContent.facebook_id,
      });
    } else {
      setFormData(defaultFormData(community.defaultContentImageUrl));
    }
  }, [community.defaultContentImageUrl, modalContent]);

  const changeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const imageUrlHandler = (imageUrl) => {
    setFormData({
      ...formData,
      image_url: imageUrl,
    });
  };

  const validate = useCallback(() => {
    let sendable = true;
    const newErrorMessages = JSON.parse(JSON.stringify(initialErrorMessages));
    if (formData) {
      if (formData.image_url.length === 0) {
        newErrorMessages.image_url = "画像を入れてください";
        sendable = false;
      }
      if (formData.contents_group_id.length === 0) {
        newErrorMessages.contents_group_id = "カテゴリを選択してください";
        sendable = false;
      }
      if (formData.name.length === 0) {
        newErrorMessages.name = "タイトルを入力してください";
        sendable = false;
      }
      if (selectedContentsGroup.date_limitable) {
        if (
          formData.start_date.length === 0 ||
          formData.start_time.length === 0 ||
          formData.end_date.length === 0 ||
          formData.end_time.length === 0
        ) {
          newErrorMessages.date_limit = "開始日時と終了予定日時を入力してください";
          sendable = false;
        }
      }
      if (formData.facebook_url.length === 0) {
        newErrorMessages.facebook_url = "URLを入力してください";
        sendable = false;
      }
      if (formData.comment.length === 0) {
        newErrorMessages.comment = "コメントを入力してください";
        sendable = false;
      }
      setErrorMessages(newErrorMessages);
      return sendable;
    }
    return false;
  }, [formData, selectedContentsGroup?.date_limitable]);

  const handleSubmit = useCallback(
    (e) => {
      setSubmitDisabled(true);
      e.preventDefault();
      const sendable = validate();
      if (sendable) {
        const csrf_token = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
        dispatch(sendContent(formData, csrf_token, closeModal, setSubmitDisabled));
      } else {
        setSubmitDisabled(false);
      }
    },
    [closeModal, dispatch, formData, validate]
  );

  const selectContentGroup = (name, id) => {
    const selected = contentsGroups.find((e) => e.id === parseInt(id, 10));

    if (selected.date_limitable) {
      setFormData({
        ...formData,
        start_date: formData.start_date || moment().add(1, "days").format("YYYY-MM-DD"),
        end_date: formData.end_date || moment().add(1, "days").format("YYYY-MM-DD"),
        start_time: formData.start_time || moment().format("HH:00"),
        end_time: formData.end_time || moment().add(1, "hours").format("HH:00"),
        contents_group_id: id,
      });
    } else {
      setFormData({
        ...formData,
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
        contents_group_id: id,
      });
    }
  };

  useEffect(() => {
    if (!formData.contents_group_id && contentsGroups.length > 0) {
      setFormData({
        ...formData,
        contents_group_id: contentsGroups[0].id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ModalTitle>
        <CloseModalIcon onClick={closeModal} className="view-close" />
        {modalContent ? "コンテンツの編集" : "コンテンツの追加"}
      </ModalTitle>
      <FormWrapper>
        <form onSubmit={handleSubmit}>
          <InformationsWrapper>
            <div style={{ marginBottom: "20px" }}>
              <CoverImageUploadComponent
                imageUrl={formData.image_url}
                imageUrlHandler={imageUrlHandler}
                error={errorMessages.image_url}
              />
              <ErrorMessage style={{ margin: "8px auto 0", width: "340px" }}>{errorMessages.image_url}</ErrorMessage>
            </div>
            <InputWrapper>
              <Label must>タイトル</Label>
              <div>
                <TextInput
                  onChange={changeHandler}
                  placeholder="タイトル"
                  name="name"
                  value={formData.name}
                  error={errorMessages.name}
                />
                <ErrorMessage>{errorMessages.name}</ErrorMessage>
              </div>
            </InputWrapper>
            <CategoryWrapper>
              <Label must>カテゴリ</Label>
              <CategoryInput
                errorMessages={errorMessages}
                formData={formData}
                name="contents_group_id"
                options={contentsGroups}
                selectHandler={selectContentGroup}
                width={200}
              />
            </CategoryWrapper>
            {dateLimitable && (
              <InputWrapper>
                <Label must>日時</Label>
                <div>
                  <TimeForms>
                    <StartDateTime>
                      <DateInput
                        onChange={changeHandler}
                        type="date"
                        name="start_date"
                        value={formData.start_date}
                        error={errorMessages.date_limit}
                      />
                      <div style={{ marginLeft: 9 }}>
                        <TimeInput
                          formData={formData}
                          setFormData={setFormData}
                          changeHandler={changeHandler}
                          name={"start_time"}
                          value={formData.start_time}
                          error={errorMessages.date_limit}
                        />
                      </div>
                    </StartDateTime>
                    <EndDateTime>
                      <DateInput
                        onChange={changeHandler}
                        type="date"
                        name="end_date"
                        value={formData.end_date}
                        error={errorMessages.date_limit}
                      />
                      <div style={{ marginLeft: 9 }}>
                        <TimeInput
                          formData={formData}
                          setFormData={setFormData}
                          changeHandler={changeHandler}
                          name={"end_time"}
                          value={formData.end_time}
                          error={errorMessages.date_limit}
                        />
                      </div>
                    </EndDateTime>
                  </TimeForms>
                  <ErrorMessage>{errorMessages.date_limit}</ErrorMessage>
                  {createWithEvent && (
                    <CheckBoxWrapper style={{ margin: "14px 0 0 2px" }}>
                      <CheckBox
                        type="checkbox"
                        onChange={changeHandler}
                        name="with_event"
                        value="true"
                        selected={formData.with_event === "true" ? "selected" : null}
                      />
                      <LabelTitle>上記スケジュールをカレンダーにも反映する</LabelTitle>
                    </CheckBoxWrapper>
                  )}
                </div>
              </InputWrapper>
            )}

            <InputWrapper>
              <Label must>URL</Label>
              <div>
                <TextInput
                  onChange={changeHandler}
                  placeholder="FacebookスレッドのURL"
                  name="facebook_url"
                  value={formData.facebook_url}
                  error={errorMessages.facebook_url}
                />
                <ErrorMessage>{errorMessages.facebook_url}</ErrorMessage>
              </div>
            </InputWrapper>
            <InputWrapper>
              <Label must>コメント</Label>
              <div>
                <Textarea
                  onChange={changeHandler}
                  type="textarea"
                  placeholder="コンテンツの説明"
                  name="comment"
                  value={formData.comment}
                  error={errorMessages.comment}
                />
                <ErrorMessage>{errorMessages.comment}</ErrorMessage>
                <Hint>※ 必要に応じて80字以内でご入力ください。</Hint>
              </div>
            </InputWrapper>
            <CheckBoxWrapper>
              <CheckBox
                type="checkbox"
                onChange={changeHandler}
                name="with_notification"
                value="true"
                selected={formData.with_notification === "true" ? "selected" : null}
              />
              <LabelTitle>{community.name}メンバーに通知を送る</LabelTitle>
            </CheckBoxWrapper>
          </InformationsWrapper>

          <Buttons>
            {modalContent && modalContent.id && (
              <DeleteButton onClick={() => openContentDelete(modalContent)}>削除する</DeleteButton>
            )}
            <RightButtons>
              <CancelButton onClick={closeModal}>キャンセル</CancelButton>
              <SubmitButton onClick={submitDisabled ? null : handleSubmit}>
                {modalContent ? "内容を反映する" : "コンテンツを追加する"}
              </SubmitButton>
            </RightButtons>
          </Buttons>
        </form>
      </FormWrapper>
    </div>
  );
};

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

const TimeForms = styled.div`
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    display: block;
  }
`;

const DateTime = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StartDateTime = styled(DateTime)`
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    margin-bottom: 14px;
  }
`;
const EndDateTime = styled(DateTime)`
  margin-left: 32px;
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    margin-left: 0;
  }
`;

const CategoryWrapper = styled(InputWrapper)`
  justify-content: flex-start;
`;

const DateInput = styled.input<{ error: null | string }>`
  font-size: 14px;
  width: 155px;
  height: 48px;
  border: solid 1px #d2d2d2;
  line-height: 48px;
  padding: 0;
  padding-left: 18px;
  ${({ error }) =>
    error !== null &&
    css`
      border: solid 1px ${Red};
      background-color: rgba(229, 2, 19, 0.1);
    `};
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    width: 138px;
    padding-left: 10px;
    height: 38px;
    line-height: 38px;
    font-size: 13px;
  }
`;

const Buttons = styled.div`
  display: flex;
  padding-top: 40px;
  justify-content: space-between;
  border-top: solid 1px #d2d2d2;
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const CheckBox = styled.input`
  margin: 18px 8px;
`;

const LabelTitle = styled.div`
  margin-left: 8px;
  line-height: 48px;
  font-size: 13px;
  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    line-height: 38px;
  }
`;
