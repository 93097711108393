import React from "react";
import { SMARTPHONE_WIDTH } from "../styles/constants";
import styled from "styled-components";
import { MemberListContents, MEMBER_LIST_MEMBER_FRAGMENT } from "./MemberListContents";
import { MemberListHeader } from "./MemberListHeader";
import { ContainerCommunityFragment, MemberListMemberFragment, MemberListQuery } from "../@types/types";
import { gql, useQuery } from "@apollo/client";

const MEMBER_LIST_QUERY = gql`
  query MemberList {
    viewer {
      community {
        members {
          edges {
            node {
              ...MemberListMember
            }
          }
        }
      }
    }
  }
  ${MEMBER_LIST_MEMBER_FRAGMENT}
`;

export const MemberListContainer: React.FC = () => {
  const { data } = useQuery<MemberListQuery>(MEMBER_LIST_QUERY);
  const members = data?.viewer?.community.members.edges
    ?.map((edge) => edge?.node)
    .filter((member): member is MemberListMemberFragment => !!member);
  if (!members) {
    return null;
  }
  return (
    <>
      <MemberListHeader />
      <ContentsWrapper>
        <MemberListContents members={members} />
      </ContentsWrapper>
    </>
  );
};

const ContentsWrapper = styled.div`
  margin: 100px 0 163px;

  @media screen and (max-width: ${SMARTPHONE_WIDTH}) {
    margin: 40px 0 120px;
  }
`;
