import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ModalBase, ModalTitle } from "./ModalBase";
import { CloseModalIcon } from "./CloseModalIcon";
import styled from "styled-components";
import { SubmitButton } from "./FormParts";

type AdminGroup = {
  id: string;
  name: string;
  cover: string;
};

const fetchAdminGroups = async () => {
  const response = await axios.get("/api/admin/admin_groups");
  return response.data;
};

export const FacebookGroupSelectorModal: React.FC<{ setFacebookContents: () => void }> = ({ setFacebookContents }) => {
  const [adminGroups, setAdminGroups] = useState<AdminGroup[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const history = useHistory();
  const closeModal = useCallback(() => {
    setFacebookContents();
    history.push("/admin/facebook");
  }, [setFacebookContents, history]);
  useEffect(() => {
    fetchAdminGroups().then((res) => {
      setAdminGroups(res);
    });
  }, []);
  const onChangeId = (id: string) => {
    setSelectedId(id);
  };

  const submit = () => {
    const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]").content;
    const data = {
      id: selectedId,
      name: adminGroups.find((g) => g.id === selectedId).name,
    };
    axios
      .post("/api/admin/link_facebook_group", data, {
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      })
      .then(() => {
        closeModal();
      });
  };
  return (
    <Base>
      <ModalBase width={700} spWidth={400} contentLabel={"グループ選択"} isOpen={true} onRequestClose={closeModal}>
        <CloseModalIcon onClick={closeModal} className="view-close" />
        <ModalTitle>連携するグループの選択</ModalTitle>
        <div style={{ overflow: "hidden" }}>
          <div>
            {adminGroups.map((g) => (
              <GroupItemWrapper key={g.id} onClick={() => onChangeId(g.id)}>
                <GroupItem>
                  <input
                    style={{ marginRight: "8px" }}
                    type="radio"
                    value={g.id}
                    name="id"
                    checked={g.id === selectedId}
                    onChange={() => onChangeId(g.id)}
                  />
                  {g.name}
                </GroupItem>
              </GroupItemWrapper>
            ))}
          </div>
          <SubmitButton style={{ margin: "16px 16px 16px auto", display: "block" }} onClick={submit}>
            決定
          </SubmitButton>
        </div>
      </ModalBase>
    </Base>
  );
};

const Base = styled.div`
  min-height: 300px;
  max-height: 1000px;
  position: relative;
`;

const GroupItemWrapper = styled.div`
  cursor: pointer;
  &:hover {
    background-color: rgba(229, 2, 19, 0.1);
  }
`;

const GroupItem = styled.div`
  padding: 16px;
  margin: 0 8px;
  border-bottom: 1px solid #a4a4a4;
`;
